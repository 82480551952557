import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../../firebase/firebase";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {hasAdmin} from "../../services/menu";
import Heading from "../Heading";
import {doGet, doPostBody} from "../../services/rest";
import Select from "react-select";
import MySpinner from "../MySpinner";

function UserAdmin() {

    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const [adminLocation, setAdminLocations] = useState()
    const [users, setUsers] = useState({})
    const [selectedLocations, setSelectedLocations] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [search, setSearch] = useState('')

    useEffect(() => {
        if(loading) return;
        if(!user) navigate("/login")
        if(!hasAdmin("ROLE_USERS")) navigate("/")
        doGet("user-admin/").then(res =>{
            const formattedOptions = res.locations.map(option => ({
                value: option.id,
                label: option.locationName
            }));
            setAdminLocations(formattedOptions)
            console.log(res.initialPeople)
            setUsers(res.initialPeople)
            setIsLoading(false)
        })
    }, [user, loading, navigate]);
    const customStyles = {
        control: provided => ({
            ...provided,
            width: '100%',
            padding: '5px',
            fontSize: '18px',
            borderRadius: '25px',
            borderColor: '#ccc',
            boxShadow: 'none', // Removes the default react-select styles
            '&:hover': { borderColor: '#ccc' } // Applies border style on hover
        }),
        input: provided => ({
            ...provided,
            color: '#000' // Changes the text color
        }),
        singleValue: provided => ({
            ...provided,
            color: '#000' // Changes the text color
        })
    };
    if(isLoading){
        return (<>
            <Heading title={'User Admin'} />
            <MySpinner />
        </>)
    }

    const filter = () =>{
        if(!search && !selectedLocations){
            window.alert("Please enter something to filter by");
            return;
        }
        const formattedOptions = selectedLocations.map(option => ({
            id: option.value,
            locationName: option.label
        }));
        setIsLoading(true)
        const searchData = {appLocations: formattedOptions, search: search}
        doPostBody(`user-admin/page/${users.number}`, searchData).then(res => {
            setUsers(res)
            setIsLoading(false)
        })
    }

    const move = (type) => {
        let number = users.number
        if(type === 'forward'){
            number++
        }else{
            number--
        }
        setIsLoading(true)
        if(search || selectedLocations.length > 0){
            const formattedOptions = selectedLocations.map(option => ({
                id: option.value,
                locationName: option.label
            }));
            const searchData = {appLocations: formattedOptions, search: search}
            doPostBody(`user-admin/page/${number}`, searchData).then(res => {
                setUsers(res)
                setIsLoading(false)
            })
        }else{
            doGet(`user-admin/page/${number}`).then(res =>{
                setUsers(res)
                setIsLoading(false)
            })
        }
    }

    const onKeyDown = event => {
        if(event.key === 'Enter'){
            filter()
        }
    }

    return(<>
        <Heading title={'User Admin'} />
        <div className={'main-container'} style={{paddingTop :30}}>
            <div className={'flex-space-between'}>
                <div>
                    <button className={'action-button'}>New User</button>
                </div>
                <div style={{width: '80%'}}>
                    <div className={'flex'} style={{justifyContent: 'flex-end', paddingRight: 10}}>
                        <input onKeyDown={onKeyDown} className={'search-input-user'} value={search} onChange={e =>setSearch(e.target.value)} type={"text"} placeholder={'Search for user...'}/>
                        <div style={{paddingLeft: 10}}>
                            <Select
                                placeholder={'Filter Locations'}
                                styles={customStyles}
                                closeMenuOnSelect={false}
                                isMulti
                                onChange={setSelectedLocations}
                                defaultValue={selectedLocations}
                                options={adminLocation}

                            />
                        </div>
                        <div style={{paddingLeft: 10, paddingTop: 10}}>
                         <button onClick={filter} className={'action-button'}>Filter</button>
                        </div>
                    </div>
                    <table className={'display-users'}>
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>GSCID</th>
                            <th>Firebase ID</th>
                            <th>Type</th>
                        </tr>
                        </thead>
                        <tbody>
                        {users.content.map(user => (
                            <tr onClick={() => navigate(`/user-admin/${user.id}`)} className={'select'}>
                                <td>{user.displayName}</td>
                                <td>{user.gscId}</td>
                                <td>{user.id}</td>
                                <td>{user.directoryType}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <div className={'flex-space-between'} style={{paddingTop: 10}}>
                        <div>
                            {!users.first && <button onClick={() => move('back')} className={'action-button'}>Previous</button>}
                        </div>
                        <div>
                            Page {users.number+1} of {users.totalPages}
                        </div>
                        <div>
                            {!users.last && <button onClick={() => move('forward')} className={'action-button'}>Next</button>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)


}

export default UserAdmin
