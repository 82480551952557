import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../../firebase/firebase";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {hasAdmin} from "../../services/menu";
import {doGet, doPostBody} from "../../services/rest";
import Heading from "../Heading";
import MySpinner from "../MySpinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";

function RepositoryForm() {
    let location = useLocation();
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true)
    const [repository, setRepository] = useState({})
    const [adminRepositories, setMyAdminRepositories] = useState([])
    useEffect(() => {
        if(loading) return;
        if(!user) navigate("/login")
        if(!hasAdmin("ROLE_DOCUMENTS_REPOSITORIES")) navigate("/")
        doGet("documents/admin/repositories").then(resp => {
            setMyAdminRepositories(resp)
            setIsLoading(false)
        })
    }, [loading, user, navigate]);

    useEffect(() =>{
        if(location.state?.repository){
            setRepository(location.state.repository)
        }
    }, [location])

    if (isLoading) {
        return (<>
            <Heading title={repository.repositoryName ? repository.repositoryName+" Repository" :'Repository Admin'} />
            <MySpinner />
        </>);
    }
    const upDateTitle = event => {
        setRepository({...repository, repositoryName: event.target.value})
    }

    const isChecked = (id) => {
        if(repository.assignedAppLocations){
            return repository?.assignedAppLocations.some(obj => obj.id === id);
        }
        return false
    }

    const onChange = (rep) => {
            const updatedRepository = {...repository};
            if (repository?.assignedAppLocations?.some(obj => obj.id === rep.id)) {
                updatedRepository.assignedAppLocations = updatedRepository.assignedAppLocations.filter(obj => obj.id !== rep.id);
            } else {
                if (!updatedRepository.assignedAppLocations) {
                    updatedRepository.assignedAppLocations = [];
                }
                updatedRepository.assignedAppLocations.push(rep);
            }
            setRepository(updatedRepository);
    }

    const updateSave = () => {
        if(!repository.repositoryName){
            window.alert("A valid repository name is required.")
            return
        }
        if(!repository.assignedAppLocations){
            window.alert("Please assign at least one location to access this repository")
            return;
        }
        setIsLoading(true)
        doPostBody('documents/admin/repositories/save', repository).then(rep => {
            if(rep){
                navigate("/repository-admin")
            }
        })
    }

    return (<>
        <Heading title={repository.repositoryName ? repository.repositoryName+" Repository" :'Repository Admin'}/>
        <div className={'inner-container'}>
            <div className={'search-container'}>
                <input className={'search-input'} title={'text'} placeholder={'Enter Repository Name'} value={repository.repositoryName} onChange={upDateTitle} />
            </div>
            <h4>Assigned Locations</h4>
            <div>
                {adminRepositories.map((rep, index) =>(
                    <div key={index} className={'survey-button-container'}>
                        <input id={index} className={'survey-radio'} type={'checkbox'} name={rep.locationName} value={rep.locationName} checked={isChecked(rep.id)} onClick={() => onChange(rep)}/>
                        <label className={'checkbox-label'} htmlFor={index}>{rep.locationName}</label>
                    </div>
                ))}
            </div>
            <div style={{paddingTop: 30}}>
            <button onClick={updateSave} className={'action-button'}>Save</button>
            </div>
        </div>
    </>)

}
export default RepositoryForm
