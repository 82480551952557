import React, { useState, useEffect } from 'react';

const TextAreaQuestion = ({ question, initialAnswer, handleAnswerChange }) => {

    const [value, setValue] = useState(initialAnswer);

    useEffect(() => {
        setValue(initialAnswer);
    }, [initialAnswer]);

    const onChange = (event) => {
        setValue(event.target.value);
        handleAnswerChange(event, question);
    };

    return (
        <div className={'question'}>
            <label>{question.question}</label>
        <input type={'text'} value={value} onChange={onChange} />
    </div>
);
}

export default TextAreaQuestion;
