import {useState} from "react";
import moment from "moment";
import {doPost} from "../../services/rest";

function checkOutUser({user, onComplete}) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [currentDateTime, setCurrentDateTime] = useState(moment().format('YYYY-MM-DDTHH:mm'));
    console.log(user)

    const checkOutUser = async () => {
        const formData = new FormData();
        formData.append("fbid", user.fbid);
        formData.append("dateTime", currentDateTime);
        const resp = await doPost('facility-services/contractor/check-out', formData)
        onComplete(resp)
    }

    return(<div className={'inner-container'}>
        <p>Check out user {user.firstName} {user.lastName}</p>
        <input className={'search-input'} type={'datetime-local'} value={currentDateTime}  onChange={(e) => setCurrentDateTime(e.target.value)}/>
        <button onClick={checkOutUser} className={'submitPost'} style={{margin: 20}}>Check Out</button>
    </div>)
}
export default checkOutUser;
