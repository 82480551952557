import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Heading from "../Heading";
import MySpinner from "../MySpinner";
import {doGet} from "../../services/rest";
import {formatFirstNameLastName} from "../directory/NameFormatters";
import ResidentImages from "../directory/ResidentImages";
import PopUp from "../PopUp";
import ResidentAdditionalInformation from "./ResidentAdditionalInformation";
import TheResidentForm from "./TheResidentForm";

function ResidentVehicleForm() {
    const {id} = useParams()
    const [residentsWithVehicles, setResidentsWithVehicles] = useState({})
    const [isLoading, setIsLoading] = useState(true);
    const [currentUserData, setCurrentUserData] = useState()
    const [residentName, setResidentName] = useState()
    const [showCarForm, setShowCarForm] = useState(false)
    const [currentCar, setCurrentCar] = useState()

    useEffect(() => {
        doGet(`vehicles-admin/load/resident/${id}`).then(resp =>{
            setResidentsWithVehicles(resp)
            setIsLoading(false)
        })
    }, [id]);

    const loadUserDetails = user =>{
        if(user.residentVehicles){
            setResidentName(user.preferredName)
            setCurrentUserData(user.residentVehicles)
        }else{
            setResidentName(user.preferredName)
            setCurrentUserData({gscid: user.residentNumber})
        }
    }

    if(isLoading){
        return(<>
            <Heading />
            <MySpinner />
        </>)
    }
    return (<>
        <Heading title={formatFirstNameLastName(residentsWithVehicles.residentsNew)} />
        <div className={'inner-container'} style={{paddingTop: 50}}>
            <div style={{display: 'flex'}}>
                <div style={{flex: 1}}>
                    <ResidentImages resident={residentsWithVehicles} thumb={false}/>
                </div>
                <div style={{flex: 1}}>
                    <div style={{textAlign: "left", paddingLeft: 20, paddingTop: 10}}><span
                        style={{fontWeight: "bolder"}}>Name: </span>{formatFirstNameLastName(residentsWithVehicles.residentsNew)}
                    </div>
                    <div style={{textAlign: "left", paddingLeft: 20, paddingTop: 10}}><span
                        style={{fontWeight: "bolder"}}>Address: </span>{residentsWithVehicles.homeNumber} {residentsWithVehicles.address}
                    </div>
                    <div style={{textAlign: "left", paddingLeft: 20, paddingTop: 10}}><span
                        style={{fontWeight: "bolder"}}>Location: </span>{residentsWithVehicles.neighborhood.neighborHoodCommunityName}
                    </div>
                    {residentsWithVehicles.homePhone &&
                        <div style={{textAlign: "left", paddingLeft: 20, paddingTop: 10}}><span
                            style={{fontWeight: "bolder"}}>Phone: </span>{residentsWithVehicles.homePhone}
                        </div>}
                    {residentsWithVehicles.homeEmail &&
                        <div style={{textAlign: "left", paddingLeft: 20, paddingTop: 10}}><span
                            style={{fontWeight: "bolder"}}>Email: </span>{residentsWithVehicles.homeEmail}
                        </div>}
                    {residentsWithVehicles.residentsNew.map(res => (res.cellNumber ?
                        <div style={{textAlign: "left", paddingLeft: 20, paddingTop: 10}}><span
                            style={{fontWeight: "bolder"}}>{res.preferredName}'s Cell: </span>{res.cellNumber}
                        </div> : <></>))}
                    {residentsWithVehicles.residentsNew.map(res => (res.emailAddress ?
                        <div style={{textAlign: "left", paddingLeft: 20, paddingTop: 10}}><span
                            style={{fontWeight: "bolder"}}>{res.preferredName}'s email: </span>{res.emailAddress}
                        </div> : <></>))}
                </div>
            </div>
            <h2>Vehicles</h2>
            {residentsWithVehicles.residentsNew.map(res => (
                <div key={res.id}>
                    <h4>{res.preferredName}'s Vehicles</h4>
                    {(res.residentVehicles?.noVehicle ?? false) && <div>Resident has indicated they do not have a vehicle</div> }
                    <lable>Notes</lable>
                    <p>{res.residentVehicles?.notes}</p>
                    <button onClick={() => loadUserDetails(res)} className={'submitPost'} style={{margin: 20}}>Edit {res.preferredName}'s Notes</button>
                    <table style={{width: '100%'}}>
                        <tr>
                            <th>Year</th>
                            <th>Make</th>
                            <th>Model</th>
                            <th>Color</th>
                            <th>Tag</th>
                            <th>Licence Plate</th>
                            <th>Edit</th>
                        </tr>
                        {res.residentVehicles?.vehicles?.map(vehicle => (
                            <tr key={vehicle.id}>
                                <td>{vehicle.year}</td>
                                <td>{vehicle.make}</td>
                                <td>{vehicle.model}</td>
                                <td>{vehicle.color}</td>
                                <td>{vehicle.tag}</td>
                                <td>{vehicle.licenceplate}</td>
                                <td><span onClick={() =>{
                                    setCurrentCar(vehicle)
                                    setShowCarForm(true)
                                }} style={{cursor: 'pointer'}}>Edit</span></td>
                            </tr>
                        ))}
                    </table>
                    <button onClick={() => {
                        setCurrentCar({residentId: res.residentNumber})
                        setShowCarForm(true)
                    }} className={'submitPost'} style={{margin: 20}}>Add a vehicle for {res.preferredName}</button>
                </div>
            ))}
        </div>
        {currentUserData && <PopUp
            content={<ResidentAdditionalInformation information={currentUserData} refreshId={residentsWithVehicles.uid} name={residentName} onRefresh={data => {
                setCurrentUserData(undefined)
                setResidentName(undefined)
                setResidentsWithVehicles(data)}}/>}
            handleClose={() => {
                setResidentName(undefined)
                setCurrentUserData(undefined)
            }}
        />}
        {showCarForm && <PopUp
            content={<TheResidentForm currentVehicle={currentCar} refreshId={residentsWithVehicles.uid} name={residentName} onComplete={data => {
                setResidentsWithVehicles(data)
                setShowCarForm(false)
                setCurrentCar(undefined)
            }} />}
            handleClose={() => {
                setShowCarForm(false)
                setCurrentCar(undefined)
            }}
        />}
    </>)
}

export default ResidentVehicleForm
