import ResidentImages from "./ResidentImages";
import {formatFirstNameLastName} from "./NameFormatters";
import {defaultColor} from "../../defaults/AppConfig";
import {useEffect, useState} from "react";
import {hasAdmin} from "../../services/menu";
import {doGet, doPostBody} from "../../services/rest";
import MySpinner from "../MySpinner";

function ViewResident({resident}) {

    const [maintenanceInfo, setMaintenanceInfo] = useState()
    const [elopementAdmin, setElopementAdmin] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [elopementLocations, setElopementLocations] = useState([]);
    const [showElopement, setShowElopement] = useState(false);
    const [selectedResidents, setSelectedResidents] = useState();
    const [selectedLocations, setSelectedLocations] = useState();
    const [editMaintenance, setEditMaintenance] = useState(false)

    useEffect(() =>{
        if(hasAdmin("ROLE_MAINTENANCE")){
            const residents = resident.residentsNew.sort((a, b) =>
                a.residentGender === 'male' ? -1 : 1,
            );
            const residentNumber = residents[0].residentNumber;

            doGet("directory/maintenance/ok-enter/"+residentNumber).then(res => setMaintenanceInfo(res)).catch(err =>{
                setMaintenanceInfo({okenter: 'no', resid: residentNumber})
            });
        }
    }, [])


    useEffect(() => {
        setElopementAdmin(hasAdmin('ROLE_ELOPEMENT'))
    }, [])

    const sendElopementAlert = async () => {
        setIsLoading(true)
        const data = await doGet("directory/elopement-alert/locations")
        setElopementLocations(data)
        setIsLoading(false)
        setShowElopement(true)
    }
    const checkBoxChecked = (uid) => {
        const updatedResidents = selectedResidents ? [...selectedResidents] : [];
        return updatedResidents.some(resident => resident.uid === uid);
    };

    const toggleResident = (resident) => {
        const updatedResidents = selectedResidents ? [...selectedResidents] : [];
        const index = updatedResidents.findIndex(r => r.id === resident.id);
        if (index > -1) {
            updatedResidents.splice(index, 1);
        } else {
            updatedResidents.push(resident);
        }
        setSelectedResidents(updatedResidents);
    };

    const toggleLocations = (location) => {
        const locations = selectedLocations ? [...selectedLocations] : [];
        const index = locations.findIndex(r => r.id === location.id);
        if (index > -1) {
            locations.splice(index, 1);
        } else {
            locations.push(location);
        }
        setSelectedLocations(locations);
    };

    const locationSelected = (id) => {
        const locations = selectedLocations ? [...selectedLocations] : [];
        return locations.some(resident => resident.id === id);
    };

    const sendTheAlert = async () =>{
        if (
            resident.residentsNew.length > 1 &&
            (!selectedResidents || selectedResidents?.length === 0)
        ) {
            window.alert(
                'Since there is more then one resident you must make at least one selection to send the alert.',
            );
            return;
        }
        if (!selectedLocations || selectedLocations?.length === 0) {
            window.alert(
                'You must make at least one location selection to send the alert.',
            );
            return;
        }
        const resp = await doPostBody('directory/elopement-alert/send-alert', {
            locations: selectedLocations,
            residents: !selectedResidents ? resident.residentsNew : selectedResidents,
        });
        if (resp) {
            window.alert('Elopement alert sent successfully.');
        } else {
            window.alert('Failed to send elopement alert.');
        }
        setSelectedLocations(undefined);
        setSelectedResidents(undefined);
        setShowElopement(false);
    }

    if(isLoading){
        return(<><MySpinner /></>)
    }
    const chedkboxChange = () =>{
        setMaintenanceInfo(prevState => {
            const newMaintenanceInfo = { ...prevState };
            newMaintenanceInfo.okenter = newMaintenanceInfo.okenter === 'yes' ? 'no' : 'yes';
            return newMaintenanceInfo;
        });
    }

    const updateNotes = event => {
        setMaintenanceInfo(prevState => {
            const newMaintenanceInfo = { ...prevState };
            newMaintenanceInfo.notes = event.target.value;
            return newMaintenanceInfo;
        });
    }

    const updateMaintenance = async () => {
        setIsLoading(true)
        const update = await doPostBody('directory/maintenance/update', maintenanceInfo);
        setMaintenanceInfo(update)
        setIsLoading(false)
        setEditMaintenance(false)
    }

    if(editMaintenance){
        return(<>
            <div style={style.name}>Edit Maintenance info for {formatFirstNameLastName(resident.residentsNew)}</div>
            <div style={{textAlign: "center", paddingTop: 10}}>
            <div className={'survey-button-container'} style={{textAlign: 'center', margin: '0 auto'}}>
                <input className={'survey-radio'} type={'checkbox'} id={maintenanceInfo.resid}
                       checked={maintenanceInfo.okenter === 'yes'}
                       onClick={chedkboxChange}
                />
                <label className={'checkbox-label'} htmlFor={maintenanceInfo.resid}>Resident has indicated it is ok to enter.</label>
            </div>
            </div>
            <div style={{display: "flex", alignItems: "center", paddingTop: 20}}>
                <label style={{paddingRight: 10}} htmlFor={'notes'}>Notes: </label>
                <input style={{flexBasis: 0, flexGrow: 1, paddingLeft: 10}} type={'text'} value={maintenanceInfo.notes}
                       onChange={updateNotes}/>
            </div>
            <div className={'flex-space-between'} style={{paddingTop: 20}}>
                <button onClick={() => setEditMaintenance(false)} style={{
                    background: defaultColor,
                    borderColor: defaultColor,
                    color: "#fff",
                    padding: 5,
                    borderRadius: 10
                }}>Cancel
                </button>
                <button onClick={updateMaintenance} style={{
                    background: defaultColor,
                    borderColor: defaultColor,
                    color: "#fff",
                    padding: 5,
                    borderRadius: 10
                }}>Update
                </button>
            </div>
        </>)
    }


    return (<>
        <div>
            {elopementAdmin && <div style={{paddingBottom: 10}}>
                <button onClick={sendElopementAlert}>Send Elopement Alert</button>
            </div>}
            <ResidentImages resident={resident} thumb={false}/>
        </div>
        <div style={style.name}>{formatFirstNameLastName(resident.residentsNew)}</div>
        <div style={{textAlign: "left", width: '500px'}}>
            <div style={style.paddingTop}><span style={style.textBold}>Address: </span>
                <span>{resident.homeNumber} {resident.address}</span></div>
            <div style={style.paddingTop}><span style={style.textBold}>Location: </span>
                <span>{resident.neighborhood.neighborHoodCommunityName}</span></div>
            {resident.homePhone && (<div style={style.paddingTop}><span style={style.textBold}>Phone: </span><span><a style={style.linkStyle} href={`tel:${resident.homePhone}`}>{resident.homePhone}</a> </span> </div> )}
            {resident.homeEmail && (<div style={style.paddingTop}><span style={style.textBold}>Email: </span><span><a style={style.linkStyle} href={`mailto:${resident.homeEmail}`}>{resident.homeEmail}</a> </span> </div> )}
            {resident.residentsNew
                .sort((a, b) => (a.residentGender === 'male' ? -1 : 1))
                .map((resident, index) =>
                    resident.cellNumber ? (
                        <div
                            key={index}
                            style={style.paddingTop}>
                            <span style={style.textBold}>
                                {resident.firstName}'s Cell Phone:{' '}
                            </span>
                            <span style={style.textNotBold}><a style={style.linkStyle} href={`tel:${resident.cellNumber}`}>{resident.cellNumber}</a></span>
                        </div>
                    ) : (
                        <></>
                    ),
                )}
            {resident.residentsNew
                .sort((a, b) => (a.residentGender === 'male' ? -1 : 1))
                .map((resident, index) =>
                    resident.emailAddress ? (
                        <div
                            key={index}
                            style={style.paddingTop}>
                            <span style={style.textBold}>
                                {resident.firstName}'s Email:{' '}
                            </span>
                            <span style={style.textNotBold}><a style={style.linkStyle} href={`mailto:${resident.emailAddress}`}>{resident.emailAddress}</a></span>
                        </div>
                    ) : (
                        <></>
                    ),
                )}
            {maintenanceInfo && (
                <div style={style.elopementAlertContainer}>
                    <div style={{cursor: 'pointer'}} onClick={()=> setEditMaintenance(true)}><div style={style.name}>Maintenance Information</div></div>
                    <div>
                        {maintenanceInfo.okenter.toLowerCase() === 'yes'
                            ? 'Resident has indicated it is OK to enter.'
                            : 'Resident has NOT given permission to enter whe they are not home.'}
                    </div>
                    {maintenanceInfo.notes && <div>{maintenanceInfo.notes}</div>}
                </div>
            )}
            {showElopement && (<div style={style.elopementAlertContainer}>
                <div style={style.name}>Elopement Alert Settings</div>
                {resident.residentsNew.length > 1 && (
                    <>
                    <div style={style.paddingTop}>
                        <div style={style.textBold}>Select Residents(s) to send the alert for</div>
                    </div>
                        {resident.residentsNew.map(resident => (
                            <div className={'survey-button-container'}>
                                <input className={'survey-radio'} type="checkbox"
                                       id={resident.id} name={resident.id}
                                       value={resident.id}
                                       onClick={() => toggleResident(resident)}
                                       checked={checkBoxChecked(resident.uid)}/>
                                <label className={'checkbox-label'} htmlFor={resident.id}>{resident.firstName} {resident.lastName}</label>
                            </div>
                        ))}
                    </>
                )}
                <div style={style.textBold}>
                    Select which locations to send the alert to.
                </div>
                {elopementLocations.map(location => (
                    <div style={style.paddingTop}>
                        <div className={'survey-button-container'}>
                            <input className={'survey-radio'} type="checkbox"
                                   id={'l'+location.id} name={location.id}
                                   value={location.id}
                                   onClick={() => toggleLocations(location)}
                                   checked={locationSelected(location.id)}/>
                            <label className={'checkbox-label'}
                                   htmlFor={'l'+location.id}>{location.locationName}</label>
                        </div>
                    </div>
                ))}
                <button onClick={sendTheAlert} className={'survey-button-container'}>Send Alert</button>
            </div>)}
        </div>
    </>)

}

const style = {
    divPadding: {
        paddingTop: '10px',
    },
    defaultContainer: {
        alignItems: 'center',
    },
    imageContainer: {
        width: '80%',
        flexDirection: 'row',
    },
    name: {
        color: defaultColor,
        fontWeight: 'bold',
        fontSize: 26,
    },
    informationContainer: {
        width: '80%',
        paddingTop: 10,
        textAlign: 'left'
    },
    multiText: {
        flexDirection: 'row',
        paddingTop: 5,
        paddingBottom: 5,
    },
    textBold: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    textNotBold: {
        fontSize: 16,
    },
    elopementAlertContainer: {
        margin: 10,
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
    },
    paddingTop: {
        paddingTop: 10,
    },
    linkStyle: {
        textDecoration: 'none',
        color: 'inherit'
    }
};
export default ViewResident
