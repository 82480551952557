import Heading from "./Heading";

function About() {
    return (<>
        <Heading title={'About'} />
        <div className={'inner-container'}>
            <h1>About the App</h1>
            <p style={{textAlign: 'left'}}>
                The Garden Spot Communities app is designed for all members of the Garden Spot community, including
                employees, residents, and other authorized users. This app serves as the primary way to receive
                important updates through a personalized messaging stream, ensuring you stay connected with relevant
                information.
            </p>
            <p style={{textAlign: 'left'}}>
                With a built-in directory, access to important HR documents, dining menus, internal job postings,
                community events, and much more, the app is a comprehensive resource for staying informed. Rest assured,
                robust security features ensure that each user only sees the information they need, so employee-specific
                content remains accessible to employees only.
            </p>
            <p style={{textAlign: 'left'}}>
                You can download the Garden Spot Communities app from the Apple App Store or Google Play Store by
                searching “Garden Spot Communities.” If you don’t have a smartphone, you can also log in from any
                computer at <a href={'https://gsc.app'}>https://gsc.app</a>.
            </p>
        </div>
    </>)
}

export default About;
