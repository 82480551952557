import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Heading from "../Heading";
import MySpinner from "../MySpinner";
import {doGet, doPostBody} from "../../services/rest";
import {hasAdmin} from "../../services/menu";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilm, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import PopUp from "../PopUp";
import MediaForm from "./MediaForm";
import {faFolder} from "@fortawesome/free-solid-svg-icons/faFolder";
import {defaultColor} from "../../defaults/AppConfig";
import VideoViewer from "../stream/VideoViewer";
import HLSStream from "./HLSStream";

function Media() {
    const {id, '*': subpath} = useParams()
    const [loading, setLoading] = useState(true);
    const [mediaItems, setMediaItems] = useState([])
    const mediaAdmin = hasAdmin("ROLE_MEDIA")
    const [showAdd, setShowAdd] = useState(false);
    const navigator = useNavigate();
    const [mediaToShow, setMediaToShow] = useState()
    const [liveStream, setLiveStream] = useState();

    useEffect(() => {
        let url = `media/load/${id}`;
        if(subpath){
            url = `media/load/${id}?folder=${subpath}`;
        }
        doGet(url).then(res=>{
            setMediaItems(res)
            setLoading(false)
        })
    }, [id, subpath])

    if(loading){
        return (<>
            <Heading title={'Media'} />
            <MySpinner />
        </>)
    }

    const onItemClick = (item) => {
        if(item.folder){
            navigator(`/media/${id}/${item.url}`)
        }else{
            if(item.mediaType === 'YOUTUBE' || item.mediaType === 'VIMEO'){
                setMediaToShow(item.url)
            }else if(item.mediaType === 'LIVE'){
                setLiveStream(item.url)
            }

        }
    }

    const delteItem = async (item) => {
        if(window.confirm("Are you sure you want to delete this item?  If it is a folder everything inside the folder will be deleted")){
            let url = `media/admin/delete/${id}`;
            if(subpath){
                url = `media/admin/delete/${id}?currentFolder=${subpath}`;
            }
            setLoading(true)
            const resp = await doPostBody(url, item)
            setLoading(false)
            setMediaItems(resp)
        }
    }

    return(<>
        <Heading title={'Media'} icons={mediaAdmin ? [<FontAwesomeIcon onClick={() => setShowAdd(true)} cursor={'pointer'} icon={faPlus} />] : []} />
        <div className="inner-container" style={{paddingTop: 50}}>
            {mediaItems.map(item => (
                <div key={item.id} style={{display: 'flex', paddingBottom: 30}}>
                    <div onClick={() => onItemClick(item)} style={{display: "flex", flex: 1, alignItems: 'center', cursor: 'pointer'}}><FontAwesomeIcon color={defaultColor} icon={item.folder ? faFolder : faFilm} /> <div style={{paddingLeft: 10}}>{item.title}</div></div>
                    {mediaAdmin && <FontAwesomeIcon cursor={'pointer'} icon={faTrash} onClick={() => delteItem(item)} />}
                </div>
            ))}
        </div>
        {showAdd && <PopUp
            content={<MediaForm repository={id} folder={subpath} onComplete={data => {
                setShowAdd(false);
                setMediaItems(data)}} />}
            handleClose={()  => setShowAdd(false)}
             /> }
        {mediaToShow &&
            <PopUp
                content={<div className={'inner-container'}><VideoViewer video={mediaToShow} admin={false} /></div>}
                handleClose={()=>setMediaToShow(undefined)}
            />}
        {liveStream && <PopUp
            content={<HLSStream url={liveStream} /> }
            handleClose={()=>setLiveStream(undefined)}
        />}
    </>)
}
export default Media
