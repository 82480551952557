import React, {useEffect, useState} from "react";
import {useSubscription} from "react-stomp-hooks";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../../firebase/firebase";
import {mainUrl} from "../../services/rest";
import MaleThumbImage from "../../images/photo-placeholder-male-thumb.jpg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd, faEdit} from "@fortawesome/free-solid-svg-icons";
import PopUp from "../PopUp";
import NewComment from "./NewComment";

function Comments({id, currentComments}) {

    const [user] = useAuthState(auth);

    const [comments, setComments] = useState([]);

    const [selectedComment, setSelectedComment] = useState()

    const [displayAddComment, setDisplayAddComment] = useState(false)

    useSubscription(`/it/comments/${id}`, (message) => {
        const newComment = JSON.parse(message.body)
        setComments(prevComments => {
            const existingIndex = prevComments.findIndex((comment) => comment.id === newComment.id);

            // If index is -1, then the comment does not exist, add it to the end of the array
            if (existingIndex === -1) {
                return [...prevComments, newComment];
            }

            // Else, replace the existing comment with the new one.
            return prevComments.map((comment, index) => (index === existingIndex ? newComment : comment));
        });
    })

    useEffect(() => {
        if(currentComments){
            setComments(currentComments)
        }
    }, [currentComments])

    const editComment = (comment) => {
        setSelectedComment(comment);
        setDisplayAddComment(true);
    }

    const handleClose = () => {
        setSelectedComment(undefined)
        setDisplayAddComment(false)
    }


    return(<>
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <div style={{width: '100px'}}></div>
            <h4>Comments</h4>
            <div style={{width: '100px'}}><FontAwesomeIcon style={{cursor: "pointer"}} onClick={() => setDisplayAddComment(true)} icon={faAdd}/></div>
        </div>
        {comments.map((comment, index) => (
            <div className={'post'} key={index}>
                <div className={'post-header'}>
                    <img src={comment.commentBy ? mainUrl + 'menu/image/' + comment.commentBy.id : MaleThumbImage}
                         className={'profile-pic'}/>
                    <p className={'post-title'}><i>Comment
                        By {comment.commentBy.displayName}</i>
                    </p>
                    <div style={{display: "flex", flex: 1, justifyContent: 'flex-end'}}>{user.uid === comment.commentBy.id && <FontAwesomeIcon icon={faEdit} onClick={() => editComment(comment)} />}</div>
                </div>
                <div className={'reset'} dangerouslySetInnerHTML={{__html: comment.comment}}></div>
            </div>
        ))}
        {displayAddComment && <PopUp
            content={<NewComment woId={id} currentComment={selectedComment} closeComments={handleClose}/> }
            handleClose={handleClose}
            />}
    </>)

}

export default Comments;
