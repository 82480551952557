import {ThreeCircles} from "react-loader-spinner";

function MySpinner() {
    return(<div className={"loading-box"}><ThreeCircles
        height={50}
        width={50}
        color={"#e97200"}
    /></div>)
}

export default MySpinner
