import {useState} from "react";
import {NotificationContext} from "./NotificationContext";
import PopUp from "../PopUp";
import ElopementNotification from "./ElopementNotification";

function NotificationProvider({children}) {
    const [notification, setNotification] = useState(null);
    const showTypes = ['elopement']

    const showNotification = (msg) => {
        if(msg.expire){
            const expiryDate = new Date(msg.expire);
            let currentDate = new Date();
            currentDate.setHours(0,0,0,0);
            if (currentDate > expiryDate) {
                console.log('Current date is after expiry date. Function will not run.');
                return;
            }
        }
        if(showTypes.includes(msg.type)){
            setNotification(msg)
        }
    }

    const hideNotification = () => {
        setNotification(null);
    };

    const renderNotification = () =>{
        switch (notification.type){
            case 'elopement':
                return <ElopementNotification notification={notification} />
        }
    }

    return (
        <NotificationContext.Provider value={{ notification, showNotification, hideNotification }}>
            {children}
            {notification && <PopUp content={renderNotification()} handleClose={hideNotification} />}
        </NotificationContext.Provider>
    );
}
export default NotificationProvider
