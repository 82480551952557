import React, {useCallback, useMemo, useState} from "react";
import {doPost} from "../../services/rest";
import {useDropzone} from "react-dropzone";

function EmergencyDocumentsForm({documentRepository, onCompleted}) {
    const [documentName, setDocumentName] = useState();
    const [file, setFile] = useState();
    const [loading, setLoading] = useState(false)

    const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    };

    const focusedStyle = {
        borderColor: '#2196f3'
    };

    const acceptStyle = {
        borderColor: '#00e676'
    };

    const rejectStyle = {
        borderColor: '#ff1744'
    };
    const onDrop = useCallback(acceptedFiles => {
        setFile(acceptedFiles[0]);
    }, [])
    const save = async () => {
        if(!documentName){
            window.alert("Document title is required")
            return
        }
        if(!file){
            window.alert("Please select a file to continue")
            return
        }
        setLoading(true)
        const formData = new FormData();
        formData.append('file', file)
        formData.append("name", documentName)
        formData.append('repo', documentRepository)
        const resp = await doPost('emergency/admin/document/upload', formData)
        onCompleted(resp)
    }
    const {getRootProps, getInputProps, isDragActive,isFocused,
        isDragAccept,
        isDragReject} = useDropzone({onDrop, maxFiles: 1})
    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);


    return(<div className={'inner-container'}>
        <h3>Upload a file</h3>
        <div className={'search-container'}>
            <input className={'search-input'} placeholder={'Enter Document Name'} value={documentName} title={'name'}
                   onChange={e => setDocumentName(e.target.value)}/>
        </div>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: 5}}>
        <div {...getRootProps()}>
            <input {...getInputProps({style})} />
        </div>
        </div>
        {loading ? <div style={{
            border: '16px solid #f3f3f3',
            borderRadius: '50%',
            borderTop: '16px solid #e97200',
            width: '10px',
            height: '10px',
            marginLeft: 10,
            animation: 'spin 2s linear infinite'
        }}/> : <button onClick={save} className={'submitPost'}>Upload Document</button>}
</div>)
}

export default EmergencyDocumentsForm
