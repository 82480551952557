

const formatPrittyDate = (date) => {
    const newDate = new Date(date)
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
    };
    return newDate.toLocaleDateString('en-US', options)
}

export {
    formatPrittyDate
}
