import {useEffect, useState} from "react";
import Heading from "../Heading";
import MySpinner from "../MySpinner";
import {doGet, doPostBody} from "../../services/rest";
import moment from "moment";
import {auth} from "../../firebase/firebase";
import {resolvePath, useNavigate} from "react-router-dom";
import {useSubscription} from "react-stomp-hooks";
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd, faPeopleCarry, faPeopleGroup, faPerson} from "@fortawesome/free-solid-svg-icons";
import {defaultColor} from "../../defaults/AppConfig";
import PopUp from "../PopUp";
import UserChatSearch from "./UserChatSearch";
import CreateGroupMessage from "./CreateGroupMessage";
function ChatMessaging() {
    const [myConversations, setMyConversations] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [showUserSearch, setShowUserSearch] = useState(false);
    const [showGroupMessage, setShowGroupMessage] = useState(false);
    useSubscription(`/user/${auth.currentUser.email}/chats`, (message) => {
        console.log(JSON.parse(message.body))
        setMyConversations(JSON.parse(message.body));
    })
    useEffect(() => {
        doGet('chat/get-my-conversations').then(response => {
            setMyConversations(response);
            setLoading(false)
        })
    }, [])

    const getSingleGroupsName = (groupMembers) => {
        const groupMember = groupMembers.filter(member => member.userId !== auth.currentUser.email)
        return groupMember[0]?.name;
    }

    const createNewUserChat = user => {
        const groupMember = {name: user.displayName, userId: user.userName}
        const you = {name: auth.currentUser.displayName, userId: auth.currentUser.email}
        const newMessage = {groupOwner: auth.currentUser.email, group: false, groupMembers: [groupMember, you]}
        setLoading(true)
        doPostBody('chat/create-group', newMessage).then(response => {
            if(!response.id){
                window.alert("Something has gone wrong, we could not create your new message")
            }
            setShowGroupMessage(false);
            setLoading(false)
        })

    }

    const createGroupChat = message =>{
        setLoading(true)
        doPostBody('chat/create-group', message).then(response => {
            if(!response.id){
                window.alert("Something has gone wrong, we could not create your new message")
            }
            setShowUserSearch(false);
            setLoading(false)
        })
    }

    const createHuddleUpMessage = () =>{
        setLoading(true)
        doGet('chat/huddle-up').then(resp => {
            navigate("/chat-messaging/chat", {state: {conversation: resp}})
        })
    }

    if(loading){
        return (<>
            <Heading title={'Chat Message'} />
            <MySpinner />
        </>)
    }

    return(<>
        <Heading title={'Chat Messages'} />
        <div className={'inner-container'} style={{paddingTop: 50}}>
            {myConversations.map(conversation => (<div onClick={() => navigate("/chat-messaging/chat", {state: {conversation: conversation}})}  style={{padding: 20, display: 'flex', flexDirection: 'column', alignItems: 'start', cursor: 'pointer'}}>
                <div>{conversation.groupTitle ? conversation.groupTitle : getSingleGroupsName(conversation.groupMembers)}</div>
                <div>Last Message: {moment(new Date(conversation.lastMessageSent)).format("MM/DD/YYYY hh:mm A")}</div>
            </div>))}
        </div>
        <Fab
            mainButtonStyles={{backgroundColor: defaultColor}}
            alwaysShowTitle={true}
            actionButtonStyles={{backgroundColor: defaultColor}}
            icon={<FontAwesomeIcon icon={faAdd} />}
        >
            <Action
                onClick={() => setShowUserSearch(true)}
                text={'Individual'}
            ><FontAwesomeIcon icon={faPerson} /></Action>
            <Action
                onClick={() => setShowGroupMessage(true)}
                text={'Group'}
            ><FontAwesomeIcon icon={faPeopleGroup} /></Action>
            <Action
                onClick={createHuddleUpMessage}
                text={'Huddle Up'}
            ><FontAwesomeIcon icon={faPeopleCarry} /></Action>
        </Fab>
        {showUserSearch && <PopUp
            content={<UserChatSearch createNewChat={e => createNewUserChat(e)}/>}
            handleClose={()=>setShowUserSearch(false)}
        />}
        {showGroupMessage && <PopUp
            content={<CreateGroupMessage createGroup={message => createGroupChat(message)} />}
            handleClose={()=>setShowGroupMessage(false)}
        />}
    </>)
}
export default ChatMessaging
