import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../../firebase/firebase";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {hasAdmin} from "../../services/menu";
import {doGet, doPost, doPostBody} from "../../services/rest";
import MySpinner from "../MySpinner";
import PopUp from "../PopUp";
import {Draggable} from "react-drag-reorder";
import {Editor} from "@tinymce/tinymce-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import Heading from "../Heading";

function SurveyCreator() {

    let { id } = useParams();

    const [user, loading] = useAuthState(auth);
    const [loadingDisplay, setLoadingDisplay] = useState(true)
    const navigate = useNavigate();
    const [surveyData, setSurveyData] = useState({})
    const [locationSelectsion, setLocationSelections] = useState([])
    const [popUpOpen, setPopUpOpen] = useState(false)
    const [currentSelections, setCurrentSelections] = useState([])
    const [newAnswer, setNewAnswer] = useState("")
    const [groupQuestionData, setGroupQuestionData] = useState({})
    const [reorderGroup, setReorderGroup] = useState(false)
    const [reorderQuestions, setReOrderQuestions] = useState(false)
    const [reorderQuestionsGroupId, setReorderQuestionsGroupId] = useState(0)
    const [allItemsHolder, setAllItemsHolder] = useState([])
    const adminRolId = "ROLE_SURVEY_ADMIN"

    useEffect(() => {
        if(loading) return;
        if(!user) navigate("/login")
        if(!hasAdmin(adminRolId)) navigate("/surveys")
            loadLocationSelections().then(()=>{
                if(id !== undefined && id !== null){
                    doGet("surveys/load/"+id).then((res)=>{
                        setSurveyData(res)
                        setLoadingDisplay(false);
                    })
                }else{
                    setLoadingDisplay(false);
                }
            })

    }, [user, loading, navigate]);

    const loadLocationSelections = async () =>{
        const locations = await doGet("surveys/create/locations")
        setLocationSelections(locations)
    }

    const onUpdate = event =>{
        setSurveyData({...surveyData, [event.target.name]: event.target.value})
    }


    const onLoacationCheckBoxChange = (item) =>{
        const items = surveyData.assignedAppLocations ?? []
        let hasValue = items.some(obj => obj.id === item.id);
        if(hasValue){
            const filteredItems = items.filter(i => i.id !== item.id)
            setSurveyData({...surveyData, assignedAppLocations: filteredItems})
        } else {
            setSurveyData({...surveyData, assignedAppLocations: [...items, item]})
        }
    }

        const shouldBeChecked = (item) =>{
            const items = surveyData.assignedAppLocations ?? []
            let shouldBeChecked = false;
            for(const i in items){
                if(items[i].id === item.id){
                    shouldBeChecked = true
                }
            }
            return shouldBeChecked
        }

        const onQuestionItemChange = (event, groupIndex, questionIndex) =>{
            const newSurveyData = {...surveyData}; // Create a copy of surveyData
            newSurveyData.questionGroups = [...surveyData.questionGroups]; // Create a copy of questionGroups
            newSurveyData.questionGroups[groupIndex].questions = [...surveyData.questionGroups[groupIndex].questions]; // Create a copy of questions array of the indexed question group
            newSurveyData.questionGroups[groupIndex].questions[questionIndex][event.target.name] = event.target.value; // Update the specific question item in the questions array
            setSurveyData(newSurveyData);
        }



    if(loadingDisplay){
        return (<>
            <Heading title={"Create New Survey"} />
            <MySpinner />
        </>)
    }

    const addSurveyQuestionGroup = () =>{
        const groups = surveyData.questionGroups ?? []
        setSurveyData({...surveyData, questionGroups: [...groups, {}]})
    }

    const addQuestionToGroup = (index) =>{
        const newSurveyData = {...surveyData}; // Create a copy of surveyData
        newSurveyData.questionGroups = [...surveyData.questionGroups]; // Create a copy of questionGroups
        const questions = newSurveyData.questionGroups[index].questions ?? []
        newSurveyData.questionGroups[index].questions = [...questions, {}]; // Update the questions array of the indexed question group
        setSurveyData(newSurveyData);
    }

    const addQuestionAnswer = async (groupIndex, questIndex) =>{
        setLoadingDisplay(true)
        setGroupQuestionData({'groupIndex': groupIndex, 'questionIndex':questIndex})
        const loadQuestions = await doGet("surveys/create/load-all-questions")
        setCurrentSelections(loadQuestions)
        setAllItemsHolder(loadQuestions)
        setLoadingDisplay(false)
        setPopUpOpen(true)
    }

    const onSelectionSelect = (selection) =>{
        const newSurveyData = {...surveyData}
        const questionAnswers = newSurveyData.questionGroups[groupQuestionData.groupIndex].questions[groupQuestionData.questionIndex].questionAnswers ?? []
        let add = true;
        for(const i in questionAnswers){
            if(questionAnswers[i].id === selection.id){
                add = false
            }
        }
        if(add){
            questionAnswers.push(selection)
            newSurveyData.questionGroups[groupQuestionData.groupIndex].questions[groupQuestionData.questionIndex].questionAnswers = questionAnswers
            setSurveyData(newSurveyData)
        }
    }

    const removeItemFromChoices = (choiceIndex, groupId, questionId) =>{
        if(window.confirm("Are you sure you want to remove this option?  It will not affect past surveys taken.")){
            const newSurveyData = {...surveyData};
            const questions  = newSurveyData.questionGroups[groupId].questions[questionId].questionAnswers ?? []
            questions.splice(choiceIndex)
            newSurveyData.questionGroups[groupId].questions[questionId].questionAnswers = questions;
            setSurveyData(newSurveyData)
        }
    }

        const saveNewAnswer = async () =>{
            if(newAnswer.length === 0){
                alert("Please enter a new answer")
                return
            }
            const formData = new FormData();
            formData.set("answer", newAnswer)
            const response = await doPost("surveys/create/new-answer", formData)
            if(response.success){
                setCurrentSelections([...currentSelections, response.newAnswer])
            }else{
                alert(response.reason)
            }
        }


    const onUpdateGroup = (event, myIndex) =>{
        setSurveyData({...surveyData, questionGroups: surveyData.questionGroups.map((group, index) => {
            if (index === myIndex) {
                return {...group, groupDescription: event};
            }
            return group;
        })});
    }

    const onUpdateTextValue = (event, myIndex) => {
        setSurveyData({...surveyData, questionGroups: surveyData.questionGroups.map((group, index) => {
                if (index === myIndex) {
                    return {...group, [event.target.name]: event.target.value};
                }
                return group;
            })});
    }

    const saveForm = async () =>{
        setLoadingDisplay(true)
        const resp = await doPostBody("surveys/create/save", surveyData)
        setLoadingDisplay(false)
        setSurveyData(resp)
    }

    const getChangedPos = (currentPos, newPos) => {
        const newSurveyData = {...surveyData}
        newSurveyData.questionGroups.splice(newPos, 0, newSurveyData.questionGroups.splice(currentPos, 1)[0])
        setSurveyData(newSurveyData)
    }

    const getChangedPosquestions = (currentPos, newPos) => {
        const newSurveyData = {...surveyData}
        newSurveyData.questionGroups[reorderQuestionsGroupId].questions.splice(newPos, 0, newSurveyData.questionGroups[reorderQuestionsGroupId].questions.splice(currentPos, 1)[0])
        setSurveyData(newSurveyData)
    }
    const reOrderQuestions = (groupIndex) =>{
        setReorderQuestionsGroupId(groupIndex)
        setReOrderQuestions(true)
    }
    const deleteGroup = async (group, index) =>{
        if(window.confirm("Are you sure you want to delete this group?  It will delete all the questions and answers associated with it.")){
            setLoadingDisplay(true);
            if(group.id === undefined || group.id === null || group.id === 0){
                //this means it has not been saved yet, so its just needs removed from the array.
                const questionGroupsTmp = {...surveyData}
                questionGroupsTmp.questionGroups.splice(index, 1)
                setSurveyData(questionGroupsTmp)
            }else{
                const deleteResponse = await doGet("surveys/create/delete-group/"+group.id)
                setSurveyData(deleteResponse)
            }
            setLoadingDisplay(false);
        }
    }

    const filterSelections = event =>{
        if(event.target.value.trim() === ''){
            setCurrentSelections(allItemsHolder)
        }
        const filteredArray = allItemsHolder.filter(obj => obj.answer.toLowerCase().includes(event.target.value.toLowerCase()))
        setCurrentSelections(filteredArray)
    }

    const editSelection = async (selection) =>{
        const newName = window.prompt("Enter a updated answer to ("+selection.answer+").")
        if(newName !== undefined && newName !== null && newName !== '' && newName !== selection.answer){
            selection.answer = newName
            const resp = await doPostBody("surveys/create/update-answer", selection)
            setCurrentSelections(resp)
            setAllItemsHolder(resp)
            saveForm()
        }
    }

    const deleteQuestion = async (question, index) =>{
        if(window.confirm("Are you sure you want to delete this question?  It will delete all the answers associated with it.")){
            setLoadingDisplay(true)
            if(question.id === undefined || question.id === null || question.id === 0){
                const questionGroupsTmp = {...surveyData}
                questionGroupsTmp.questionGroups[reorderQuestionsGroupId].questions.splice(index, 1)
                setSurveyData(questionGroupsTmp)
            }else{
                const deleteResponse = await doGet("surveys/create/delete-question/"+question.id)
                setSurveyData(deleteResponse)
            }
            setLoadingDisplay(false)
        }
    }

    return (<>
        <Heading title={"Create New Questionnaire"} />
        <div className={'main-container'}>
            <div className={'inner-container'}>
                <div className={'input-container'}>
                    <input className={'fancy-input'} name={'surveyName'} onChange={onUpdate}
                           placeholder={'Enter Questionnaire Title'} value={surveyData.surveyName}/>
                </div>
                <div className={'input-container'}>
                    <input className={'fancy-input'} name={'surveyDescription'} onChange={onUpdate}
                           placeholder={'Enter Questionnaire Description'} value={surveyData.surveyDescription}/>
                </div>

                <h4>Select Locations to assign the Questionnaire</h4>
                {locationSelectsion.map((data) => (
                    <div><input type={'checkbox'} onChange={() => onLoacationCheckBoxChange(data)} name={'location'}
                                value={data.id} checked={shouldBeChecked(data)}/> {data.locationName} </div>
                ))}

                {surveyData.questionGroups !== undefined ? surveyData.questionGroups.map((data, index) => (

                    <div className={'group'} id={'group_' + index} key={index}>
                        <div style={{display: 'flex', justifyContent: "space-between", width: '100%'}}>
                            <div style={{cursor: 'pointer'}} onClick={() => setReorderGroup(true)}>Manage Groups</div>
                            <div style={{cursor: 'pointer'}} onClick={() => reOrderQuestions(index)}>Manage
                                Questions
                            </div>
                        </div>
                        <h4>Group {index + 1}</h4>

                        <div className={'input-container'}>
                            <input className={'fancy-input'} name={'groupName'} onChange={e => onUpdateTextValue(e, index)}
                                   placeholder={'Enter Group Title'} value={data.groupName}/>
                        </div>
                        <div className={'input-container'}>
                            <Editor
                                onEditorChange={(e) => onUpdateGroup(e, index)}
                                licenseKey={'gpl'}
                               // apiKey={'kr6kh68cex59yd6e19ad2wqrex5ooo2jhiui3bidudqult41'}
                                value= {data.groupDescription === undefined ? "<p>Enter group description or delete this text.</p>" : data.groupDescription}
                                init={{
                                    height: 200,
                                    menubar: false,
                                    plugins: [
                                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount', 'table'
                                    ],
                                    toolbar: 'undo redo | blocks | link table|' +
                                        'bold italic forecolor | alignleft aligncenter ' +
                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                        'removeformat | toggle',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                }}
                                tinymceScriptSrc={'/tinymce/tinymce.min.js'}
                            />
                        </div>
                        {surveyData.questionGroups[index].questions !== undefined ? surveyData.questionGroups[index].questions.map((data1, questionIndex) => (
                            <div className={'question-container'} id={'group_' + index + '_question_' + questionIndex}>
                                <h4>Question {questionIndex + 1}</h4>
                                <div className={'input-container'}>
                                    <input className={'fancy-input'} name={'question'}
                                           onChange={e => onQuestionItemChange(e, index, questionIndex)}
                                           placeholder={'Enter Question'} value={data1.question}/>
                                </div>
                                <br/>
                                <div className={'input-container'}>
                                    <select style={{width: "100%", boxSizing: "border-box"}} className={'fancy-input'}
                                            onChange={e => onQuestionItemChange(e, index, questionIndex)}
                                            name={'questionType'}>
                                        <option value={""} selected={data1.questionType === undefined}
                                                disabled={true}>Select question type
                                        </option>
                                        <option value={"SHORTTEXT"} selected={data1.questionType === "SHORTTEXT"}>Short
                                            Text
                                        </option>
                                        <option value={"LONGTEXT"} selected={data1.questionType === "LONGTEXT"}>Long
                                            Text
                                        </option>
                                        <option value={"MULTIPLECHOICE"}
                                                selected={data1.questionType === "MULTIPLECHOICE"}>Multiple Choice
                                            Checkboxes
                                        </option>
                                        <option value={"MULTIPLECHOICESINGLE"}
                                                selected={data1.questionType === "MULTIPLECHOICESINGLE"}>Multiple Choice
                                            Radio
                                        </option>
                                    </select>
                                </div>
                                {surveyData.questionGroups[index].questions[questionIndex].questionType !== undefined && (surveyData.questionGroups[index].questions[questionIndex].questionType === "MULTIPLECHOICE" || surveyData.questionGroups[index].questions[questionIndex].questionType === "MULTIPLECHOICESINGLE") ?
                                    <div>{surveyData.questionGroups[index].questions[questionIndex].questionAnswers !== undefined ? <>
                                        <div>Current Choices</div>
                                        <div className={"flex"}>
                                            {surveyData.questionGroups[index].questions[questionIndex].questionAnswers.map((answerData, answerIndex) => (<>
                                                <div className={'answer-choice'}>
                                                    <span>{answerData.answer}</span>
                                                    <button
                                                        onClick={() => removeItemFromChoices(answerIndex, index, questionIndex)}
                                                        className="close-btn">X
                                                    </button>
                                                </div>
                                            </>))}
                                        </div>
                                    </> : <></>}
                                        <button className={'fancy-button'}
                                                onClick={() => addQuestionAnswer(index, questionIndex)}
                                                name={'add-answer'}>Add Answer
                                        </button>
                                    </div> : <></>
                                }
                            </div>
                        )) : <></>}
                        <button onClick={() => addQuestionToGroup(index)} name={'newGroup'}
                                className={'fancy-button'}>Add Question
                        </button>
                    </div>

                )) : <></>}
                <div style={{paddingTop: '10px'}}>
                    <button onClick={addSurveyQuestionGroup} name={'newGroup'} className={'fancy-button'}>Add Question
                        Group
                    </button>
                </div>
                <hr className="fancy-line"/>
                <div style={{paddingTop: '10px'}}>
                    <button onClick={saveForm} name={'newGroup'} className={'fancy-button'}>Save Questionnaire
                    </button>
                </div>
            </div>
            {popUpOpen && <PopUp
                content={<>
                    <div className="input-container1">
                        <input type="text" onKeyDown={filterSelections} onChange={e => setNewAnswer(e.target.value)} placeholder="Add New Answer"/>
                        <button onClick={saveNewAnswer}>Add</button>
                    </div>
                    <div>- or -</div>
                    <div>Select a current answer</div>
                    <br/>
                    {currentSelections != null && currentSelections.map((selection)=>(
                        <><div className={'group flex-space-between selection'} ><span onClick={()=>onSelectionSelect(selection)}>{selection.answer}</span>
                            <FontAwesomeIcon style={{cursor: 'pointer'}} onClick={() => editSelection(selection)} icon={faEdit} />
                        </div></>
                    ))}
                </>}
                handleClose={() => setPopUpOpen(!popUpOpen)}
            ></PopUp>}
            {reorderGroup && <PopUp
                content={<>
                    Drag to re-order groups. Then Press OK when you are finished.
                    <Draggable onPosChange={getChangedPos}>
                        {surveyData.questionGroups.map((group, index)=>(
                            <div key={index} className={'group flex-space-between'}>
                                {group.groupName}
                                <FontAwesomeIcon icon={faTrash} style={{cursor: 'pointer'}} onClick={() => deleteGroup(group, index)}/>
                            </div>
                        ))}
                    </Draggable>
                    <button onClick={()=>setReorderGroup(false)}>OK</button>
                </>}
                handleClose={() => setReorderGroup(!reorderGroup)}
            ></PopUp>}
            {reorderQuestions && <PopUp
                content = {<>Drag to re-order questions, then press ok when finished.
                    <Draggable onPosChange={getChangedPosquestions}>
                        {surveyData.questionGroups[reorderQuestionsGroupId].questions.map((question, index) => (
                            <div key={index} className={'flex-space-between group'}>
                                {question.question}
                                <FontAwesomeIcon icon={faTrash} style={{cursor: 'pointer'}} onClick={() => deleteQuestion(question, index)}/>
                            </div>
                        ))}
                    </Draggable>
                    <button onClick={() => setReOrderQuestions(false)}>OK</button>
                </>}
                handleClose={() => setReOrderQuestions(false)}
            ></PopUp>}
        </div>

    </>)


}

export default SurveyCreator
