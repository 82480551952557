import {useEffect, useState} from "react";
import {doGet} from "../../services/rest";

function CheckListSelection({setSelectedCheckList}) {

    const [checkLists, setCheckLists] = useState([]);

    useEffect(() => {
        doGet("it-admin/get-checklists").then(data => setCheckLists(data))
    }, [])

    return (<div className={'inner-container'}>
        <h4>Select a checklist.</h4>
        {checkLists.map(list => (<div onClick={() => setSelectedCheckList(list)} style={{cursor: "pointer"}} key={list.id}>{list.title}</div>))}
    </div> )
}
export default CheckListSelection
