function ImageLoadingIndicator({number}) {
    return (<div style={{display: "flex"}}>
        {Array.from({length: number}, (_, i) => <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100px',
            width: '100px',
            border: '1px solid black',
            paddingRight: 10
        }}>
            <div style={{
                border: '16px solid #f3f3f3',
                borderRadius: '50%',
                borderTop: '16px solid #e97200',
                width: '50px',
                height: '50px',
                animation: 'spin 2s linear infinite'
            }}/>
        </div>)}
    </div>)
}

export default ImageLoadingIndicator;
