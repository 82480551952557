import {useState} from "react";
import {doPost, doPostBody} from "../../services/rest";

function AddAdminUser({calendar, currentAdmins, onUpdate}) {

    const [users, setUser] = useState([]);

    const search = async event =>{
        if(event.key === 'Enter'){
            if(event.target.value.length < 3){
                window.alert("Please enter more to search by.")
                return
            }
            const formData = new FormData();
            formData.set('search', event.target.value);
            const resp = await doPost('calendar-admin/search-users', formData)
            const filteredList = resp.filter(respObj =>
                !currentAdmins.some(adminObj => adminObj.id === respObj.id)
            );
            setUser(filteredList)
        }
    }

    const onUserSelected = async (user) =>{
        if(window.confirm("Are you sure you want to make this user an admin of this calendar?")){
            const data = {gscUsers: user, calendar: calendar, isAdmin: true}
            const resp = await doPostBody('calendar-admin/add-admin-user', data);
            onUpdate(resp)
        }
    }

    return(<div className={'inner-container'}>
        <input className={'search-input'} type={'text'} placeholder={'Search For Users'} onKeyDown={search} />
        {users.map(user =>(<div style={{padding: 10, cursor: 'pointer'}} onClick={() => onUserSelected(user)} key={user.id}>{user.displayName}<div style={{fontSize: 8}}>{user.userName}</div></div>))}
    </div>)
}
export default AddAdminUser
