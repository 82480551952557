import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {doGet} from "../../services/rest";
import Heading from "../Heading";
import MySpinner from "../MySpinner";
import TeamMemberImages from "../directory/TeamMemberImages";
import PopUp from "../PopUp";
import TheEmployeeForm from "./TheEmployeeForm";

function EmployeeVehicleForm() {
    const {id} = useParams()
    const [employeeWithVehicle, setEmployeeWithVehicle] = useState({})
    const [loading, setLoading] = useState(true);
    const [vehicleEdit, setVehicleEdit] = useState({})
    const [showForm, setShowForm] = useState(false);
    useEffect(() => {
        doGet(`vehicles-admin/load/team-member/${id}`).then(resp =>{
            setEmployeeWithVehicle(resp)
            setLoading(false)
        })
    }, [id]);

    if(loading){
        return (<>
            <Heading />
            <MySpinner />
        </>)
    }
    return (<>
        <Heading title={`${employeeWithVehicle.fname} ${employeeWithVehicle.lname}`} />
        <div className={'inner-container'} style={{paddingTop: 50}}>
            <div style={{display: 'flex'}}>
                <div style={{flex: 1}}>
                    <TeamMemberImages teamMember={employeeWithVehicle} thumb={false}/>
                </div>
                <div style={{flex: 1}}>
                    <div style={{textAlign: "left", paddingLeft: 20, paddingTop: 10}}><span
                        style={{fontWeight: "bolder"}}>Name: </span>{`${employeeWithVehicle.fname} ${employeeWithVehicle.lname}`}
                    </div>
                    {employeeWithVehicle.phone &&
                        <div style={{textAlign: "left", paddingLeft: 20, paddingTop: 10}}><span
                            style={{fontWeight: "bolder"}}>Phone: </span>{`${employeeWithVehicle.phone}`}</div>}
                    {employeeWithVehicle.mobile &&
                        <div style={{textAlign: "left", paddingLeft: 20, paddingTop: 10}}><span
                            style={{fontWeight: "bolder"}}>Cell: </span>{`${employeeWithVehicle.mobile}`}</div>}
                    <div style={{textAlign: "left", paddingLeft: 20, paddingTop: 10}}><span
                        style={{fontWeight: "bolder"}}>Department: </span>{`${employeeWithVehicle.teamDepartments.name}`}
                    </div>
                    <div style={{textAlign: "left", paddingLeft: 20, paddingTop: 10}}><span
                        style={{fontWeight: "bolder"}}>Position: </span>{`${employeeWithVehicle.position}`}
                    </div>
                </div>
            </div>
            <h2>Vehicles</h2>
            <table style={{width: '100%'}}>
                <tr><th>Year</th><th>Make</th><th>Model</th><th>Color</th><th>Licence Plate</th><th>Edit</th></tr>
            {employeeWithVehicle?.employeeVehiclesList?.map((vehicle) => (
                <tr key={vehicle.id}>
                    <td>{vehicle.year}</td>
                    <td>{vehicle.make}</td>
                    <td>{vehicle.model}</td>
                    <td>{vehicle.color}</td>
                    <td>{vehicle.licencePlate}</td>
                    <td><span style={{cursor: 'pointer'}} onClick={() => {
                        setVehicleEdit(vehicle)
                        setShowForm(true)
                    }}>Edit</span></td>
                </tr>
            ))}
            </table>
            <button className={'submitPost'} style={{marginTop: 20}} onClick={() =>{
                setVehicleEdit(undefined)
                setShowForm(true)
            }}>Add Vehicle</button>
        </div>
        {showForm && (<PopUp
            content={<TheEmployeeForm currentVehicle={vehicleEdit} employeeId={employeeWithVehicle.employeeID} refreshId={employeeWithVehicle.uid} onRefresh={data => {
                setEmployeeWithVehicle(data)
                setVehicleEdit(undefined)
                setShowForm(false)
            }} />}
            handleClose={()=> {
                setVehicleEdit(undefined)
                setShowForm(false)}}
        />)}
    </>)
}

export default EmployeeVehicleForm
