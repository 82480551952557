import {useEffect, useState} from "react";
import MySpinner from "../MySpinner";
import {doGet, doPost} from "../../services/rest";
import {formatFirstNameLastName} from "../directory/NameFormatters";
import ResidentImages from "../directory/ResidentImages";
import TeamMemberImages from "../directory/TeamMemberImages";
import {useNavigate} from "react-router-dom";

function PersonSearch({onPersonSelect, appLocations}) {

    const [searchResults, setSearchResults] = useState({});
    const [searchTerm, setSearchTerm] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [currentWorkOrders, setCurrentWorkOrders] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        doGet(`it-admin/current-open`).then(res => setCurrentWorkOrders(res))
    }, []);

    const search = () => {
        setIsLoading(true)
        const data = new FormData();
        data.append("search", searchTerm);
        doPost('it-admin/user-search', data).then(res => {
            console.log(res)
            setSearchResults(res)
            setIsLoading(false)
        })
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
           search();
        }
    }

    if(isLoading){
        return <MySpinner />
    }

    const hasWorkOrder = (gscId1, gscId2) => {
        console.log(gscId1, gscId2)
        return currentWorkOrders.some(wo =>
            (gscId1 !== null && (wo.GSCId1 === gscId1 || wo.GSCId2 === gscId1)) ||
            (gscId2 !== null && (wo.GSCId1 === gscId2 || wo.GSCId2 === gscId2))
        );
    };

    const openCurrentWOrkOrder = (gscId1, gscId2) => {
        const workOrder = currentWorkOrders.find(wo =>
            (gscId1 !== null && (wo.GSCId1 === gscId1 || wo.GSCId2 === gscId1)) ||
            (gscId2 !== null && (wo.GSCId1 === gscId2 || wo.GSCId2 === gscId2))
        );
        if(workOrder){
            navigate(`/workorders/edit/${workOrder.id}`, {state: {locations: appLocations}});
        }else{
            alert("No work order found for this resident.")
        }
    }



    return(<>
        <div className={'search-container'} style={{alignItems: 'center'}}>
            <input onChange={e => setSearchTerm(e.target.value)} value={searchTerm} className={'search-input'} type={'text'} onKeyDown={handleKeyDown} placeholder={'Enter Search Term'} /><div style={{paddingLeft: 10}}><button onClick={search} className={'submitPost'}>Search</button></div>
        </div>
            {searchResults.residents && <div>
                <h4>Residents</h4>
                {searchResults.residents.map((resident, index) => (
                    <div  key={index} style={(hasWorkOrder(resident?.residentsNew[0]?.residentNumber, resident?.residentsNew[1]?.residentNumber)
                        ? {border: '1px solid red'}
                        : {})}>
                    <div
                        onClick={() => onPersonSelect(resident, 'resident')}
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingBottom: 10,
                            cursor: "pointer",
                        }}
                    >
                        <ResidentImages resident={resident} thumb={true}/>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            flex: 1,
                            paddingLeft: 10
                        }}>
                            <div><b>{formatFirstNameLastName(resident.residentsNew)}</b></div>
                            <div>{resident.homeNumber} {resident.address}</div>
                        </div>

                    </div>
                        {hasWorkOrder(resident?.residentsNew[0]?.residentNumber, resident?.residentsNew[1]?.residentNumber) && <div onClick={() => openCurrentWOrkOrder(resident?.residentsNew[0]?.residentNumber, resident?.residentsNew[1]?.residentNumber)} style={{color: 'red', cursor: 'pointer'}}>View Work Order</div>}
                    </div>))}
            </div>}
        {searchResults.teamMembers && <div>
            <h4>Team Members</h4>
            {searchResults?.teamMembers?.map((teamMember, index) => (
                <div key={index} style={(hasWorkOrder(teamMember?.employeeID, null)
                    ? {border: '1px solid red'}
                    : {})}>
                <div onClick={() => onPersonSelect(teamMember, 'team')} style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingBottom: 10,
                    cursor: "pointer"
                }}>
                    <TeamMemberImages teamMember={teamMember} thumb={true}/>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', flex: 1, paddingLeft: 10}}>
                        <div><b>{teamMember.fname} {teamMember.lname}</b></div>
                        <div>{teamMember.position}</div>
                    </div>
                </div>
                    {hasWorkOrder(teamMember?.employeeID, undefined) && <div
                        onClick={() => openCurrentWOrkOrder(teamMember?.employeeID, null)}
                        style={{color: 'red', cursor: 'pointer'}}>View Work Order</div>}
                </div>
            ))}
        </div>}
    </>)
}

export default PersonSearch
