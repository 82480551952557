import {useEffect, useState} from "react";
import MySpinner from "../MySpinner";
import {doGet, doPostBody} from "../../services/rest";
import MyCheckBox from "../MyCheckBox";

function ShowDeleteOptions({uid, close}) {
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState({});
    const [deleteRequest, setDeleteRequest] = useState({});
    const [response, setResponse] = useState();


    useEffect(() => {
        doGet(`user-admin/info/${uid}`).then((res) => {
            console.log(res);
            setItems(res)
            setLoading(false)
        })
    }, [uid])

    const onChange = event =>{
        setDeleteRequest(prevState => ({
            ...prevState,
            [event.target.name]: !prevState[event.target.name] ?? true
        }))
    }

    const submitRequest = async () => {
        if(window.confirm("Are you sure you want to delete?")){
            deleteRequest.gscUserId = uid;
            deleteRequest.directoryContext = items.directoryContextId;
            setLoading(true);
            const resp = await doPostBody('user-admin/user/delete', deleteRequest);
            setResponse(resp.resp);
            setLoading(false);
        }
    }

    if(loading){
        return <MySpinner />
    }

    if(response){
        return (<div className={'inner-container'}>
            <div dangerouslySetInnerHTML={{__html: response}}></div>
            <button className={'submitPost'} onClick={close}>OK</button>
        </div>)
    }



    return (<div className={'inner-container'}>
        <h3>Select what you would like to delete</h3>
        {items.teamMember && <MyCheckBox id={'tm'} checked={deleteRequest.teamMember} onChange={onChange} name={'teamMember'} label={'Delete from Team Member Directory.'}/>}
        {items.residentsNew && <MyCheckBox id={'res'} checked={deleteRequest.residentsNew}  onChange={onChange}name={'residentsNew'} label={'Delete from Resident Directory.'}/>}
        {items.myFLUser && <MyCheckBox id={'fl'} onChange={onChange} checked={deleteRequest.myFLUser} name={'myFlUser'} label={'Delete from My Frederick Living.'}/>}
        {items.myGSVUser && <MyCheckBox id={'mygsv'} onChange={onChange} checked={deleteRequest.MyGSVUser} name={'myGSVUser'} label={'Delete from My GSV.'}/>}
        {items.activeDirectoryAccount && <MyCheckBox onChange={onChange} checked={deleteRequest.activeDirectoryAccount} name={'activeDirectoryAccount'} id={'activeDirectory'} label={'Delete Active Directory Account.'}/>}
        <MyCheckBox name={'gscApp'} id={'gscApp'} checked={deleteRequest.gscApp} onChange={onChange} label={'Delete GSC App User.'}/>
        <button onClick={submitRequest} className={'submitPost'}>Delete</button>
    </div>)
}
export default ShowDeleteOptions;
