import React from 'react';
import ResidentImages from "./ResidentImages";
import TeamMemberImages from "./TeamMemberImages";
import {formatFirstNameLastName} from "./NameFormatters";

function DirectoryResultsDisplay({directoryResponse, displayType, onClick}) {
    const data = [
        {title: 'Residents', data: directoryResponse.residents ?? []},
        {title: 'Team Members', data: directoryResponse.teamMembers ?? []},
        {title: 'Campus Numbers', data: directoryResponse.campusNumbers ?? []},
    ];


    if (displayType === "1") {
        data.splice(1, 2);
    } else if (displayType === "2") {
        data.splice(0, 1);
        data.pop();
    } else if (displayType === "3") {
        data.splice(0, 2);
    }

    const handleClick = (data, title) =>{
        onClick(data, title);
    }

    return (
        <div>
            {data.map((section, index) => (
                <React.Fragment key={index}>
                    <h2>{section.title}</h2>
                    <div className={'flex'}>
                        {section.data.length === 0 && <div>No Results Found</div>}
                        {section.data.map((item, i) => (
                            <div style={{width: 300, cursor: 'pointer'}} onClick={() => handleClick(item, section.title)} key={i}>
                                {
                                    {
                                        'Campus Numbers': (
                                            <div>
                                                <a style={{textDecoration: 'none', color: 'inherit'}} href={`tel:${item.number}`}>
                                                    <h3>{item.label}</h3>
                                                    <p>{item.number}</p>
                                                </a>
                                            </div>
                                        ),
                                        'Residents': (
                                            <div>
                                                <ResidentImages resident={item} thumb={true} />
                                                <h3>{formatFirstNameLastName(item.residentsNew)}</h3>
                                                <p>{item.homeNumber} {item.address}</p>
                                                <p>{item?.neighborhood?.neighborHoodCommunityName ?? ''}</p>
                                            </div>
                                        ),
                                        'Team Members': (
                                            <div>
                                                <TeamMemberImages teamMember={item} thumb={true} />
                                                <h3>{item.fname} {item.lname}</h3>
                                                <p>{item.community}</p>
                                                <p>{item?.teamDepartments?.name ?? ""}</p>
                                                <p>{item.position}</p>
                                            </div>
                                        )
                                    }[section.title]
                                }
                            </div>
                        ))}
                    </div>
                </React.Fragment>
            ))}
        </div>
    );
}
export default DirectoryResultsDisplay
