import {useEffect, useState} from "react";
import Heading from "../Heading";
import MySpinner from "../MySpinner";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../../firebase/firebase";
import {useNavigate, useParams} from "react-router-dom";
import {doGet} from "../../services/rest";

function Documents() {
    let { id } = useParams();
    const [user, loading] = useAuthState(auth);
    const [isLoading, setIsLoading] = useState(true)
    const [repositories, setRepositories] = useState([])
    const [documents, setDocuments] = useState()
    const navigate = useNavigate();


    useEffect(() => {
        if(loading) return;
        if(!user) navigate("/login")
    }, [user, loading, navigate]);

    useEffect(() => {
        if(user && !id){
            doGet("documents").then(res => {
                setRepositories(res)
                setIsLoading(false)
            })
        }
    }, [user, id]);

    useEffect(() => {
        if(user && id){
            doGet(`documents/${id}`).then(res => {
                setDocuments(res)
                setIsLoading(false)
            })
        }
    }, [user, id]);

    useEffect(() => {
        if(repositories.length === 1){
            navigate(`/documents/${repositories[0].id}`);
        }
    }, [repositories, navigate]);


    if(isLoading){
        return(<>
            <Heading title={'Documents'} />
            <MySpinner />
        </>)
    }

    return(<>
        <Heading title={'Documents'} />
        <div className={'inner-container'} style={{paddingTop: 50}}>
            {repositories.map(rep =>(
                <div onClick={() => navigate(`/documents/${rep.id}`)} style={{width: '100%', display: 'flex', flexDirection:'row', paddingBottom: 30, cursor: 'pointer'}}>{rep.repositoryName}</div>
            ))}
        </div>
    </>)

}
export default Documents;
