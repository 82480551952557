import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Heading from "../Heading";
import MySpinner from "../MySpinner";
import {doGet, doPostBody} from "../../services/rest";
import MyCheckBox from "../MyCheckBox";

function AppLocationsAdmin() {
    const {location} = useParams()
    const [loading, setLoading] = useState(false);
    const [appLocations, setAppLocations] = useState({});
    const [directoryLocations, setDirectoryLocations] = useState({});
    const [menuItems, setMenuItems] = useState([]);
    useEffect(() => {
        if(location){
            setLoading(true)
            doGet(`app-admin/load-location/${location}`).then(resp =>{
                setAppLocations(resp)
                setLoading(false)
            })
        }
    }, [location]);

    useEffect(() =>{
        doGet(`app-admin/directory-locations`).then(resp =>{
            setDirectoryLocations(resp)
        })
        doGet('app-admin/menu-items').then(resp => setMenuItems(resp))
    }, [])

    const onChange = event => {
        setAppLocations(prevState => ({
            ...prevState,
            [event.target.name]: event.target.value
        }));
    }

    const onMenuItemChange = menuItem => {
        setAppLocations(prevState => {
            const menuItems = prevState?.menuItems ?? [];

            const updatedMenuItems = menuItems.some(item => item.id === menuItem.id)
                ? menuItems.filter(item => item.id !== menuItem.id) // Remove the menu item
                : [...menuItems, menuItem]; // Add the menu item

            return {
                ...prevState,
                menuItems: updatedMenuItems,
            };
        });
    };

    const onLocationSecurityChange = uid => {
        setAppLocations(prevState => {
            // Safely get directoryLocations from prevState, defaulting to an empty array if it's undefined
            const directoryLocations = prevState?.directoryLocations ?? [];

            // Check if uid already exists
            const updatedLocations = directoryLocations.includes(uid)
                ? directoryLocations.filter(location => location !== uid) // Remove uid if it exists
                : [...directoryLocations, uid]; // Add uid if it doesn't exist

            return {
                ...prevState,
                directoryLocations: updatedLocations,
            };
        });
    };

    const itemChecked = (itemId) => {
        return appLocations?.menuItems?.some(item => item.id === itemId) ?? false
    }

    const saveGroup = () => {
        if(!appLocations.locationName){
            window.alert("You must specify a location name");
            return;
        }
        setLoading(true)
        doPostBody('app-admin/save-location', appLocations).then(resp =>{
            if(resp.id){
                window.alert("Your location has been saved")
                setAppLocations(resp)
                setLoading(false)
            }
        })
    }

    if(loading){
        return(<>
            <Heading title={'App Locations'} />
            <MySpinner />
        </>)
    }

    return(<>
        <Heading title={'App Locations'} />
        <div className={'inner-container'} style={{paddingTop: 50}}>
            <label style={{display: "flex", paddingBottom: 5, paddingLeft: 5}}>Location Name</label>
            <input type={'text'} name={'locationName'} onChange={onChange} className={'search-input'}
                   placeholder={'Enter Location Name'} value={appLocations.locationName}/>
            <label style={{display: "flex", paddingBottom: 5, paddingLeft: 5}}>Location Display Name</label>
            <input style={{marginTop: 10, marginBottom: 10}} type={'text'} name={'locationDisplayName'}
                   onChange={onChange} className={'search-input'}
                   placeholder={'Enter Location Display Name'} value={appLocations.locationDisplayName}/>
            <label style={{display: "flex", paddingBottom: 5, paddingLeft: 5}}>Location Email Address</label>
            <input style={{marginBottom: 10}} type={'text'} name={'emailAddress'}
                   onChange={onChange} className={'search-input'}
                   placeholder={'Enter Location Email Address Group'} value={appLocations.emailAddress}/>
            <label style={{display: "flex", paddingBottom: 5, paddingLeft: 5}}>Group Type</label>
            <select name={'appLocationType'} onChange={onChange} value={appLocations.appLocationType}
                    className={'search-input'}>
                <option value={'BOTH'}>Both</option>
                <option value={'SECURITY'}>Security Only</option>
                <option value={'MESSAGING'}>Messaging Only</option>
            </select>
            <div style={{display: 'flex'}}>
                <div style={{flex: 1}}>
                    <h3>Menu Items</h3>
                    {menuItems.map((item) =>(
                        <MyCheckBox onChange={() => onMenuItemChange(item)} key={item.id} label={item.title} id={item.id} checked={itemChecked(item.id)} />
                    ))}
                </div>
                <div style={{flex: 1}}>
                    <h3>Directory Locations</h3>
                    <h5>Resident</h5>
                    {directoryLocations.resident?.map(directoryLocation => (<div key={directoryLocation.uid}>
                        <MyCheckBox onChange={() => onLocationSecurityChange(directoryLocation.uid)} name={'resident'} value={directoryLocation.uid} label={directoryLocation.name} id={directoryLocation.uid} checked={appLocations?.directoryLocations?.includes(directoryLocation.uid)} />
                    </div>))}
                    <h5>Team Member</h5>
                    {directoryLocations.team?.map(directoryLocation => (<div key={directoryLocation.uid}>
                        <MyCheckBox onChange={() => onLocationSecurityChange(directoryLocation.uid)} name={'resident'} value={directoryLocation.uid} label={directoryLocation.name} id={directoryLocation.uid} checked={appLocations?.directoryLocations?.includes(directoryLocation.uid)} />
                    </div>))}
                    <h5>Locations</h5>
                    {directoryLocations.campus?.map(directoryLocation => (<div key={directoryLocation.uid}>
                        <MyCheckBox onChange={() => onLocationSecurityChange(directoryLocation.uid)} name={'resident'} value={directoryLocation.uid} label={directoryLocation.name} id={directoryLocation.uid} checked={appLocations?.directoryLocations?.includes(directoryLocation.uid)} />
                    </div>))}
                </div>
            </div>
            <button onClick={saveGroup} style={{margin: 20}} className={'submitPost'}>Save</button>
        </div>
    </>)
}

export default AppLocationsAdmin;
