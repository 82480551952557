import {useEffect, useState} from "react";
import {viewImage} from "../../services/rest";
import PopUp from "../PopUp";
import MySpinner from "../MySpinner";
import Lightbox from "yet-another-react-lightbox";

function MultiLightBox({images, close}) {
    const [loadedImages, setLoadedImages] = useState()

    useEffect(() =>{
        loadImages()
    }, [images])
    const loadImages = async () =>{
        const imgContainer = []
        for(const image in images){

            const respBlob = await viewImage('full', images[image]);
            const respUrl = URL.createObjectURL(respBlob);
            imgContainer.push({src: respUrl});
        }
       setLoadedImages(imgContainer)
    }

    if(!loadedImages){
        return (<PopUp
            content={<MySpinner />}
        />)
    }

    return (<Lightbox
        open={true}
        slides={loadedImages}
        close={() => close(true)}
    />)

}

export default MultiLightBox
