import {useEffect, useState} from "react";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../../firebase/firebase";
import {hasAdmin} from "../../services/menu";
import {doGet, doPostBody, downloadFileFromUrlGet} from "../../services/rest";
import {useNavigate, useParams} from "react-router-dom";
import {formatPrittyDate} from "../../services/dates";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faClose, faCopy, faEnvelope, faFileCsv} from "@fortawesome/free-solid-svg-icons";
import MySpinner from "../MySpinner";
import Heading from "../Heading";
import FileSaver from 'file-saver';
import PopUp from "../PopUp";
import SurveyEmail from "./SurveyEmail";
import {url} from "../../defaults/AppConfig";


function SurveyParticipants() {
    let { id } = useParams();
    const [user, loading] = useAuthState(auth);
    const [startedSurveys, setStartedSurveys] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const adminRolId = "ROLE_SURVEY_ADMIN"
    const navigate = useNavigate();
    const [searchResults, setSearchResults] = useState()
    const [showCSVOptions, setShowCSVOptions] = useState(false)
    const [includeNames, setIncludeNames] = useState(false)
    const [includeIncomplete, setIncludeIncomplete] = useState(false)
    const [showEmailSelection, setShowEmailSelection] = useState(false)
    const [emailReminder, setEmailReminder] = useState()

    useEffect(() => {
        if(emailReminder){
            setShowEmailSelection(true)
        }
    }, [emailReminder]);

    useEffect(() => {
        if(!showEmailSelection){
            setEmailReminder(undefined)
        }
    },[showEmailSelection])

    useEffect(() => {
        if(loading) return;
        if(!user) navigate("/login")
        if(!hasAdmin(adminRolId)) navigate("/surveys")
        doGet("surveys/create/current-surveys/"+id).then(res => {
            console.log(res)
            setStartedSurveys(res)
        })
    }, [user, loading, navigate]);

    const takeSurvey = (survey) =>{
        navigate("/surveys/take-survey/"+survey.gscUsers.id, {state: {survey: survey.survey, answers: survey }})
    }

    const startSurvey = async (gscUser) =>{
        setIsLoading(true)
        const survey = await doGet("surveys/load/"+id)
        navigate("/surveys/take-survey/"+gscUser.id, {state: {survey: survey}})
    }

    const search = async event =>{
        if(event.key === 'Enter'){
            if(event.target.value.length < 3){
                window.alert("Please enter more to search by.")
                return
            }
            const resp = await doGet("surveys/create/search-users?search="+event.target.value)
            let filteredUsers = resp.filter(user => {
                return !startedSurveys.some(response => response.gscUsers.id === user.id)
            })
            setSearchResults(filteredUsers)
            setIsLoading(false)
        }
    }

    if(isLoading){
        return (<>
            <Heading title={"Questionnaires"} />
            <MySpinner/>
        </>)
    }
    const copyUrl =(userId) =>{
        const toEncode = btoa(userId+":"+id)
        navigator.clipboard.writeText(url+"survey-link/"+toEncode).then(_ =>{
            alert("Url Copied to clipboard");
        })
    }

    const resetSurveyForUser = async (startedSurvey) =>{
        if(window.confirm("Are you sure you want to reset this survey?  This will allow the user to make changes to their answers")){
            setIsLoading(true)
            const resp = await doGet("surveys/create/reset/"+id+"/"+startedSurvey.id)
            setStartedSurveys(resp)
             setIsLoading(false)
        }
    }

    const downloadCSV = async () =>{
        setShowCSVOptions(false)
        setIsLoading(true)
        const resp = await downloadFileFromUrlGet('surveys/create/download-csv/'+id+`?incomplete=${includeIncomplete}&names=${includeNames}`)
        FileSaver.saveAs(new Blob([resp]), 'report.csv')
        setIsLoading(false)
    }

    const downloadCSVIcon = () => {
        return [<div style={{cursor: "pointer"}} onClick={() => setShowCSVOptions(true)}><FontAwesomeIcon icon={faFileCsv} /></div>,
            <div style={{cursor: "pointer", paddingLeft: 15}} onClick={() => setShowEmailSelection(true)}><FontAwesomeIcon icon={faEnvelope} /></div>
        ]
    }

    const sendEmailReminder = async (user) =>{
        setEmailReminder(user)
    }


    return (<>
        <Heading title={"Questionnaires"} icons={downloadCSVIcon()} />
        <div className={'survey-container'}>
            <label>Search For Participants</label>
            <input type={'text'} onKeyDown={search} />
            {searchResults !== undefined && <>
                <h3>Search Results</h3>
                <table style={{textAlign: 'left'}}>
                    <tr><th>Name</th><th>Location</th><th>Copy Link</th><th>Start</th></tr>
                    {searchResults.map(data => (<tr key={data.id}>
                        <td>{data.displayName}</td>
                        <td>{data.directoryType}</td>
                        <td><FontAwesomeIcon style={{cursor: 'pointer'}} onClick={() => copyUrl(data.id)} icon={faCopy} /></td>
                        <td><span style={{cursor: 'pointer'}} onClick={()=> startSurvey(data)}>Start</span></td>
                    </tr>))}
                </table>

            </>}
            {startedSurveys.length > 0 && <> <h3>Current Responses</h3>
                <table style={{textAlign: 'left'}}>
                    <tr><th>Name</th><th>Date Started</th><th>Completed?</th><th>Reset</th></tr>
                {startedSurveys.map(data => (<tr>
                    <td>{data.gscUsers.displayName}</td>
                    <td>{formatPrittyDate(data.startedDateTime)}</td>
                    <td><FontAwesomeIcon icon={data.completed ?? false ? faCheck : faClose} /><span style={{paddingLeft: '10px', cursor: 'pointer'}} onClick={() => takeSurvey(data)}>{data.completed ? 'View' : 'Continue'}</span>{!data.completed && <> -or- <FontAwesomeIcon onClick={() => sendEmailReminder(data.gscUsers)} style={{cursor: 'pointer'}} icon={faEnvelope} /></>}</td>
                    <td>{(data.completed ?? false) && <span style={{cursor: 'pointer'}} onClick={() => resetSurveyForUser(data)}>Reset</span>}</td>
                </tr>))}
                </table>
            </>}

        </div>
        {showCSVOptions && <PopUp
            content={<>
                <p>Do you want this to include the participants name?</p>
                <div className={'flex'}>
                    <div className={'survey-button-container'}>
                        <input id={'p_yes'} checked={includeNames} onClick={() => setIncludeNames(true)}
                               className={'survey-radio'} type={'radio'} value={'true'}/>
                        <label className={'radio-label'} htmlFor={'p_yes'}>Yes</label>
                    </div>
                    <div className={'survey-button-container'}>
                        <input id={'p_no'} checked={!includeNames} onClick={() => setIncludeNames(false)}
                               className={'survey-radio'} type={'radio'} value={'no'}/>
                        <label className={'radio-label'} htmlFor={'p_no'}>No</label>
                    </div>
                </div>
                <p>Do you want to include incomplete questionnaires?</p>
                <div className={'flex'}>
                    <div className={'survey-button-container'}>
                        <input id={'i_yes'} checked={includeIncomplete} onClick={() => setIncludeIncomplete(true)}
                               className={'survey-radio'} type={'radio'} value={'true'}/>
                        <label className={'radio-label'} htmlFor={'i_yes'}>Yes</label>
                    </div>
                    <div className={'survey-button-container'}>
                        <input id={'i_no'} checked={!includeIncomplete} onClick={() => setIncludeIncomplete(false)}
                               className={'survey-radio'} type={'radio'} value={'no'}/>
                        <label className={'radio-label'} htmlFor={'i_no'}>No</label>
                    </div>
                </div>
                <button onClick={downloadCSV} className={'submitPost'}>Generate CSV</button>
            </>}
            handleClose={() => setShowCSVOptions(false)}
        />}
        {showEmailSelection && <PopUp
            content={<SurveyEmail surveyId={id} close={() => setShowEmailSelection(false)} defaultUsers={emailReminder}/>}
            handleClose={() => setShowEmailSelection(false)}
        />}
    </>)
}

export default SurveyParticipants;
