import {useState} from "react";
import {doPost} from "../../services/rest";

function UserSelect({onUserSelect, userSelected, closePopUp}) {

    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState();

    const searchUsers = async () => {
        const formData = new FormData();
        formData.append("query", search)
        const results = await doPost('message-admin/user-search', formData)
        setUsers(results)
    }

    return (<>
        <div style={{display: "flex;"}}><input className="search-input" type="text" name="title"
                                               placeholder="Search for Users" value={search}
                                               onChange={e => setSearch(e.target.value)}/></div>
        <button onClick={searchUsers} className={'search-button'}>Search</button>
        {users.map(user => (
            <div className={"survey-button-container"}>
                <input className={'survey-radio'} type={'checkbox'} id={user.id} value={user.id} onChange={() => onUserSelect(user)} checked={userSelected(user.id)}/>
                <label className={'checkbox-label'} htmlFor={user.id}>{user.displayName} ({user.directoryType})</label>
            </div>
        ))}
        <button className={'search-button'} onClick={closePopUp}>Close</button>
    </>)
}

export default UserSelect
