import Heading from "../Heading";
import {useEffect, useState} from "react";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../../firebase/firebase";
import {useNavigate} from "react-router-dom";
import {hasAdmin} from "../../services/menu";
import MySpinner from "../MySpinner";
import {doGet} from "../../services/rest";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faEye, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";

function RepositoryAdmin() {
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true)
    const [repositories, setRepositories] = useState([])

    useEffect(() => {
        if(loading) return;
        if(!user) navigate("/login")
        if(!hasAdmin("ROLE_DOCUMENTS_REPOSITORIES")) navigate("/")
        doGet("documents").then(res => {
            setRepositories(res)
            setIsLoading(false)
        })
    }, [loading, user, navigate]);

    if (isLoading) {
        return (<>
            <Heading title={'Repository Admin'} />
            <MySpinner />
        </>);
    }

    const viewRepository = (repository) => {
        navigate(`/documents/${repository.id}`)
    }

    const deleteRep = (repository) =>{
        if(window.confirm("Are you sure you want to delete this?  It will delete the repository and all containing documents.")){
            setIsLoading(true)
            doGet(`documents/admin/repositories/delete/${repository.id}`).then(resp => {
                setRepositories(resp)
                setIsLoading(false)
            })
        }
    }



    return (<>
        <Heading title={'Repository Admin'}  icons={[<div onClick={() => navigate("/repository-admin/form")} style={{cursor: "pointer"}}><FontAwesomeIcon icon={faPlus} /></div>]}/>
        <h3>Repositories</h3>
        <div className={'inner-container'}>
        {repositories.map(rep => (
            <div style={{display: 'flex', flexDirection: 'row', paddingBottom: 20}}>
                <div style={{width: '100%', display: 'flex', justifyContent: 'flex-start'}}>{rep.repositoryName}</div>
                <div onClick={() => viewRepository(rep)} style={{paddingLeft: 30, cursor: "pointer"}}><FontAwesomeIcon icon={faEye}/></div>
                <div onClick={() => navigate("/repository-admin/form", {state : {repository: rep}})} style={{paddingLeft: 30, cursor: "pointer"}}><FontAwesomeIcon icon={faEdit}/></div>
                <div onClick={() => deleteRep(rep)} style={{paddingLeft: 30, cursor: "pointer"}}><FontAwesomeIcon icon={faTrash}/></div>
            </div>
        ))}
        </div>
    </>)
}

export default RepositoryAdmin
