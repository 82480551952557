import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {doGet, doPostBody, imageUploadHandler} from "../../services/rest";
import Heading from "../Heading";
import MySpinner from "../MySpinner";
import {Editor} from "@tinymce/tinymce-react";
import ToggleSwitch from "../inputs/ToggleSwitch";
import moment from "moment";

function PMForm(){
    const {id} = useParams()
    const [pm, setPM] = useState({});
    const [loading, setLoading] = useState(false);
    const [appLocations, setAppLocations] = useState([]);
    const [users, setUsers] = useState([])
    const [workOrders, setWorkOrders] = useState([])
    const navigate = useNavigate();
    useEffect(() => {
        if(id){
            setLoading(true);
            doGet(`it-pm/pm/${id}`).then(resp =>{
                setPM(resp)
                setLoading(false)
            })
            doGet(`it-pm/work-orders/${id}`).then(resp => {
                setWorkOrders(resp);
            });
        }else{
            setPM(prevState => ({
                ...prevState,
                maxNumOfOccurrences: 0,
                seperationCount: 1,
                appLocations: [],
                active: true
            }))
        }
    }, [id]);

    useEffect(() =>{
        doGet('it-pm/locations').then(resp =>{
            console.log(resp)
            setAppLocations(resp)
        })
        doGet('it-admin/it-users').then(data => {
            setUsers(data)
        })
    },[])


    useEffect(() => {
        console.log(pm)
    }, [pm]);

    if(loading){
        return (<>
            <Heading title={'IT PM'} />
            <MySpinner />
        </>)
    }


    const updateData = event =>{
        setPM(prevState => ({
            ...prevState,
            [event.target.name]: event.target.value
        }))
    }
    const onEditChange = event => {
        setPM(prevState => ({
            ...prevState,
            det: event
        }))
    }

    const checkBoxChecked = id => {
        return pm.appLocations.some(location => location.id === id)
    }

    const checkCheckBox = loc => {
        const locations = [...pm.appLocations]
        if(locations.some(location => location.id === loc.id)){
            setPM(prevState => ({
                ...prevState,
                appLocations: locations.filter(location => location.id !== loc.id),
                assignedUsers: undefined
            }))
        }else{
            locations.push(loc)
            setPM(prevState => ({
                ...prevState,
                appLocations: locations,
                assignedUsers: undefined
            }))
        }
    }

    const filteredUsers = users.filter(user =>
        pm.appLocations.every(selectedApp =>
            user.locationsSet.some(appLocation =>
                appLocation.id === selectedApp.id
            )
        )
    );

    const save = async() =>{
        if(isNaN(pm.recurringTypeId)){
            window.alert("Please select a recurrence type to continue.")
            return
        }
        if(!pm.seperationCount){
            window.alert("Please select an interval to continue.")
            return
        }
        if(isNaN(pm.maxNumOfOccurrences)){
            window.alert("Please select the max number of occurrences.")
            return
        }
        if(parseInt(pm.recurringTypeId) === 1 && !pm.dayOfWeek){
            window.alert("Please select the day of week to continue.")
            return
        }
        if((parseInt(pm.recurringTypeId) === 2 || parseInt(pm.recurringTypeId) === 3) && (!pm.dayOfMonth || parseInt(pm.dayOfMonth) < 1 || parseInt(pm.dayOfMonth) > 31)){
            window.alert("Please select a valid day of the month to continue")
            return
        }
        if(parseInt(pm.recurringTypeId) === 3 && !pm.monthOfYear){
            window.alert("Please select a month to continue.")
            return
        }
        if(!pm.appLocations || pm.appLocations.length === 0){
            window.alert("Please select at least one app location to continue")
            return
        }
        setLoading(true)
        const resp = await doPostBody('it-pm/save', pm)
        if(resp.id){
            navigate("/it/pm")
        }else{
            window.alert("Something went wrong")
            setLoading(false)
        }
    }

    const formatDate = (dateTime) => {
        const date = moment(dateTime)
        if(!date.isValid()){
            return ""
        }
        return  date.format("MM/DD/yyyy h:mm a");
    }
    const formatDateOnly = (dateTime) => {
        if(!dateTime){
            return ""
        }
        return  moment(dateTime).format("MM/DD/yyyy");
    }


    return (<>
        <Heading title={pm.id ? `PM id ${pm.id}` : 'Create New PM'} />
        <div className={'inner-container'} style={{paddingTop: 50}}>
            <div>
                Select recurrence type: <select onChange={updateData} name='recurringTypeId' className={'submitPost'}
                                                value={pm.recurringTypeId}>
                <option></option>
                <option value={0}>Daily</option>
                <option value={1}>Weekly</option>
                <option value={2}>Monthly</option>
                <option value={3}>Yearly</option>
            </select>
            </div>
            {parseInt(pm.recurringTypeId) === 0 && <>
                <div style={{paddingTop: 15}}>
                    Every <input type={'text'} style={{width: 50}} pattern={"[0-9]*"}
                                 className={'search-input-standard'}
                                 name={`seperationCount`} onChange={updateData} value={pm.seperationCount}/> Days.
                </div>
                <div style={{paddingTop: 15}}>
                    Maximum number of occurrences: <input type={'text'} style={{width: 50}} pattern={"[0-9]*"}
                                                          className={'search-input-standard'}
                                                          name={`maxNumOfOccurrences`}
                                                          onChange={updateData}
                                                          value={pm.maxNumOfOccurrences ?? 0}/> (Enter 0 for unlimited).
                </div>
            </>}
            {parseInt(pm.recurringTypeId) === 1 && <>
                <div style={{paddingTop: 15}}>
                    Every <input type={'text'} style={{width: 50}} pattern={"[0-9]*"}
                                 className={'search-input-standard'}
                                 name={`seperationCount`} onChange={updateData} value={pm.seperationCount}/> Weeks.
                </div>
                <div style={{paddingTop: 15}}>
                    Select Day Of Week: <select onChange={updateData} name={'dayOfWeek'} className={'submitPost'}
                                                value={pm.dayOfWeek}>
                    <option></option>
                    <option value="0">Sunday</option>
                    <option value="1">Monday</option>
                    <option value="2">Tuesday</option>
                    <option value="3">Wednesday</option>
                    <option value="4">Thursday</option>
                    <option value="5">Friday</option>
                    <option value="6">Saturday</option>
                </select>
                </div>
                <div style={{paddingTop: 15}}>
                    Maximum number of occurrences: <input type={'text'} style={{width: 50}} pattern={"[0-9]*"}
                                                          className={'search-input-standard'}
                                                          name={`maxNumOfOccurrences`}
                                                          onChange={updateData}
                                                          value={pm.maxNumOfOccurrences ?? 0}/> (Enter 0 for unlimited).
                </div>
            </>}
            {parseInt(pm.recurringTypeId) === 2 && <>
                <div style={{paddingTop: 15}}>
                    Every <input type={'text'} style={{width: 50}} pattern={"[0-9]*"}
                                 className={'search-input-standard'}
                                 name={`seperationCount`} onChange={updateData} value={pm.seperationCount}/> Months.
                </div>
                <div style={{paddingTop: 15}}>
                    Maximum number of occurrences: <input type={'text'} style={{width: 50}} pattern={"[0-9]*"}
                                                          className={'search-input-standard'}
                                                          name={`maxNumOfOccurrences`}
                                                          onChange={updateData}
                                                          value={pm.maxNumOfOccurrences ?? 0}/> (Enter 0 for unlimited).
                </div>
                <div style={{paddingTop: 15}}>
                    on the <input type={'text'} style={{width: 50}} pattern={"[0-9]*"}
                                  className={'search-input-standard'}
                                  name={`dayOfMonth`} onChange={updateData} value={pm.dayOfMonth}/> day of the month.
                </div>
            </>}
            {parseInt(pm.recurringTypeId) === 3 && <>
                <div style={{paddingTop: 15}}>
                    Every <input type={'text'} style={{width: 50}} pattern={"[0-9]*"}
                                 className={'search-input-standard'}
                                 name={`seperationCount`} onChange={updateData} value={pm.seperationCount}/> Years.
                </div>
                <div style={{paddingTop: 15}}>
                    Maximum number of occurrences: <input type={'text'} style={{width: 50}} pattern={"[0-9]*"}
                                                          className={'search-input-standard'}
                                                          name={`maxNumOfOccurrences`}
                                                          onChange={updateData}
                                                          value={pm.maxNumOfOccurrences ?? 0}/> (Enter 0 for unlimited).
                </div>
                <div style={{paddingTop: 15}}>
                    During the month of <select onChange={updateData} name={'monthOfYear'} className={'submitPost'}
                                                value={pm.monthOfYear}>
                    <option></option>
                    <option value="1">January</option>
                    <option value="2">February</option>
                    <option value="3">March</option>
                    <option value="4">April</option>
                    <option value="5">May</option>
                    <option value="6">June</option>
                    <option value="7">July</option>
                    <option value="8">August</option>
                    <option value="9">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                </select>
                    on the <input type={'text'} style={{width: 50}} pattern={"[0-9]*"}
                                  className={'search-input-standard'}
                                  name={`dayOfMonth`} onChange={updateData} value={pm.dayOfMonth}/> day of the month.
                </div>
            </>}
            <div style={{paddingTop: 15, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                Status: <ToggleSwitch isToggled={pm.active} toggle={() => setPM(prevState => ({...prevState, active: !prevState.active}))} />
            </div>
            <div style={{paddingTop: 15}}>
                <input type={'text'} className={'search-input'} placeholder={'Enter Subject'} onChange={updateData}
                       value={pm.des} name={'des'}/>
            </div>
            <div style={{paddingTop: 15}}>
                <Editor
                    onEditorChange={(e) => onEditChange(e)}
                    licenseKey={'gpl'}
                    value={pm.det}
                    init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount', 'table'
                        ],
                        toolbar: 'undo redo | blocks | link table|' +
                            'bold italic forecolor | alignleft aligncenter ' +
                            'alignright alignjustify | image | bullist numlist outdent indent | ' +
                            'removeformat | toggle',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                        automatic_uploads: true,
                        images_upload_handler: imageUploadHandler,
                    }}
                    tinymceScriptSrc={'/tinymce/tinymce.min.js'}
                />
            </div>
            <div style={{paddingTop: 15, display: "flex", justifyContent: 'space-between'}}>
                <div>Select Locations:
                    {appLocations.map(loc => (
                        <div className={'survey-button-container'} key={loc.id}>
                            <input checked={checkBoxChecked(loc.id)} id={loc.id} className={'survey-radio'} type="checkbox" name={'loc'} onClick={() => checkCheckBox(loc)}/>
                            <label htmlFor={loc.id} className={'checkbox-label'}>{loc.locationName}</label>
                        </div>
                    ))}
                </div>
                <div>
                    Assign to User:
                    <select className={'submitPost'} value={pm.assignedUsers?.id} onChange={(e) => {
                        const selectedUser = users.find(user => user.id === e.target.value);
                        setPM(prevPM => ({ ...prevPM, assignedUsers: selectedUser }));
                    }}>
                        <option></option>
                        {filteredUsers.map(user => (
                            <option key={user.id} value={user.id}>{user.displayName}</option>
                        ))}
                    </select>
                </div>
            </div>
            <div style={{paddingTop: 15, paddingBottom: 15}}>
                <button onClick={save} className={'submitPost'}>Save</button>
            </div>
            {workOrders && workOrders.length > 0 && <>
                <table className={'workorder'} style={{width: '100%', fontSize: 12, paddingTop: 15, paddingBottom: 15}}>
                    <tr>
                        <th>Submitted Date</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Assigned To</th>
                        <th>Due Date</th>
                        <th>Closed By</th>
                    </tr>
                    {workOrders
                        .map(order => (
                            <tbody key={order.id}
                                   onClick={() => navigate(`/workorders/edit/${order.id}`, {state: {locations: appLocations}})}>
                            <tr>
                                <td>{formatDate(order.closedDate)}</td>
                                <td>{order?.name ?? ''}</td>
                                <td>{order.subject}</td>
                                <td>{order?.assignedTo?.displayName ?? ""}</td>
                                <td>{formatDateOnly(order?.dueDate)}</td>
                                <td>{order?.closedBy?.displayName}</td>
                            </tr>
                            </tbody>
                        ))}
                </table>
            </>}
        </div>
    </>)
}

export default PMForm
