import { useNavigate } from "react-router-dom";
import {useEffect} from "react";

function Redirect() {
    const navigate = useNavigate(); // Correctly use the navigation function
    const userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;

    useEffect(()=>{
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            console.log("iOS");
            window.location.href = "https://apps.apple.com/us/app/garden-spot-communities/id1254108282"; // Redirect to App Store
        }
        else if (/android/i.test(userAgent)) {
            console.log("Android");
            window.location.href = "https://play.google.com/store/apps/details?id=org.gardenspotvillage.gsc&hl=en_US&gl=US"; // Redirect to Play Store
        }
        else {
            console.log("Web");
            navigate("/"); // Use React Router navigation for web
        }
    },[navigate, userAgent])
}

export default Redirect;
