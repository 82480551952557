import {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown, faArrowUp, faWarning} from "@fortawesome/free-solid-svg-icons";

function ColapsableFormSection({item, answers, onUpdateData, index}) {

    const [visable, setVisable] = useState(false);

    const [currentAnswers, setCurrentAnswers] = useState({});

    useEffect(() =>{
        if(index === 0){
            setVisable(true);
        }
    },[])

    useEffect(() => {
        if(answers){
            setCurrentAnswers(answers);
        }
    }, [answers]);

    const ranges = (item) =>{
        if(item.lowRange){
            return `${item.lowRange} - ${item.highRange}`
        }
        if(item.dataType === 'SELECTION'){
            let string = '';
            for(const equip of item.equipmentSelections){
                string += equip.selections+' or ';
            }
            return string.slice(0, -3)
        }
    }


    const showIcon = (item, value)=>{
        if(value){
            switch (item.dataType){
                case "TEMP":
                case "PERCENT":
                case "PSI":
                    try{
                        const currentTmpe = parseInt(value)
                        if(currentTmpe < item.lowRange){
                            return <FontAwesomeIcon icon={faWarning} color={'blue'} />
                        }
                        if(currentTmpe > item.highRange){
                            return <FontAwesomeIcon icon={faWarning} color={'red'} />
                        }
                    }catch(e){
                        return <FontAwesomeIcon icon={faWarning}/>
                    }
                    break;
                case "SELECTION":
                    let isOk = false
                    for(const selection of item.equipmentSelections){
                        if(selection.selections.toString().toLowerCase().trim() === value.toString().toLowerCase().trim()){
                            isOk = true;
                            break;
                        }
                    }
                    if(!isOk){
                        return <FontAwesomeIcon icon={faWarning} />
                    }
                    break;
                default:
                    return null;
            }
        }
    }

    const onChage = event =>{
        const currentChagne = currentAnswers;
        currentChagne[event.target.name] = event.target.value;
        onUpdateData(currentChagne)
    }

    return(<div style={{border: '1px solid #D3D3D3', padding: 10, margin: 10, borderRadius: 10}}>
        <div onClick={() => setVisable(!visable)} style={{display: 'flex', justifyContent: 'space-between', cursor: "pointer"}}>
            {item.name}<FontAwesomeIcon icon={visable ? faArrowUp : faArrowDown} />
        </div>
        {visable && <div style={{paddingTop: 10}}>
            <div style={style.heading}>
                <div style={style.headingTitle}>Name</div>
                <div style={style.headingTitle}>Range</div>
                <div style={style.headingTitle}>Value</div>
            </div>
            {item.equipmentItemsList.map((item, index) => (
                <div key={index} style={style.heading}>
                    <div style={style.otherLablesLeft}>{item.title}</div>
                    <div style={style.otherLables}>{ranges(item)}</div>
                    <div style={style.otherLables}><input type={'text'} className={'input-container'} name={item.dataId} value={currentAnswers[item.dataId]} onChange={onChage} /> {showIcon(item, currentAnswers[item.dataId])} </div>
                </div>
            ))}
        </div>}
    </div>)

}

const style = {
    heading:{
        display: "flex",
        borderBottom: "1px solid #D3D3D3",
        padding: 5,
    },
    headingTitle:{
        flex: 1,
        fontStyle: "italic",
    },
    otherLables:{
        flex: 1,
    },
    otherLablesLeft:{
        flex: 1,
        textAlign: "left",
    }
}
export default ColapsableFormSection;
