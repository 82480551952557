import {hasAdmin} from "../../services/menu";
import Heading from "../Heading";
import MyLabel from "../inputs/MyLabel";
import React, {useState} from "react";
import {doPost} from "../../services/rest";
import TeamMemberImages from "../directory/TeamMemberImages";
import ResidentImages from "../directory/ResidentImages";
import {formatFirstNameLastName} from "../directory/NameFormatters";
import {useNavigate} from "react-router-dom";

function Vehicles(){
    const employeeVehicles = hasAdmin("ROLE_EMPLOYEE_VEHICLE")
    const residentVehicles = hasAdmin("ROLE_RESIDENT_VEHICLE")
    const [searchResults, setSearchResults] = useState({})
    const [searchBy, setSearchBy] = useState('all')
    const navigate = useNavigate();

    const search = async event => {
        if(event.key === 'Enter'){
            if(event.target.value.length < 3){
                window.alert("Please enter more to search by.")
                return
            }
            const formData = new FormData();
            formData.set('search', event.target.value);
            const resp = await doPost('vehicles-admin/search', formData)
            setSearchResults(resp)
        }
    }

    return(<>
        <Heading title={"Vehicles"} />
        <div className={'inner-container'} style={{paddingTop: 50}}>
            <input className="search-input" type="text" placeholder="Search..." onKeyDown={search}/>
            {employeeVehicles && residentVehicles && <div style={{display: "flex", justifyContent: "space-around"}}><MyLabel name={'All'} id={'all'} checked={searchBy === 'all'} onChange={() => setSearchBy('all')}/> <MyLabel name={'Residents'} id={'residents'} checked={searchBy === 'resident'} onChange={() => setSearchBy('resident')}/> <MyLabel name={'Team Members'} id={'team'} checked={searchBy === 'team'} onChange={() => setSearchBy('team')}/></div>}
            {employeeVehicles && searchResults.employeeVehicles && (searchBy === 'all' || searchBy === 'team') && <div>
                <h2>Employee Results</h2>
                <div className={'flex'}>
                    {searchResults.employeeVehicles.map(item => (
                        <div key={item.id} style={{width: 300, cursor: 'pointer'}} onClick={()=>navigate(`/vehicles/team-member/${item.uid}`)}>
                            <TeamMemberImages teamMember={item} thumb={true}/>
                            <h3>{item.fname} {item.lname}</h3>
                            <p>{item?.teamDepartments?.name ?? ""}</p>
                            <p>{item.position}</p>
                        </div>
                    ))}
                </div>
            </div>}
            {residentVehicles && searchResults.residentsNewWithVehicles && (searchBy === 'all' || searchBy === 'resident') && <div>
                <h2>Resident Results</h2>
                <div className={'flex'}>
                    {searchResults.residentsNewWithVehicles.map(item => (
                    <div kind={item.id} style={{width: 300, cursor: 'pointer'}} key={item.id}  onClick={()=>navigate(`/vehicles/resident/${item.uid}`)}>
                        <ResidentImages resident={item} thumb={true}/>
                        <h3>{formatFirstNameLastName(item.residentsNew)}</h3>
                        <p>{item.homeNumber} {item.address}</p>
                        <p>{item?.neighborhood?.neighborHoodCommunityName ?? ''}</p>
                    </div>
                    ))}
                </div>
            </div>}
        </div>
    </>)
}

export default Vehicles
