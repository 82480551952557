import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../../firebase/firebase";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {hasAdmin} from "../../services/menu";
import MySpinner from "../MySpinner";
import {doGet} from "../../services/rest";
import Heading from "../Heading";

function ViewSurveys() {
    const [user, loading] = useAuthState(auth);
    const [loadingDisplay, setLoadingDisplay] = useState(true)
    const [surveys, setSurveys] = useState([])
    const navigate = useNavigate();
    const adminRolId = "ROLE_SURVEY_ADMIN"

    useEffect(() => {
        if(loading) return;
        if(!user) navigate("/login")
        if(!hasAdmin(adminRolId)) navigate("/surveys")
        loadSurveys().then((surveys) =>{
            setSurveys(surveys)
            setLoadingDisplay(false)
        })

    }, [user, loading, navigate]);

    const loadSurveys = async () =>{
        return await doGet("surveys/create/list-surveys")
    }

    if(loadingDisplay){
        return (<>
            <Heading title={"Questionnairea"} />
            <MySpinner />
        </>)
    }

    return(<>
        <Heading title={"Questionnaires"} />
        <div className={'main-container'}>
            <ul className="fancyList">
                {surveys.map((survey)=>(
                    <li>
                        <h2 style={{cursor: 'pointer'}} onClick={() => navigate("/surveys/edit/" + survey.id)}>{survey.surveyName}</h2>
                        <p>{survey.surveyDescription}</p>
                        <div style={{cursor: 'pointer'}} className={'flex-space-between'} onClick={()=> navigate("/surveys/participants/"+survey.id)}>Questionnaire Recipients</div>
                    </li>
                ))}
            </ul>
        </div>
    </>)
}

export default ViewSurveys
