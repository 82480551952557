import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../../firebase/firebase";
import {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import Heading from "../Heading";
import MySpinner from "../MySpinner";
import {doGet, doPostBody, downloadFileFromUrlPost} from "../../services/rest";
import Select from 'react-select';
import DirectoryResultsDisplay from "./DirectoryResultsDisplay";
import PopUp from "../PopUp";
import ViewResident from "./ViewResident";
import ViewEmployee from "./ViewEmployee";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose, faFilePdf} from "@fortawesome/free-solid-svg-icons";
import {defaultColor} from "../../defaults/AppConfig";
import FileSaver from 'file-saver';

function Directory(){
    const [user, loading] = useAuthState(auth);
    const [search, setSearch] = useState('')
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [searchBy, setSearchBy] = useState("0")
    const [selections, setSelections] = useState([])
    const [searchLocations, setSearchLocations] = useState([])
    const [subLocations, setSubLocations] = useState([])
    const [selectedSubLocations, setSelectedSubLocations] = useState([])
    const [directoryResults, setDirectoryResults] = useState()
    const [campusNumbers, setCampusNumbers] = useState([])
    const [bottomBarNumbers, setBottomBarNumbers] = useState([])
    const [displayPopUp, setDisplayPopup] = useState(false)
    const [popUpType, setPopUpType] = useState()
    const [popUpData, setPopUpData] = useState()
    const [showPdfOptions, setShowPdfOptions] = useState(false)
    const [selectedItes, setSelectedItems] = useState([]);
    const [displayClear, setDisplayClear] = useState(false)

    useEffect(() =>{
        if(loading) return;
        if(!user) navigate("/login")
        doGet("directory/search-locations").then(items => {
            let itemsList= [];
            for (let item in items.team) {
                itemsList.push({label: items.team[item], value: items.team[item] + '-t'});
            }
            let itemsListResident = [];
            for (let item in items.resident) {
                itemsListResident.push({
                    label: items.resident[item],
                    value: items.resident[item] + '-r',
                });
            }
            setSelections([
                {
                    label: 'Team Members',
                    options: itemsList,
                },
                {
                    label: 'Resident',
                    options: itemsListResident,
                },
            ]);
            setIsLoading(false)
        })
        doGet("directory/").then(loc =>{
            console.log(loc)
            setCampusNumbers(loc.filter(item => !item.bottomBar));
            setBottomBarNumbers(loc.filter(item => item.bottomBar));
        })
    },[user, loading, navigate])

    useEffect(() =>{
        if(searchLocations.length === 0){
            setSubLocations([])
        }
    },[searchLocations])

    useEffect(() => {
        if(directoryResults){
            setDisplayClear(true)
        }else{
            setDisplayClear(false)
        }
    }, [directoryResults]);

    const pdfDirectory = () =>{
        if(displayClear){
            return ([
                <div style={{cursor: "pointer"}} onClick={() => setShowPdfOptions(true)}>
                    <FontAwesomeIcon icon={faFilePdf}/>
                </div>,
                <div style={{cursor: "pointer", paddingLeft: 10}} onClick={() => setDirectoryResults(undefined)}>
                    <FontAwesomeIcon icon={faClose} />
                </div>
            ])
        }
        return ([<div style={{cursor: "pointer"}} onClick={() => setShowPdfOptions(true)}>
            <FontAwesomeIcon icon={faFilePdf} />
        </div>])
    }

    const loadLocations = async () =>{
        if(searchLocations.length > 0){
            const search = []
            for(const i in searchLocations){
                search.push(searchLocations[i].value)
            }
            const resp = await doPostBody('directory/get-sub-locations', search)

            const transformedData = resp.map(dataElem =>
                ({
                    label: dataElem.name,
                    options: dataElem.children.map(child => (
                        {
                            value: child.id,
                            label: child.name
                        }
                    ))
                })
            );
            setSubLocations(transformedData)
            setIsLoading(false)
        }else{
            setSubLocations([])
        }
    }

    const downloadPdf = async (location) => {
        if (location !== 'Campus') {
            if (
                location === 'Team Members' &&
                !selectedItes.some(item => item.endsWith('-t'))
            ) {
                window.alert(
                    'Please select at least one item from Team Members.',
                );
                return;
            } else if (
                location === 'Resident' &&
                !selectedItes.some(item => item.endsWith('-r'))
            ) {
                window.alert(
                    'Please select at least one item from Resident Locations.',
                );
                return;
            }
        }
        setIsLoading(true);
        downloadFileFromUrlPost({url: 'directory/pdf/'+location, data: selectedItes}).then(blob =>{
            FileSaver.saveAs(new Blob([blob]), location+".pdf")
            }
        )
        setIsLoading(false)

    };
    const customStyles = {
        control: provided => ({
            ...provided,
            width: '100%',
            padding: '5px',
            fontSize: '18px',
            borderRadius: '25px',
            borderColor: '#ccc',
            boxShadow: 'none', // Removes the default react-select styles
            '&:hover': { borderColor: '#ccc' } // Applies border style on hover
        }),
        input: provided => ({
            ...provided,
            color: '#000' // Changes the text color
        }),
        singleValue: provided => ({
            ...provided,
            color: '#000' // Changes the text color
        })
    };
    const addSelectedItems = (location) => {
        const selected = [...selectedItes] ?? [];
        const index = selected.findIndex(item => item === location);
        if (index > -1) {
            selected.splice(index, 1);
        } else {
            selected.push(location);
        }
        setSelectedItems(selected);
    };
    const showChecked = (item)  => {
        return selectedItes.includes(item);
    };

    const doSearch = async () =>{
        setIsLoading(true)
        const searchData = {search: search, searchType: searchBy}
        if(searchLocations.length > 0){
            const items = [];
            for(const i in searchLocations){
                items.push(searchLocations[i].value)
            }
            const employee = [];
            const resident = [];
            for (let i = 0; items.length > i; i++) {
                const myString = items[i];
                if (myString.endsWith('-t')) {
                    employee.push(myString.substring(0, myString.length - 2));
                } else {
                    resident.push(myString.substring(0, myString.length - 2));
                }
            }
            if (employee.length > 0) {
                searchData.employeeLocations = employee;
            }
            if (resident.length > 0) {
                searchData.residentLocations = resident;
            }
            if (selectedSubLocations.length > 0) {
                const newList = [];
                for(let i in selectedSubLocations){
                    newList.push(selectedSubLocations[i].value)
                }
                searchData.subLocations = newList;
            }
        }
        const resp = await doPostBody(
            'directory/search',
            searchData,
        );
        setDirectoryResults(resp)
        setIsLoading(false)
    }


    if(isLoading){
        return (<>
            <Heading title={"Directory"} />
            <MySpinner />
        </>)
    }

    const onTextChange = event =>{
        setSearch(event.target.value)
    }

    const onReturn = event =>{
        if (event.key === 'Enter') {
            doSearch()
        }
    }
    const handleSelection = (data, title) =>{
        setPopUpType(title)
        setPopUpData(data)
        setDisplayPopup(true)
    }

    return (<><Heading title={"Directory"} icons={pdfDirectory()}/>
        <div className={'main-container'}>
            <div className="search-container">
                <input
                    className="search-input"
                    type="text"
                    placeholder="Type Here..."
                    value={search}
                    onChange={onTextChange}
                    onKeyDown={onReturn}
                />
            </div>
            <div className={'flex-space-between'} style={{paddingLeft: '20px', paddingRight: '20px'}}>
                <div>
                    <div className={'survey-button-container'}>
                        <input id={'all'} className={'survey-radio'} name={'searchBy'} type="radio" value={"ALL"} checked={searchBy === '0'} onClick={() => setSearchBy('0')}/>
                        <label htmlFor={'all'} className={'radio-label'}>All</label>
                    </div>
                </div>
                <div>
                    <div className={'survey-button-container'}>
                        <input  id={'resident'} className={'survey-radio'} name={'searchBy'} type="radio" value={"RESIDENT"} checked={searchBy === '1'} onClick={() => setSearchBy('1')}/>
                        <label htmlFor={'resident'} className={'radio-label'}>Resident</label>
                    </div>
                </div>
                <div>
                    <div className={'survey-button-container'}>
                        <input id={'team'} className={'survey-radio'} name={'searchBy'} type="radio" value={"TEAMEMMBER"}
                               checked={searchBy === '2'} onClick={() => setSearchBy('2')}/>
                        <label htmlFor={'team'} className={'radio-label'}>Team Member</label>
                    </div>
                </div>
                <div>
                    <div className={'survey-button-container'}>
                        <input id={'campus'} className={'survey-radio'} name={'searchBy'} type="radio" value={"CAMPUS"} checked={searchBy === '3'} onClick={() => setSearchBy('3')}/>
                        <label htmlFor={'campus'} className={'radio-label'}>Campus</label>
                    </div>
                </div>
            </div>
            <div style={{padding: '20px'}}>
                <Select
                    placeholder={'Select Search Locations'}
                    closeMenuOnSelect={false}
                    isMulti
                    onChange={setSearchLocations}
                    defaultValue={searchLocations}
                    options={selections}
                    onMenuClose={loadLocations}
                    styles={customStyles}
                />
            </div>
            {subLocations.length > 0 && <div style={{padding: '20px'}}>
                <Select
                    placeholder={'Select'}
                    closeMenuOnSelect={false}
                    isMulti
                    onChange={setSelectedSubLocations}
                    defaultValue={selectedSubLocations}
                    options={subLocations}
                    styles={customStyles}
                />
            </div>}
            <button className={'search-button'} onClick={doSearch}>Search</button>
            {directoryResults && <DirectoryResultsDisplay directoryResponse={directoryResults} displayType={searchBy} onClick={handleSelection} />}
            {!directoryResults && !isLoading &&(
                    <div className={'flex-space-between'} style={{paddingTop: 30}}>
                        {campusNumbers.map(numbers => (<a style={{color: 'inherit', textDecoration: 'none'}} href={`tel:${numbers.emergencyNumber}`}>
                        <div>{numbers.emergencyName}</div>
                        <div>{numbers.emergencyNumber}</div>
                    </a>))}
                </div>
            )}

        </div>
        {!directoryResults && !isLoading &&(
            <div className={'bottom-view'}>
                {bottomBarNumbers.map(numbers => (<a style={{color: '#fff', textDecoration: 'none'}} href={`tel:${numbers.emergencyNumber}`}>
                    <div>{numbers.emergencyName}</div>
                    <div>{numbers.emergencyNumber}</div>
                </a>))}
            </div>
        )}
        {displayPopUp && <PopUp
            content = {<>
            {popUpType === 'Residents' ? <ViewResident resident={popUpData} /> : <ViewEmployee employee={popUpData} /> }
            </>}
            handleClose={() => setDisplayPopup(false)}
        ></PopUp>}
        {showPdfOptions && <PopUp
            content = {<>
                {selections.map(loc => (
                    <div>
                        <div style={{
                            textAlign: 'center',
                            fontWeight: 'bold',
                            paddingBottom: 5,
                        }}>{loc.label}</div>
                        {loc.options.map(item => (
                            <div className={'survey-button-container'}>
                                <input className={'survey-radio'} type={'checkbox'} id={item.value}
                                       checked={showChecked(item.value)}
                                       onClick={() => addSelectedItems(item.value)}
                                />
                                <label className={'checkbox-label'} htmlFor={item.value}>{item.label}</label>
                            </div>
                        ))}
                        <button
                            onClick={() => downloadPdf(loc.label)}
                            style={{
                            background: defaultColor,
                            borderColor: defaultColor,
                            color: "#fff",
                            padding: 5,
                            borderRadius: 10
                        }}>Generate {loc.label} PDF
                        </button>
                    </div>
                ))}
                <div style={{paddingTop: 10}}>
                <button
                    onClick={() => downloadPdf('Campus')}
                    style={{
                    background: defaultColor,
                    borderColor: defaultColor,
                    color: "#fff",
                    padding: 5,
                    borderRadius: 10
                }}>Generate Campus PDF
                </button>
                </div>
            </>}
            handleClose={() => setShowPdfOptions(false)}
        />}
    </>)
}

export default Directory
