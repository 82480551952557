import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {auth} from "../../firebase/firebase";
import Heading from "../Heading";
import MySpinner from "../MySpinner";
import {doGet, doPost} from "../../services/rest";
import {defaultColor} from "../../defaults/AppConfig";
import {useStompClient, useSubscription} from "react-stomp-hooks";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCamera} from "@fortawesome/free-solid-svg-icons";
import PopUp from "../PopUp";


function Chatting() {
    const location = useLocation()
    const {conversation} = location?.state
    const navigate = useNavigate();
    const [message, setMessage] = useState([]);
    const [loading, setLoading] = useState(true);
    const messagesEndRef = useRef(null);
    const [newMessage, setNewMessage] = useState("");
    const stompClient = useStompClient();
    const fileInputRef = useRef(null);
    const [currentImages, setCurrentImages] = useState([]);
    const [displayImage, setDisplayImage] = useState();

    useSubscription(`/topic/group/${conversation.id}`, (message) => {
        const fixedMessage = JSON.parse(message.body)
        setMessage(prevMessage => {
            return [fixedMessage, ...prevMessage];
        });
    })
    useEffect(() => {
        if(conversation){
            if(!conversation.groupMembers.some(member => member.userId === auth.currentUser.email)){
                navigate("/chat-messaging")
            }else{
                doGet(`chat/messages/${conversation.id}`).then(response => {
                    setMessage(response)
                    setLoading(false)
                })
            }
        }else{
            navigate("/chat-messaging")
        }
    }, [conversation])
    const uploadFile = file =>{
        const formData = new FormData();
        formData.append("file", file.target.files[0])
        doPost('chat/upload-image', formData).then(data => {
            console.log(data)
            const images = {fullUrl: data.url, thumbnail: data.url}
            setCurrentImages(prevImages => ([...prevImages, images]))
        })
    }
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(scrollToBottom, [message]);

    const generateTitle = () => {
        if(conversation.groupTitle){
            return conversation.groupTitle;
        }
        const groupMember = conversation.groupMembers.filter(member => member.userId !== auth.currentUser.email)
        return groupMember[0]?.name;
    }

    if(loading){
        return(<>
            <Heading title={generateTitle()} />
            <MySpinner />
        </>)
    }

    const sendMessage = () => {
        if(newMessage){
            const newMessageMap = {
                senderId: auth.currentUser.email,
                senderName: auth.currentUser.displayName,
                message: newMessage,
            }
            if(currentImages){
                newMessageMap.chatImages = currentImages
            }
         /*   setMessage(prevMessage => {
                return [newMessageMap, ...prevMessage];
            });*/
            setNewMessage("")
            setCurrentImages([])
            if(stompClient){
                stompClient.publish({
                    destination: `/app/group/${conversation.id}`,
                    body: JSON.stringify(newMessageMap)
                })
            }
        }

    }

    const onKeyDown = event => {
        if(event.key === 'Enter'){
            sendMessage();
        }
    }
    const handleIconClick = () => {
        if (fileInputRef && fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const removeImage = index =>{
        if(window.confirm("Are you sure you want to remove this image?")){
            setCurrentImages(prevImages => prevImages.filter((img, i) => i !== index));
        }
    }

    const style = {
        mine: {
            display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', textAlign: 'right', padding: 15
        },
        other: {
            display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',textAlign: 'left', padding: 15
        },
        mineInner: {
            backgroundColor: 'gray',
            color: '#fff',
            display: 'inline-block',
            padding: 20,
            borderRadius: 20,
            width: 'fit-content'
        },
        innerOther:{
            backgroundColor: defaultColor,
            color: '#fff',
            display: 'inline-block',
            padding: 20,
            borderRadius: 20,
            width: 'fit-content'
        }
    }

    return (<>
        <Heading title={generateTitle()} />
        <div className="inner-container" style={{paddingTop: 50}}>
            <div style={{height: '80vh', overflow: 'auto'}}>
                {[...message].reverse().map(mess => (
                    <div key={mess.id} style={mess.senderId === auth.currentUser.email ? style.mine : style.other}>
                        <div style={{display: 'inline-block'}}>
                            <div style={mess.senderId === auth.currentUser.email ? style.mineInner : style.innerOther}>
                                <div style={{flexDirection: 'column', display: 'flex'}}>
                                {mess.chatImages && <div style={{display: 'inline-block'}}> {mess.chatImages.map((img, i) => (
                                    <div key={i} style={{height: '100px', width: '100px', cursor: 'pointer', padding: 5}} ><img onClick={() => setDisplayImage(img.fullUrl)} src={img.fullUrl} style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover'
                                    }}/></div>
                                ))}</div>}
                                {mess.message}
                                </div>
                            </div>
                            <div style={{fontSize: 12}}>
                                {mess.senderName}
                            </div>
                        </div>
                    </div>
                ))}

                <div ref={messagesEndRef}/>
            </div>
            <div style={{position: 'sticky', bottom: 0}}>
                {currentImages && <div style={{display: "flex"}}>
                    {currentImages.map((image, index) => (
                        <div onClick={() => removeImage(index)} key={index} style={{height: '100px', width: '100px', cursor: 'pointer'}} ><img src={image.fullUrl} style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover'
                        }}/> </div>
                    ))}
                </div>}
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <FontAwesomeIcon onClick={handleIconClick} style={{paddingRight: 10}} cursor={'pointer'} icon={faCamera}/>
                    <input
                        ref={fileInputRef}
                        type="file"
                        accept="image/*"
                        onChange={uploadFile}
                        style={{display: "none"}}
                    />
                    <input
                        onKeyDown={onKeyDown}
                        className={'search-input'}
                        type="text"
                        value={newMessage}
                        onChange={e => setNewMessage(e.target.value)}
                        placeholder="Type a message"
                    />
                    <button onClick={sendMessage} className={'submitPost'} style={{marginLeft: 10}}>Send</button>
                </div>
            </div>
        </div>
        {displayImage && <PopUp
            content={<div><img src={displayImage} /></div>}
                handleClose={() => setDisplayImage(undefined)}
            />}
    </>)
}

export default Chatting
