import { useEffect, useRef } from "react";
import { useStompClient } from "react-stomp-hooks";

const Subscriber = ({ listener, onMessage }) => {
    const stompClient = useStompClient();  // Provide your Stomp Configuration if required
    const subscription = useRef(null);

    useEffect(() => {
        if (stompClient?.connected) {
            subscription.current = stompClient.subscribe(
                "/topic/stream/" + listener,
                (message) => onMessage(message.body)
            );
        }
        return () => {
            if(subscription.current) {
                subscription.current.unsubscribe();
            }
        };
    }, [listener, stompClient]);

    return null;
};

export default Subscriber;


