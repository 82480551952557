//const serviceUrl: string = 'http://10.1.1.70:8080/';

const serviceUrl= process.env.REACT_APP_SERVICE_URL;
//const serviceUrl= 'http://localhost:8080/';
const defaultColor= '#e97200';

//const websocketUrl = 'ws://localhost:8080/ws'
const websocketUrl = process.env.REACT_APP_WEB_SOCKET

const url = process.env.REACT_APP_URL

export {serviceUrl, defaultColor, websocketUrl, url};
