import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose, faTrash} from "@fortawesome/free-solid-svg-icons";
import {doGet, doPostBody} from "../../services/rest";

function AddEmergencyPhone({appLocation, updatePage, currentUpdate}) {

    const[numbers, setNumbers] = useState({ phoneNumbers: [] });
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if(currentUpdate){
            setNumbers(currentUpdate)
        }
    }, [currentUpdate]);

    const updateNumbers = event =>{
        setNumbers(prevNumbers => ({...prevNumbers, [event.target.title]: event.target.value}));
    }

    const addNumber = () => {
        setNumbers(prevNumbers => ({...prevNumbers, phoneNumbers: [...prevNumbers?.phoneNumbers, {label: 'Work'}]}));
    }

    const deleteNumber = (number, index) =>{
            setNumbers(prevNumbers => {
                // Create a clone of the existing phoneNumbers
                let phoneNumbersClone = [...prevNumbers.phoneNumbers];
                // Remove number using the index on the cloned phoneNumbers
                phoneNumbersClone.splice(index, 1);
                return { ...prevNumbers, phoneNumbers: phoneNumbersClone };
            });
    }
    const updatePhoneNumber = (index, newPhoneNumber) => {
        setNumbers(prevNumbers => {
            const phoneNumbers = prevNumbers.phoneNumbers.map((phoneNumber, i) =>
                i === index ? { ...phoneNumber, number: newPhoneNumber } : phoneNumber
            );
            return { ...prevNumbers, phoneNumbers };
        });
    }

    const updateSelect = (index, select) =>{
        setNumbers(prevNumbers => {
            const phoneNumbers = prevNumbers.phoneNumbers.map((phoneNumber, i) =>
                i === index ? { ...phoneNumber, label: select } : phoneNumber
            );
            return { ...prevNumbers, phoneNumbers };
        });
    }

    const save = async () =>{
        if(!numbers.name){
            alert("Name is required!")
            return;
        }
        if(numbers.phoneNumbers.length === 0){
            alert("At least one phone number must be added.")
            return;
        }
        setIsLoading(true)
        const data = {emergencyLocation: appLocation, emergencyNumbers: numbers}
        const resp = await doPostBody('emergency/admin/save', data)
        updatePage(resp)

    }

    const deleteUser = async () => {
        if(window.confirm("Are you sure?")){
            const resp = await doGet(`emergency/admin/delete/number/${numbers.id}`)
            updatePage(resp)
        }
    }

    return(<div className={'inner-container'}>
        <div style={{display: "flex"}}>{numbers.id && <FontAwesomeIcon onClick={deleteUser} style={{cursor: "pointer"}} icon={faTrash} />}</div>
        <h3>Add New Phone Number(s)</h3>
        <div className={'search-container'}>
            <input className={'search-input'} placeholder={'Enter Name'} value={numbers.name} title={'name'}
                   onChange={updateNumbers}/>
        </div>
        <div className={'search-container'}>
            <input className={'search-input'} placeholder={'Enter Label'} value={numbers.label} title={'label'}
                   onChange={updateNumbers}/>
        </div>
        <h5>Numbers</h5>
        {numbers.phoneNumbers?.map((phoneNumber, index) => (
            <div key={index} style={{display: 'flex', alignItems: 'center', paddingBottom: 5}}>
                <div style={{display: 'flex', flex: 1}}>
                    <select name={'label'} value={numbers.phoneNumbers[index].label}
                            onChange={event => updateSelect(index, event.target.value)}>
                        <option value={'Work'}>Work</option>
                        <option value={'Cell'}>Cell</option>
                        <option value={'Home'}>Home</option>
                    </select>
                </div>
                <div style={{display: 'flex', flex: 1}}>
                    <input className={'search-input'} placeholder={'Number'} value={numbers.phoneNumbers[index].number}
                           title={'number'} onChange={e => updatePhoneNumber(index, e.target.value)}/>
                </div>
                <FontAwesomeIcon style={{paddingLeft: 10, cursor: 'pointer'}} icon={faClose}
                                 onClick={() => deleteNumber(phoneNumber, index)}/>
            </div>
        ))}
        <br/>
        <div onClick={addNumber} style={{cursor: "pointer"}}>Add Number</div>
        <br/>
        {isLoading ? <div style={{
            border: '16px solid #f3f3f3',
            borderRadius: '50%',
            borderTop: '16px solid #e97200',
            width: '10px',
            height: '10px',
            marginLeft: 10,
            animation: 'spin 2s linear infinite'
        }}/> : <button onClick={save} className={'submitPost'}>Save</button>}
    </div>)
}

export default AddEmergencyPhone
