function ElopementNotification({notification}) {
    return (<>
        <h2>{notification.title}</h2>
        <img src={notification.image} alt={'notification image'}/>
        <div><p>{notification.message}</p></div>
        <div><p>Address: {notification.location}</p></div>
    </>)
}

export default ElopementNotification;
