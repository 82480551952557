import {hasAdmin} from "../../services/menu";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import Heading from "../Heading";
import MySpinner from "../MySpinner";
import {doGet, doPostBody} from "../../services/rest";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd, faTrash} from "@fortawesome/free-solid-svg-icons";
import ToggleSwitch from "../inputs/ToggleSwitch";
import moment from "moment";

function PMManagement() {
    const access = hasAdmin("ROLE_IT")
    const navigation = useNavigate()
    const [loading, setLoading] = useState(true);
    const [pms, setPMs] = useState([]);

    useEffect(() => {
        doGet('it-pm').then(resp =>{
            console.log(resp)
            setPMs(resp)
            setLoading(false)
        })
    }, []);

    if (!access) {
        navigation("/")
    }

    if(loading){
        return (<>
            <Heading title={'IT PM'} />
            <MySpinner />
        </>)
    }

    const toggle = async (pm, index) => {
        const resp = await doPostBody("it-pm/toggle", pm)
        setPMs(prevPMs => {
            const updatedPMs = [...prevPMs];
            updatedPMs[index] = resp;
            return updatedPMs;
        });
    }

    const deletePm = async (pm) => {
        if(window.confirm("Are you sure you want to delete this PM?")){
            setLoading(true)
            const resp = await doPostBody('it-pm/delete', pm)
            setPMs(resp)
            setLoading(false)
        }
    }


    return (<>
        <Heading title={'IT PM'} icons={[<FontAwesomeIcon style={{cursor: 'pointer'}} onClick={() => navigation("/it/pm/form")} icon={faAdd} />]} />
        <div className={'inner-container'} style={{paddingTop: 50}}>
            <table className={'workorder'} style={{width: '100%', fontSize: '12px'}}>
                <tr>
                    <th>Description</th>
                    <th>Next Occurrence</th>
                    <th>Active</th>
                    <th>Delete</th>
                </tr>
                {pms.map((pm, index) =>(
                    <tr key={pm.id}>
                        <td onClick={() => navigation(`/it/pm/form/${pm.id}`)}>{pm.des}</td>
                        <td>{pm.active ? moment(pm.nextDueDate).format("MM/DD/YYYY") : ''}</td>
                        <td><ToggleSwitch isToggled={pm.active} toggle={() => toggle(pm, index)}  /></td>
                        <td><FontAwesomeIcon onClick={() => deletePm(pm)} icon={faTrash} style={{fontSize: 18}}/></td>
                    </tr>
                ))}
            </table>
        </div>
    </>)


}
export default PMManagement
