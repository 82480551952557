
import FemalePlaceholder from "../../images/photo-placeholder-female-thumb.jpg";
import MalePlaceholder from "../../images/photo-placeholder-male-thumb.jpg";

function ResidentImageSingle({resident, thumb}) {
    let imgSrc = '';
    if (resident.photo) {
        let newPhoto = resident.photo;

        if (thumb) {
            let photoFiles = resident.photo.split('.');
            newPhoto = `${photoFiles[0]}-thumb.${photoFiles[1]}`;
        }
        imgSrc = `https://photos.gsv.gsrdp.org/${newPhoto}`;

    } else {
        imgSrc = resident.residentGender === 'female'
            ? FemalePlaceholder
            : MalePlaceholder;
    }

    return (
        <img
            style={thumb ? styles.image : styles.largeImage}
            src={imgSrc}
            alt="Resident"
        />
    );
}
const styles = {
    image: {
        width: 165,
    },
    largeImage: {
        objectFit: 'contain',
        width: '500px',
    },
};
export default ResidentImageSingle
