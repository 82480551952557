import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {doGet, doPostBody} from "../../services/rest";
import Heading from "../Heading";
import MySpinner from "../MySpinner";
import ColapsableFormSection from "./ColapsableFormSection";

function FacilityServicesForm() {
    const {id} = useParams();
    const [formData, setFormData] = useState({})
    const [formAnswer, setFormAnswer] = useState({})
    const [formSave, setFormSave] = useState({})
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if(id){
            doGet('facility-services/form/' + id).then(resp => {
                setFormSave(resp)
                setFormAnswer(JSON.parse(resp.reportData))
            })
        }
    }, [id]);

    useEffect(() => {
        doGet('facility-services/form-data').then(resp => {
            setFormData(resp)
            setLoading(false)
        })
    }, []);


    const saveChanges = async () => {
        formSave.reportData = JSON.stringify(formAnswer)
        setLoading(true)
        const resp = await doPostBody('facility-services/save/form', formSave)
        setFormSave(resp)
        setFormAnswer(JSON.parse(resp.reportData))
        setLoading(false)
        window.alert("Your form as been saved.")
    };


    if(loading){
        return(<>
            <Heading title={'Mechanical Form'} />
            <MySpinner />
        </>)
    }

    const updateData = (item, data) => {
        setFormAnswer(prev => ({
            ...prev,
            [item]: data
        }));
    };

    return(<>
        <Heading title={'Mechanical Form'} />
        <div className={'inner-container'} style={{paddingTop: 50}}>
            {formData.map((item, index) => (
                <ColapsableFormSection item={item} answers={formAnswer[item.name]} index={index} key={item.id} onUpdateData={data => updateData(item.name, data)}/>
            ))}
            <button onClick={saveChanges} className={'submitPost'}>Save</button>
        </div>
    </>)


}
export default FacilityServicesForm
