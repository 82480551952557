import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Heading from "../Heading";
import MySpinner from "../MySpinner";
import {doGet} from "../../services/rest";
import BenefitImage from "./BenefitImage";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChrome} from "@fortawesome/free-brands-svg-icons";
import {faEdit, faEnvelope, faFilePdf, faPhone} from "@fortawesome/free-solid-svg-icons";
import {defaultColor} from "../../defaults/AppConfig";
import {hasAdmin} from "../../services/menu";

function ViewBenefits() {
    const {id} = useParams()
    const [loading, setLoading] = useState(true)
    const [benefit, setBenefits] = useState({});
    const benefitAdmin = hasAdmin('ROLE_BENEFITS')
    const navigation = useNavigate()

    useEffect(() => {
        doGet(`benefits/benefit/${id}`).then(resp =>{
            console.log(resp);
            setBenefits(resp);
            setLoading(false)
        })
    }, [id]);

    if(loading){
        return (<>
            <Heading />
            <MySpinner />
        </>)
    }

    const getIcon = (type) =>{
        switch(type){
            case "website": return faChrome;
            case "phone": return faPhone;
            case "email": return faEnvelope;
            case "document": return faFilePdf;
            default: return faChrome;
        }
    }

    const onFeatureClick = (feature) =>{
        switch(feature.type){
            case "website":
                window.open(feature.data)
                break;
            case "phone":
                window.open("tel:"+feature.data)
                break;
            case "email":
                window.open("mailto:" + feature.data)
                break;
            case "document":
                if(feature.data.startsWith("http")){
                    window.open(feature.data)
                }else{
                    window.open(`https://gsc.app/admin/.uploads/${feature.data}`)
                }
                break;
        }
    }

    return (<>
        <Heading title={benefit.title} icons={benefitAdmin && [<FontAwesomeIcon onClick={() => navigation(`/benefits/edit/${benefit.id}`)} style={{cursor: "pointer"}} icon={faEdit} />]} />
        <div className={'inner-container'} style={{paddingTop: 50}}>
            {benefit.featureImage && <BenefitImage image={benefit.featureImage} style={{maxWidth: '100%'}}/>}
            {benefit.featureList &&
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: "space-around", paddingTop: 20}}>
                    {benefit.featureList.map(feature => (
                        <div style={{flex: 1}}>
                        <div style={{cursor: 'pointer'}} onClick={() => onFeatureClick(feature)} key={feature.id}>
                            <div><FontAwesomeIcon style={{color: defaultColor, fontSize: 30, paddingBottom: 10}}
                                                  icon={getIcon(feature.type)}/></div>
                            {feature.displayName}</div></div>))}
                </div>}
            <div style={{marginTop: 15}}>
             <div className={'reset'} dangerouslySetInnerHTML={{__html:benefit.body}}></div>
            </div>
        </div>
    </>)

}

export default ViewBenefits
