import {useEffect, useState} from "react";
import {doGet} from "../../services/rest";
import Heading from "../Heading";
import MySpinner from "../MySpinner";

function UserRequests() {
    const [userRequests, setUsersRequests] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        doGet('it-admin/user-requests').then((response) => {
            setUsersRequests(response);
            setLoading(false)
        })
    }, [])

    if(loading){
        return (<>
            <Heading title={'IT User Requests'} />
            <MySpinner />
        </>)
    }

    const getName = (name, item) => {
        console.log(item);
        try {
            // Use Object.values to iterate over the object values
            for (const obj of Object.values(item)) {
                if (obj.name === name) {
                    return obj.prettyFormat || obj.answer;
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    return <>
        <Heading title={'IT User Requests'} />
        <div className={'inner-container'} style={{paddingTop: 50}}>
            <h3>Open Requests</h3>
            <table  className={'workorder'} style={{width:'100%', fontSize: 12}}>
                <tr>
                    <th>Requested By</th>
                    <th>Requested For</th>
                    <th>Type</th>
                    <th>Due Date</th>
                    <th>Open</th>
                </tr>
                {userRequests?.content?.map(item => <tr key={item.id}>
                    <td>{getName("requesterName", item.answers)}</td>
                    <td>{getName("employeeName", item.answers)}</td>
                    <td>{getName("requestType", item.answers)}</td>
                    <td>{getName("dateNeeded", item.answers)}</td>
                    <td><a href={`https://gardenspotcommunities.jotform.com/edit/${item.id}`}>View/ Edit</a></td>
                </tr>)}
            </table>
        </div>
    </>
}

export default UserRequests;
