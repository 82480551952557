import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import {doGet, doPostBody, downloadFileFromUrlGet} from "../../services/rest";
import FileSaver from 'file-saver';

function ItBilling({myAppLocations}) {
    const [workOrders, setWorkOrders] = useState([]);
    const [marAsBilled, setMarAsBilled] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        doGet('it-admin/billing').then(billing => setWorkOrders(billing));
    }, []);

    const formatDate = (dateTime) => {
        return  moment(dateTime).format("MM/DD/yyyy h:mm a");
    }
    const formatDateOnly = (dateTime) => {
        if(!dateTime){
            return ""
        }
        return  moment(dateTime).format("MM/DD/yyyy");
    }
    const downloadBilling = async () =>{
        const resp = await downloadFileFromUrlGet('it-admin/billing/export')
        const cate = new Date();
        FileSaver.saveAs(new Blob([resp]), 'billing-'+cate.toString()+'.csv')
    }
    const toggleCheckBox = (id) => {
        if(marAsBilled.includes(id)){
            setMarAsBilled(marAsBilled.filter(item => item !== id));
        }else{
            setMarAsBilled(marAsBilled  => [...marAsBilled, id]);
        }
    }

    const markAsBilled = async () =>{
        if(marAsBilled.length === 0){
            alert("You must select at least one work order to mark as billed.")
            return
        }
        const resp = await doPostBody('it-admin/mark-billed', marAsBilled)
        setWorkOrders(resp)
    }

    return(<div className={'inner-container'}>
        <table className={'workorder'} style={{width: '100%'}}>
            <tr>
                <th>Mark Billed</th>
                <th>Closed Date</th>
                <th>Submitted By</th>
                <th>Description</th>
                <th>Assigned To</th>
                <th>Due Date</th>
            </tr>
            {workOrders.map(order => (
                    <tbody key={order.id}
                           onClick={(event) => {
                               // check if the element with class 'survey-radio' was clicked
                               if (event.target.classList.contains('survey-radio') || event.target.classList.contains('checkbox-label')) {
                                   // Checkbox was clicked, so we prevent any further action
                                   event.stopPropagation();
                                   return;
                               }
                               navigate(`/workorders/edit/${order.id}`, {state: {locations: myAppLocations}})
                           }}>
                    <tr>
                        <td>
                            <div className={'survey-button-container'}>
                            <input id={'checked_'+order.id} className={'survey-radio'} type={'checkbox'} checked={marAsBilled.includes(order.id)} onClick={() => toggleCheckBox(order.id)} />
                            <label htmlFor={'checked_'+order.id} className={'checkbox-label'}></label>
                            </div>
                        </td>
                        <td>{formatDate(order.closedDate)}</td>
                        <td>{order.submittedBy.displayName}</td>
                        <td>{order.subject}</td>
                        <td>{order?.assignedTo?.displayName ?? ""}</td>
                        <td>{formatDateOnly(order?.dueDate)}</td>
                    </tr>
                    {order.name && <tr>
                        <td></td>
                        <td colSpan={2} style={{textAlign: 'start'}}>Name: {order.name}</td>
                        <td colSpan={3} style={{textAlign: 'start'}}>Location: {order.address}</td>
                    </tr>}
                    <tr>
                        <td></td>
                        <td colSpan={5}>Billing amount: {order.amount}</td>
                    </tr>
                    </tbody>
                ))}
        </table>
        <div className={'flex-space-between'}>
            <button className={'submitPost'} onClick={markAsBilled}>Mark As Billed</button>
            <button className={'submitPost'} onClick={downloadBilling}>Download Billing CSV File</button>
        </div>
    </div>)
}

export default ItBilling
