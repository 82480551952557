import MaleThumbImage from './../../images/photo-placeholder-male-thumb.jpg';
import FemaleThumbImage from './../../images/photo-placeholder-female-thumb.jpg';

function TeamMemberImages({teamMember, thumb}) {
    let imgSrc = '';

    if (teamMember.photoFileName) {
        let newPhoto = teamMember.photoFileName;

        if (thumb) {
            let photoFiles = teamMember.photoFileName.split('.');
            newPhoto = photoFiles[0] + '-thumb.' + photoFiles[1];
        }

        imgSrc = `https://photos.team.gsc.gl/${teamMember.uid}/${newPhoto}`;
    } else {
        imgSrc = teamMember.gender === 'female'
            ? FemaleThumbImage
            : MaleThumbImage;
    }

    return (
        <img
            style={thumb ? styles.image : styles.largeImage}
            src={imgSrc}
            alt="team member' photo"
        />
    );
}
const styles = {
    image: {
        width: 165,
    },
    largeImage: {
        objectFit: 'contain',
        width: '500px',
    },
};
export default TeamMemberImages
