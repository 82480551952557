import React from 'react';

function TextInput({label, value, name, onChange, readOnly}) {
    return (
        <div style={{
            margin: '10px 0',
            alignItems: 'center'
        }}>
            <label style={{
                marginRight: '10px'
            }}>
                {label}
            </label>
            <input type="text" style={{
                padding: '5px',
                width: 250
            }}
                value={value}
                   name={name}
                   onChange={onChange}
                   readOnly={readOnly ?? false}
            />
        </div>
    );
}

export default TextInput;
