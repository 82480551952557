import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

function VideoViewer({video, admin, removeVideo}) {

    const getVideoType = () => {
        let regularExpression  = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
        if(regularExpression.test(video)){
            return "YOUTUBE"
        }
        return "VIMEO"
    }
    const getYoutubeId = () => {
        let regularExpression  = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
        let match = video.match(regularExpression)
        return match[6];
    }

    const getVimeoId = () => {
        let regularExpression = /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;
        let match = video.match(regularExpression);
        return match[4];
    }

    if(getVideoType() === 'YOUTUBE'){
        return <>
            {admin && <div className={'flex-space-between'}><div></div><FontAwesomeIcon style={{cursor: "pointer"}} onClick={() => removeVideo()} icon={faTrash} /> </div>}
            <div style={{position: 'relative', paddingBottom: '56.25%'}}>
            <iframe style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%'}} src={`https://www.youtube.com/embed/${getYoutubeId()}`} frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                    allowFullScreen>
            </iframe>
            </div>
        </>
    }
    return (<>
        {admin && <div className={'flex-space-between'}><div></div><FontAwesomeIcon style={{cursor: "pointer"}} onClick={() => removeVideo()} icon={faTrash} /> </div>}
        <div style={{position: 'relative', paddingBottom: '56.25%'}}>
            <iframe style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%'}}
                    src={`https://player.vimeo.com/video/${getVimeoId()}`} frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture" allowFullScreen>
            </iframe>
        </div>
    </>)

}

export default VideoViewer
