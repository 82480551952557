import React, {useEffect, useState} from "react";
import {hasAdmin} from "../../services/menu";
import Heading from "../Heading";
import MySpinner from "../MySpinner";
import {doGet, doPostBody, downloadFileFromUrlPost} from "../../services/rest";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd, faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import PopUp from "../PopUp";
import AddEmergencyPhone from "./AddEmergencyPhone";
import EmergencyDocumentsForm from "./EmergencyDocumentsForm";
import FileSaver from 'file-saver';
import EditHeading from "./EditHeading";
function EmergencyInformation() {
    const [loading, setLoading] = useState(true);
    const admin = hasAdmin("ROLE_EMERGENCY")
    const [emergencyInformation, setEmergencyInformation] = useState([]);
    const [appLocationtoEdit, setAppLocationtoEdit] = useState()
    const [displayUserInfo, setDisplayUserInfo] = useState()
    const [phonesUpdate, setPhonesUpdate] = useState()
    const [emergencyDocumentsLocation, setEmergencyDocumentsLocation] = useState()
    const [editHeading, setEditHeading] = useState()

    useEffect(() => {
        doGet('emergency').then(resp =>{
            console.log(resp)
            setEmergencyInformation(resp)
            setLoading(false)
        })
    }, []);

    if(loading){
        return(<>
            <Heading title={'Emergency Information'} />
            <MySpinner />
        </>)
    }
    const updatePage = data =>{
        setEmergencyInformation(data)
        setAppLocationtoEdit(undefined)
        setPhonesUpdate(undefined)
        setEmergencyDocumentsLocation(undefined)
        setEditHeading(undefined)
    }

    const updateNumber = (phones, location) =>{
        setPhonesUpdate(phones)
        setAppLocationtoEdit(location)
    }

    const handleClose = () => {
        setAppLocationtoEdit(undefined)
        setPhonesUpdate(undefined)
    }

    const openFile = (file) =>{
        downloadFileFromUrlPost({url: 'emergency/document/download', data: file}).then(blob => FileSaver.saveAs(new Blob([blob]), file.fileName))
    }

    const deleteDocument = async (document) =>{
        if(window.confirm("Are you sure you want to delete this document?")){
            const resp = await doPostBody('emergency/admin/document/delete', document);
            setEmergencyInformation(resp)
        }
    }

    return(<>
        <Heading title={'Emergency Information'} />
        <div className={'inner-container'}>
            {emergencyInformation.map((item, index) => (<div key={index}>
                <div style={{ paddingTop: 20, position: 'relative' }}>
                    <div style={{
                        position: 'absolute',
                        top: 10,
                        right: 0
                    }}>
                        {admin &&  <FontAwesomeIcon onClick={() => setEditHeading(item)} style={{cursor: "pointer"}} icon={faEdit} />}
                    </div>
                <div className={'reset'} dangerouslySetInnerHTML={{__html: item.headingHtml}}></div>
                </div>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><h3>All-Hazards Plan Contact List</h3> {admin && <FontAwesomeIcon onClick={() => setAppLocationtoEdit(item.id)} style={{cursor: 'pointer', paddingLeft: 10}} icon={faAdd} />}</div>
                {item.emergencyNumbers
                    .sort((a, b) => a.label.localeCompare(b.label)) // Sort by label
                    .map((phone, index2, array) => (

                        // Conditionally render the label only when it changes from the previous item
                        <React.Fragment key={index2}>
                            {(index2 === 0 || array[index2 - 1].label !== phone.label) && <div><h4>{phone.label}</h4></div>}

                            <div style={{display: 'flex', justifyContent: 'center'}}><div style={{cursor: 'pointer', paddingBottom: 10}} onClick={() => setDisplayUserInfo(phone)}>
                                {phone.name}
                            </div>{admin && <FontAwesomeIcon onClick={() => updateNumber(phone, item.id)} style={{paddingLeft: 5, cursor: 'pointer'}} icon={faEdit} />}</div>
                        </React.Fragment>
                    ))
                }
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><h3>Emergency
                    Documents</h3> {admin && <FontAwesomeIcon onClick={() => setEmergencyDocumentsLocation(item.id)} style={{cursor: 'pointer', paddingLeft: 10}} icon={faAdd}/>}
                </div>
                {item.emergencyDocuments.map((document, index2) => (
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div onClick={() => openFile(document)} key={index2} style={{paddingBottom: 10, cursor: 'pointer'}}>
                        {document.title}
                    </div>
                        {admin && <FontAwesomeIcon onClick={() => deleteDocument(document)} style={{paddingLeft: 5, cursor: 'pointer'}} icon={faTrash} />}
                    </div>
                ))}
            </div>))}
        </div>
        {editHeading && <PopUp
            content={<EditHeading heading={editHeading} onUpdate={data => updatePage(data)} />}
            handleClose={() => setEditHeading(undefined)}
        />}
        {appLocationtoEdit && <PopUp
            content={<AddEmergencyPhone appLocation={appLocationtoEdit} updatePage={data => updatePage(data)} currentUpdate={phonesUpdate}/>}
            handleClose={handleClose}
        />}
        {emergencyDocumentsLocation && <PopUp
            content={<EmergencyDocumentsForm documentRepository={emergencyDocumentsLocation} onCompleted={data => updatePage(data)}/>}
            handleClose={() => setEmergencyDocumentsLocation(undefined)}
        />}
        {displayUserInfo && <PopUp
            content={<>
                <h3>{displayUserInfo.name}</h3>
                <table>
                {displayUserInfo.phoneNumbers.map(phone => (
                    <tr key={phone.id}><td style={{textAlign: "left"}}><b>{phone.label}:</b></td> <td><a href={`tel:${phone.number}`}>{phone.number}</a></td></tr>
                ))}
                </table>
            </>}
            handleClose={() => setDisplayUserInfo(undefined)}
            />}
    </>)
}
export default EmergencyInformation
