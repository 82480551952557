import {formatPrittyDate} from "../../services/dates";
import MaleThumbImage from '../../images/photo-placeholder-male-thumb.jpg';
import {mainUrl} from "../../services/rest";

function HtmlStreamView({stream}) {
    return (
        <div className="post">
            <div className="post-header">
                <img src={stream.user ? mainUrl+'menu/image/'+stream.user.id : MaleThumbImage}  className={'profile-pic'}/>
                <p className={'post-title'}><i>Posted By {stream.user ? stream.user.displayName : stream.sentBy}  on {formatPrittyDate(stream.dateSent)}</i></p>
            </div>
            <h3>{stream.title}</h3>
            {!stream.reversed && stream.attachment && <img style={{maxWidth: '100%', height: "auto"}} src={`https://gsc.app/fb/.uploads/${stream.attachment}`} /> }
            <div className={'reset'} dangerouslySetInnerHTML={{__html: stream.message}}></div>
            {stream.reversed && stream.attachment && <img style={{maxWidth: '100%', height: "auto"}} src={`https://gsc.app/fb/.uploads/${stream.attachment}`} /> }
            <div className="post-reactions">
                {/*  <button>Like</button>
                maybe we do this later?
                <button>Comment</button>*/}
            </div>
        </div>
    );
}
export default HtmlStreamView;


/*const Post = props => {
    return (
        <div className="post">
            <div className="post-header">
                <img src={props.profilePic} alt="profile" className="profile-pic"/>
                <div>
                    <h2>{props.username}</h2>
                    <h3>{props.timestamp}</h3>
                </div>
            </div>

            <p>{props.message}</p>

            {props.image && <img src={props.image} alt="post" className="post-image"/>}

            <div className="post-reactions">
                <button>Like</button>
                <button>Comment</button>
                <button>Share</button>
            </div>
        </div>
    );
};*/
