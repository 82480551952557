import {useEffect, useState} from "react";
import {doPost} from "../../services/rest";

function AddFeature({feature, onClose}) {
    const [features, setFeatures] = useState({});
    const [file, setFile] = useState();
    const updateData = event =>{
        setFeatures(prevFeatures => ({
            ...prevFeatures,
            [event.target.name]: event.target.value
        }));
    }

    useEffect(() => {
        if(file){
            const formData = new FormData();
            formData.append('file', file);
            doPost('benefits-admin/upload-file', formData).then(data => {
                setFeatures(prevFeatures => ({
                    ...prevFeatures,
                    data: data.data
                }));
            })
        }
    },[file])

    const onSave = () =>{
        if(!features.displayName){
            window.alert("Please enter a title")
            return
        }
        if(!features.data){
            window.alert("Please complete this form")
            return
        }
        onClose(features)
    }

    const getPlaceHolder = () =>{
        switch (features.type){
            case 'website':
                return "Enter Url"
            case 'phone':
                return "Enter Phone Number"
            default:
                return "Enter Email Address"
        }
    }

    return(<div className={'inner-container'}>
        <input className={'search-input'} name={'displayName'} onChange={updateData} value={features.displayName} placeholder={'title'} />
        <label>Select Feature Type: </label>
        <select style={{marginTop: 15}} className={'submitPost'} name={'type'} value={features.type} onChange={updateData}>
            <option></option>
            <option value={'website'}>Website</option>
            <option value={'phone'}>Phone</option>
            <option value={'email'}>Email</option>
            <option value={'document'}>Document</option>
        </select>
        {(features.type === 'website' || features.type === 'email' || features.type === 'phone') &&
            <input style={{marginTop: 15}} className={'search-input'} name={'data'} value={features.data} onChange={updateData} placeholder={getPlaceHolder()}/> }
        {features.type === 'document' && <div style={{paddingTop: 15}}><input type={'file'} name={'file'} onChange={(event) => setFile(event.target.files[0])} placeholder={'file'}/></div> }
        <br/>
        <button onClick={onSave} style={{marginTop: 15}} className={'submitPost'}>Add</button>
    </div>)
}

export default AddFeature
