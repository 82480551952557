import {useState} from "react";
import {doGet} from "../../services/rest";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";
import {auth} from "../../firebase/firebase";

function CreateGroupMessage({createGroup}) {
    const [userResults, setUsersResults] = useState([]);
    const [title, setTitle] = useState();
    const [selectedUsers, setSelectedUsers] = useState([]);

    const search = event => {
        if(event.key === 'Enter') {
            if(event.target.value.length > 3){
                doGet(`chat/search-users?search=${event.target.value}`).then(result => {
                    setUsersResults(result)
                })
            }
        }
    }

    const selectUser = user => {
        setSelectedUsers(prevState => ([
            ...prevState,
            user
        ]))
    }
    const removeUser = user =>{
        setSelectedUsers(prevState => {
            return prevState.filter(users => user.id !== users.id)
        })
    }

    const saveGroup = () =>{
        if(!title){
            window.alert("Please enter a group title.")
            return
        }
        if(selectedUsers.length === 0){
            window.alert("Please select at least one other user to join your group.")
            return
        }
        const groupMembers = []
        groupMembers.push({name: auth.currentUser.displayName, userId: auth.currentUser.email})
        selectedUsers.forEach(user => {
            groupMembers.push({name: user.displayName, userId: user.userName})
        })
        const newMessage = {groupOwner: auth.currentUser.email, group: true, groupMembers: groupMembers, groupTitle: title}
        createGroup(newMessage)
    }

    return(<div className={'inner-container'}>
        <input className={'search-input'} style={{marginBottom: 10}} type='text' value={title} placeholder={'Enter Group Title'} onChange={e => setTitle(e.target.value)}/>
        <input className={'search-input'} type='text' placeholder={'Search For Users'} onKeyDown={e => search(e)}/>
        <div style={{display: "flex"}}>
            {selectedUsers.map(user => (<div style={{padding: 5, margin: 5,  backgroundColor:'#B2BEB5', borderRadius: 30, display: 'flex', alignItems: 'center'}}><FontAwesomeIcon onClick={() => removeUser(user)} cursor={'pointer'} style={{paddingRight: 3}} icon={faClose} />{user.displayName}</div>))}
        </div>
        {userResults.filter(userResult => !selectedUsers.some(selectedUser => selectedUser.id === userResult.id))
            .map(user => (
                <div onClick={() => selectUser(user)} style={{padding: 10, cursor: "pointer"}} key={user.id}>{user.displayName}</div>
            ))}
        <button onClick={saveGroup} style={{margin: 20}} className={'submitPost'}>Create Group</button>
    </div>)
}

export default CreateGroupMessage
