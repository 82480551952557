import {useEffect, useState} from "react";
import {doGet, doPostBody} from "../../services/rest";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

function TheEmployeeForm({currentVehicle, employeeId, refreshId, onRefresh}) {
    const [vehicle, setVehicle] = useState({});

    useEffect(() => {
        if(currentVehicle) {
            setVehicle(currentVehicle)
        }
    }, [currentVehicle]);

    const onUpdate = event =>{
        setVehicle({...vehicle, [event.target.name]: event.target.value});
    }

    const save = async () =>{
        if(vehicle.year && isNaN(vehicle.year)){
            window.alert("Please enter only numbers for the year")
            return
        }
        if(!vehicle.make){
            window.alert("Vehicle Make is required.")
            return
        }
        if(!vehicle.licencePlate){
            window.alert("Vehicle Licence Plate is required.")
            return
        }
        if(!vehicle.gscId){
            vehicle.gscId=employeeId
        }
        const resp = await doPostBody(`vehicles-admin/save/team-member/${refreshId}`, vehicle)
        if(resp.id){
            onRefresh(resp)
        }else{
            window.alert("Something has gone wrong, vehicle has not been saved.")
        }
    }

    const deleteVehicle = async () =>{
        if(window.confirm("Are you sure you want to delete this vehicle?")){
           const resp = await doGet(`vehicles-admin/team-member/delete/${refreshId}/${vehicle.id}`)
            if(resp.id){
                onRefresh(resp)
            }else{
                window.alert("Something has gone wrong, vehicle has not been deleted.")
            }
        }
    }

    return(<div className={'inner-container'}>
        {vehicle.id && <div style={{display: 'flex', paddingBottom: 20}}><FontAwesomeIcon onClick={deleteVehicle} icon={faTrash} cursor={'pointer'} /> </div>}
        <div className={'flex'} style={{justifyContent: "center", alignItems: "center"}}>
            <div style={{flex: 1, textAlign: "left"}}>Year:</div>
            <div style={{flex: 1}}>
                <input className={'search-input'} type={'number'} placeholder={'Enter Year'} value={vehicle.year}
                       name={'year'} pattern={"[0-9]*"} onChange={onUpdate}/>
            </div>
        </div>
        <div className={'flex'} style={{justifyContent: "center", alignItems: "center", marginTop: 10}}>
            <div style={{flex: 1, textAlign: "left"}}>Make:</div>
            <div style={{flex: 1}}>
                <input className={'search-input'} type={'text'} placeholder={'Enter Make'} value={vehicle.make}
                       name={'make'} onChange={onUpdate}/>
            </div>
        </div>
        <div className={'flex'} style={{justifyContent: "center", alignItems: "center", marginTop: 10}}>
            <div style={{flex: 1, textAlign: "left"}}>Model:</div>
            <div style={{flex: 1}}>
                <input className={'search-input'} type={'text'} placeholder={'Enter Model'} value={vehicle.model}
                       name={'model'} onChange={onUpdate}/>
            </div>
        </div>
        <div className={'flex'} style={{justifyContent: "center", alignItems: "center", marginTop: 10}}>
            <div style={{flex: 1, textAlign: "left"}}>Color:</div>
            <div style={{flex: 1}}>
                <input className={'search-input'} type={'text'} placeholder={'Enter Color'} value={vehicle.color}
                       name={'color'} onChange={onUpdate}/>
            </div>
        </div>
        <div className={'flex'} style={{justifyContent: "center", alignItems: "center", marginTop: 10}}>
            <div style={{flex: 1, textAlign: "left"}}>Licence Plate:</div>
            <div style={{flex: 1}}>
                <input className={'search-input'} type={'text'} placeholder={'Enter Licence Plate'} value={vehicle.licencePlate}
                       name={'licencePlate'} onChange={onUpdate}/>
            </div>
        </div>
        <button onClick={save} className={'submitPost'} style={{marginTop: 30}}>Save</button>
    </div>)
}

export default TheEmployeeForm
