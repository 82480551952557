import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {doGet, doPostBody} from "../../services/rest";
import Heading from "../Heading";
import MySpinner from "../MySpinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {availableIcons} from "../Sidebar";
import PopUp from "../PopUp";
import MyCheckBox from "../MyCheckBox";

function MenuItemsAdmin() {
    const {item} = useParams()
    const [loading, setLoading] = useState(true);
    const [menuItems, setMenuItems] = useState({});
    const [menuItemType, setMenuItemType] = useState([]);
    const [showIconPicker, setShowIconPicker] = useState(false);
    useEffect(() => {
        doGet('app-admin/menu-item-types').then(resp => {
            setLoading(false);
            setMenuItemType(resp)});
    }, []);

    useEffect(() => {
        console.log(menuItems)
    },[menuItems])

    useEffect(() => {
        if(item){
            doGet(`app-admin/menu-item/${item}`).then(resp => setMenuItems(resp));
        }
    }, [item]);

    const onChange = event => {
        setMenuItems(prevState => ({
            ...prevState,
            [event.target.name]: event.target.value
        }));
    }

    const dropDownSelected = (id) => {
        return menuItems?.menuItemTypes?.id === id;
    }

    const onSelectChange = event =>{
      const item =  menuItemType.find(obj => obj.id === parseInt(event.target.value));
      setMenuItems(prevState => ({
          ...prevState,
          menuItemTypes: item
      }))
    }

    const save = async () => {
        if(!menuItems.title){
            window.alert("A title is required")
            return;
        }
        if(!menuItems.menuItemTypes){
            window.alert("Menu item type is required")
            return;
        }
        if(menuItems.menuItemTypes.id === 8 && menuItems.singleLink && !menuItems.singleUrl){
            window.alert("You must specify a URL for a single URL repository.")
            return;
        }

        setLoading(true);

        const resp = await doPostBody(`app-admin/menu-item/save`, menuItems);

        if(resp.id){
            window.alert("Your item has been saved")
            setMenuItems(resp)
            setLoading(false)
        }else{
            window.alert("Something went wrong you menu item was not saved.")
            setLoading(false)
        }

    }

    if(loading){
        return (<>
            <Heading title={'Menu Item'} />
            <MySpinner />
        </>)
    }

    return(<>
        <Heading title={'Menu Item'} />
        <div className={'inner-container'} style={{padding: 50}}>
            <label style={{display: "flex", paddingBottom: 5, paddingLeft: 5}}>Title</label>
            <input type={'text'} name={'title'} onChange={onChange} className={'search-input'}
                   placeholder={'Title'} value={menuItems.title}/>
            <label style={{display: "flex", paddingBottom: 5, paddxingLeft: 5}}>Description</label>
            <input style={{marginTop: 10, marginBottom: 10}} type={'text'} name={'description'}
                   onChange={onChange} className={'search-input'}
                   placeholder={'Description'} value={menuItems.description}/>
            <label style={{display: "flex", paddingBottom: 5, paddingLeft: 5}}>Menu Item Type</label>
            <select name={'menuItemTypes'} className={'search-input'} onChange={onSelectChange}>
                <option></option>
                {menuItemType.map(menuItemType => <option selected={dropDownSelected(menuItemType.id)}
                                                          key={menuItemType.id}
                                                          value={menuItemType.id}>{menuItemType.name}</option>)}
            </select>
            <div style={{display: 'flex', alignItems: 'center', marginTop: 10}}>Select Icon: <FontAwesomeIcon
                style={{marginLeft: 10}} onClick={() => setShowIconPicker(true)} cursor={'pointer'}
                icon={availableIcons[menuItems.icon ?? 'fa-face-smile']}/></div>
            <label style={{display: "flex", paddingBottom: 5, paddxingLeft: 5}}>Order Priority (0 highest)</label>
            <input style={{marginTop: 10, marginBottom: 10}} type={'number'} name={'order'}
                   onChange={onChange} className={'search-input'}
                   placeholder={'Priority'} value={menuItems.order}/>
            {menuItems?.menuItemTypes?.id === 8 && <MyCheckBox id={'single-link'} onChange={() => setMenuItems(prevState => ({...prevState, singleLink: !prevState.singleLink}))} checked={menuItems.singleLink} label={'Single Link Repository'} />}
            {menuItems?.menuItemTypes?.id === 8 && menuItems.singleLink && <>
                            <label style={{display: "flex", paddingBottom: 5, paddxingLeft: 5}}>Enter the URL</label>
                <input style={{marginTop: 10, marginBottom: 10}} type={'text'} name={'singleUrl'}
                                                                 onChange={onChange} className={'search-input'}
                                                                 placeholder={'Single Url'}
                                                                 value={menuItems.singleUrl}/></>
        }
        <button onClick={save} className={'submitPost'}>Save</button>
    </div>
{
    showIconPicker && <PopUp
        content={<>
                <div className={'inner-container'}>
                    <h3>Select your icon</h3>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        {Object.entries(availableIcons).map(([key, value]) => (
                            <FontAwesomeIcon
                                key={key}
                                title={key}  // Optionally set key as a title for easy identification
                                style={{ marginLeft: 10 }}
                                onClick={() => {
                                    setMenuItems(prevState => ({
                                        ...prevState,
                                        icon: key
                                    }))
                                    setShowIconPicker(false)
                                }}
                                cursor='pointer'
                                icon={value}
                            />
                        ))}
                    </div>
                </div>
            </>}
            handleClose={() => setShowIconPicker(false)}
        />}
    </>)
}

export default MenuItemsAdmin;
