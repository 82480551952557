import {hasAdmin} from "../../services/menu";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import Heading from "../Heading";
import MySpinner from "../MySpinner";
import {doGet, doPost, doPostBody, downloadFileFromUrlPost} from "../../services/rest";
import {serviceUrl} from "../../defaults/AppConfig";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAdd,
    faArrowLeft,
    faArrowRight,
    faCheckSquare,
    faSquare
} from "@fortawesome/free-solid-svg-icons";
import FileSaver from 'file-saver';

function GiftCards() {
    const linksAdmin = hasAdmin("ROLE_QR_ADMIN")
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true);
    const [totalGive, setTotalGive] = useState(0);
    const [totalSold, setTotalSold] = useState(0);
    const [totalUnSold, setTotalUnSold] = useState(0);
    const [totalGivenToday, setTotalGivenToday] = useState(0);
    const [currentSelected, setCurrentSelected] = useState('today');
    const [display, setDisplay] = useState({});
    const [cardsTosell, setCardsTosell] = useState([]);
    const [cardsToUnsell, setCardsToUnsell] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [selectAll, setSelectAll] = useState(false);
    if(!linksAdmin){
        navigate("/")
    }

    useEffect(() => {
        updateNumbers()
    },[])

    const updateNumbers = () =>{
        doGet('qr-admin').then(res =>{
            setTotalGive(res.totalGive)
            setTotalSold(res.totalSold)
            setTotalUnSold(res.totalUnSold)
            setTotalGivenToday(res.totalGivenToday)
            setLoading(false)
        })
    }

    useEffect(() =>{
        setLoading(true)
        doGet(`qr-admin/load/${currentSelected}?page=${currentPage}`).then(res => {
            setDisplay(res)
            setLoading(false)
        })
    },[currentSelected, currentPage])

    if(loading){
        return(<>
            <Heading title={'Gift Card Admin'}/>
            <MySpinner />
        </>)
    }
    const markSold = (cardNumber) => {
        if(!cardsTosell.includes(cardNumber)){
            setCardsTosell(prevState => ([
                ...prevState,
                cardNumber
            ]));
        }else{
            setCardsTosell(prevState => prevState.filter(card => card !== cardNumber));
        }
    }

    const unsellCard = (cardNumber) =>{
        if(!cardsToUnsell.includes(cardNumber)){
            setCardsToUnsell(prevState => ([
                ...prevState,
                cardNumber
            ]));
        }else{
            setCardsToUnsell(prevState => prevState.filter(card => card !== cardNumber));
        }
    }
    const exportNumbers = async () => {
        if(cardsTosell.length === 0 && !selectAll){
            window.alert("Please select at least one card to download.")
            return
        }
        const data = {'codes': cardsTosell, 'downloadAll' : selectAll}
        const resp = await downloadFileFromUrlPost({url: 'qr-admin/export', data: data});
        FileSaver.saveAs(new Blob([resp]), 'cards.xlsx')
    }

    const markAsSolde = async() =>{
        if(cardsTosell.length === 0 && !selectAll){
            window.alert("Please select at least one card to sell.")
            return
        }
        if(selectAll){
            if(window.confirm("Are you sure you want to mark all cards as sold?  This will also mark any cars not displaying on this page.")){
                setLoading(true)
                const resp = await doGet('qr-admin/mark-sold-all')
                setDisplay(resp)
                setSelectAll(false)
                setCardsTosell([])
                updateNumbers()
                setLoading(false)
            }
        }else{
            setLoading(true)
            const resp = await doPostBody('qr-admin/mark-sold', cardsTosell)
            setDisplay(resp)
            setCardsTosell([])
            updateNumbers()
            setLoading(false)
        }
    }
    const markAsUnSolde = async () =>{
        if(cardsToUnsell.length === 0){
            window.alert("Please select at least one card to un-sell.")
            return
        }
        setLoading(true)
        const resp = await doPostBody('qr-admin/mark-unsold', cardsToUnsell)
        setDisplay(resp)
        setCardsToUnsell([])
        updateNumbers()
        setLoading(false)
    }
    const addCards = async () =>{
        const number = window.prompt("Enter the number of cards you would like to add");
        if(!isNaN(parseInt(number))){
            setLoading(true)
            const fromData = new FormData();
            fromData.append("number", number)
            const resp = await doPost('qr-admin/add-cards',fromData);
            window.alert(resp.message)
            setLoading(false)
            updateNumbers()
            if(currentSelected !== 'unsold'){
                setCurrentSelected('unsold')
            }else{
                setLoading(true)
                doGet(`qr-admin/load/unsold?page=${currentPage}`).then(res => {
                    setDisplay(res)
                    setLoading(false)
                })
            }

        }else{
            window.alert("Please enter only numbers.")
        }
    }

    return(<>
        <Heading title={'Gift Card Admin'} icons={[<FontAwesomeIcon style={{cursor: 'pointer'}} onClick={addCards} icon={faAdd} />]}/>
        <div className={'inner-container'}>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <div onClick={() => setCurrentSelected('given')}
                     className={currentSelected === 'given' ? 'giftcard selected' : 'giftcard'}>
                    <p>Total Given</p>
                    <p>{totalGive}</p>
                </div>
                <div onClick={() => setCurrentSelected('remaining')}
                     className={currentSelected === 'remaining' ? 'giftcard selected' : 'giftcard'}>
                    <p>Total remaining to give</p>
                    <p>{totalSold}</p>
                </div>
                <div onClick={() => setCurrentSelected('unsold')}
                     className={currentSelected === 'unsold' ? 'giftcard selected' : 'giftcard'}>
                    <p>Unsold Cards</p>
                    <p>{totalUnSold}</p>
                </div>
                <div onClick={() => setCurrentSelected('today')}
                     className={currentSelected === 'today' ? 'giftcard selected' : 'giftcard'}>
                    <p>Given Today</p>
                    <p>{totalGivenToday}</p>
                </div>
            </div>
            {currentSelected === 'given' && <h3>Cards Given</h3>}
            {currentSelected === 'remaining' && <h3>Cards That Can be Given</h3>}
            {currentSelected === 'unsold' && <h3>Cards that have not been sold</h3>}
            {currentSelected === 'today' && <h3>Cards Given Today</h3>}
            <table style={{width: '100%'}} className={'codes'}>
                <tr>
                    <th>Code</th>
                    <th>Link</th>
                    {currentSelected === 'remaining' && <th>Un-sell card</th>}
                    {(currentSelected === 'today' || currentSelected === 'given') && <th>Person</th>}
                    {(currentSelected === 'today' || currentSelected === 'given') && <th>Date</th>}
                    {currentSelected === 'unsold' && <th>Mark Sold / Export  <span style={{cursor: "pointer", fontSize:12}} onClick={() => setSelectAll(!selectAll)}>(Select All) <FontAwesomeIcon  icon={selectAll ? faCheckSquare: faSquare} /></span></th>}
                </tr>
                {display?.content?.map((item, i) => (
                    <tr key={i}>
                        <td>{item.code}</td>
                        <td><a href={serviceUrl + 'qr/birthday/' + item.url} target={'_blank'}>Link</a></td>
                        {currentSelected === 'remaining' &&
                            <td><input onChange={() => unsellCard(item.code)} type={'checkbox'}
                                       checked={cardsToUnsell.includes(item.code)}/></td>}
                        {(currentSelected === 'today' || currentSelected === 'given') &&
                            <td>{item?.gscUsers?.displayName}</td>}
                        {(currentSelected === 'today' || currentSelected === 'given') && <td>{item.dateGiven}</td>}
                        {currentSelected === 'unsold' &&
                            <td style={{cursor: "pointer", textAlign: 'center'}}><input onChange={() => markSold(item.code)}
                                                                   type={'checkbox'}
                                                                   checked={cardsTosell.includes(item.code) || selectAll}/></td>}
                    </tr>
                ))}
            </table>
            <div style={{display: 'flex', flexDirection: 'row', paddingTop: 20}}>
                {!display.first &&
                    <div style={{flex: 1, textAlign: 'left'}}><FontAwesomeIcon style={{cursor: 'pointer'}}
                                                                               icon={faArrowLeft}
                                                                               onClick={() => setCurrentPage(currentPage - 1)}/>
                    </div>}
                {!display.last &&
                    <div style={{flex: 1, textAlign: 'right'}}><FontAwesomeIcon style={{cursor: "pointer"}}
                                                                                icon={faArrowRight}
                                                                                onClick={() => setCurrentPage(currentPage + 1)}/>
                    </div>}
            </div>
            <br/>
            {currentSelected === 'unsold' &&
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <button onClick={markAsSolde} className={'submitPost'}>Mark as sold</button>
                    <button onClick={exportNumbers} className={'submitPost'}>Export Numbers</button>
                </div>}
            {currentSelected === 'remaining' &&
                <button onClick={markAsUnSolde} className={'submitPost'}>Mark as un-sold</button>}
        </div>
    </>)

}

export default GiftCards
