import React, {useEffect, useState} from "react";
import {doGet, doPostBody, imageUploadHandler} from "../../services/rest";
import {Editor} from "@tinymce/tinymce-react";
import ToggleSwitch from "../inputs/ToggleSwitch";
import MyCheckBox from "../MyCheckBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

function AddCategory({currentCategory, onSaveSuccess}) {
    const [category, setCategory] = useState({});
    const [appLocations, setAppLocations] = useState([])

    useEffect(() => {
        doGet('benefits-admin/locations').then(resp => setAppLocations(resp))
    }, []);

    useEffect(() =>{
        if(currentCategory){
            console.log(currentCategory)
            setCategory(currentCategory)
        }else{
            setCategory(prevCategory => ({
                ...prevCategory,
                show: true
            }));
        }
    },[currentCategory])

    const onChange = (e) => {
        setCategory(prevCategory => ({
            ...prevCategory,
            [e.target.name]: e.target.value
        }));
    }
    const onEditChange = (e) => {
        setCategory(prevCategory => ({
            ...prevCategory,
            description: e
        }));
    }
    const toggle = () =>{
        setCategory(prevCategory => ({
            ...prevCategory,
            show: !prevCategory.show
        }));
    }

    const isChecked = (itemId) => {
        return category?.appLocations?.some(location => location.id === itemId);
    };

    const toggleCheckBox = (item) => {
        if(category?.appLocations?.some(location => location.id === item.id)){
            setCategory(prevState => ({
                ...prevState,
                appLocations: category?.appLocations?.filter(location => location.id !== item.id) || []
            }));
        }else{
            setCategory(prevState => ({
                ...prevState,
                appLocations: [...(prevState?.appLocations || []), item]
            }));
        }
    };

    const saveCategory = () => {
        if(!category.name){
            window.alert("Please enter a category name")
            return
        }
        doPostBody('benefits-admin/save-category', category).then(resp =>{
            if(resp.id){
                window.alert("Successfully added a category")
                setCategory(resp)
                onSaveSuccess()
            }else{
                window.alert("Something went wrong")
            }
        })

    }

    const deleteCategory = () =>{
        if(window.confirm("Are you sure you want to delete this category? It cannot be undone.")){
            doGet('benefits-admin/delete-category/'+category.id).then(resp =>{
                if(resp.toString().trim() === 'true'){
                    onSaveSuccess()
                }else{
                    window.alert("something went wrong, your category has not been deleted")
                }
            })
        }
    }

    return(<div className={'inner-container'}>
        {category.id && <div className={'flex'} style={{justifyContent: 'flex-start', paddingBottom: 10}}><FontAwesomeIcon onClick={deleteCategory} cursor={'pointer'} icon={faTrash} /></div>}
        <input style={{marginBottom: 10}} className="search-input" type="text" name="name"
               placeholder="Enter Cateogry Name" value={category.name} onChange={onChange}/>
        <div style={{display: 'flex', alignItems: 'center', marginBottom: 10}}>Hide&nbsp; <ToggleSwitch
            isToggled={category.show}
            toggle={toggle}/>&nbsp; Show
        </div>
        <Editor
            onEditorChange={(e) => onEditChange(e)}
            licenseKey={'gpl'}
            value={category.description}
            init={{
                height: 500,
                menubar: false,
                plugins: [
                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount', 'table'
                ],
                toolbar: 'undo redo | blocks | link table|' +
                    'bold italic forecolor | alignleft aligncenter ' +
                    'alignright alignjustify | image | bullist numlist outdent indent | ' +
                    'removeformat | toggle',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                automatic_uploads: true,
                images_upload_handler: imageUploadHandler,
            }}
            tinymceScriptSrc={'/tinymce/tinymce.min.js'}
        />
        <div>
            <h4>Assigned Locations</h4>
            {appLocations.map(location => (<MyCheckBox key={location.id} id={location.id} label={location.locationName}
                                                       checked={isChecked(location.id)}
                                                       onChange={() => toggleCheckBox(location)}/>))}
        </div>
        <button onClick={saveCategory} style={{marginTop: 10}} className={'submitPost'}>Add Category</button>
    </div>)
}

export default AddCategory
