import React from 'react'
class AutoExpandingTextArea extends React.Component {
    handleInput = (e) => {
        e.target.style.height = 'inherit';
        e.target.style.height = `${e.target.scrollHeight}px`;
    }

    render() {
        return (
            <textarea style={{minHeight: '50px', resize: 'none'}}
                      onInput={this.handleInput}
                      onChange={this.props.onChange}
                      value={this.props.value}/>
        );
    }
}

export default AutoExpandingTextArea
