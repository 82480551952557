import {useEffect, useState} from "react";
import {hasAdmin} from "../../services/menu";
import {useNavigate} from "react-router-dom";
import Heading from "../Heading";
import MySpinner from "../MySpinner";
import {doGet} from "../../services/rest";

function AppAdmin() {

    const [loading, setLoading] = useState(true)
    const [appLocations, setAppLocations] = useState([]);
    const [menuItems, setMenuItems] = useState([]);

    const iaAdmin = hasAdmin('ROLE_ADMIN');
    const navigate = useNavigate();

    useEffect(() => {
        if(!iaAdmin) {
            navigate("/")
        }
    }, [iaAdmin, navigate])

    useEffect(() => {
        doGet('app-admin').then(resp =>{
            setAppLocations(resp.appLocations)
            setMenuItems(resp.menuItems)
            setLoading(false)
        })
    }, []);

    if(loading){
        return(<>
            <Heading title="Admin" />
            <MySpinner />
        </>)
    }

    return(<>
        <Heading title="Admin" />
        <div className={'inner-container'} style={{paddingTop: 50}}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <div/>
                <h3>Groups</h3>
                <div style={{cursor: 'pointer'}} onClick={() => navigate('/app-admin/app-locations')}>Add New</div>
            </div>
            <table style={{width: '100%'}}>
                <tr>
                    <th style={{textAlign: "left"}}>Name</th>
                    <th style={{textAlign: "left"}}>Display Name</th>
                    <th style={{textAlign: "left"}}>Email Address</th>
                    <th style={{textAlign: "left"}}><span>Edit</span></th>
                </tr>
                {appLocations.map(appLocation => <tr key={appLocation.id}>
                    <td style={{textAlign: "left"}}>{appLocation.locationName}</td>
                    <td style={{textAlign: "left"}}>{appLocation.locationDisplayName}</td>
                    <td style={{textAlign: "left"}}>{appLocation.emailAddress}</td>
                    <td style={{textAlign: "left"}}><span style={{cursor: 'pointer'}} onClick={() => navigate(`/app-admin/app-locations/${appLocation.id}`)}>Edit</span></td>
                </tr>)}
            </table>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <div/>
                <h3>Menu Items</h3>
                <div style={{cursor: 'pointer'}} onClick={() => navigate('/app-admin/menu-items')}>Add New</div>
            </div>
            <table style={{width: '100%'}}>
                <tr>
                    <th style={{textAlign: "left"}}>Title</th>
                    <th style={{textAlign: "left"}}>Description</th>
                    <th style={{textAlign: "left"}}>Edit</th>
                </tr>
                {menuItems.map(menuItem => <tr key={menuItem.id}>
                    <td style={{textAlign: "left"}}>{menuItem.title}</td>
                    <td style={{textAlign: "left"}}>{menuItem.description}</td>
                    <td style={{textAlign: "left"}}><span style={{cursor: 'pointer'}} onClick={() => navigate(`/app-admin/menu-items/${menuItem.id}`)}>Edit</span></td>
                </tr>)}
            </table>
        </div>
    </>)

}

export default AppAdmin;
