import SlidePane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import {useEffect, useState} from "react";
import {useSubscription} from "react-stomp-hooks";
import {doGet, doPost} from "../../services/rest";
import moment from "moment";

function CheckList({workOrder, checkList, display, onChange}) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);


    useSubscription(`/it/checkbox/${workOrder.id}`, (message) => setSelectedItems(JSON.parse(message.body)))


    useEffect(() =>{
            setIsOpen(display);
    }, [display])

    useEffect(()=>{
        if(workOrder && checkList){
            doGet(`it-admin/checked-items/${workOrder.id}/${checkList.id}`).then(res=> setSelectedItems(res))
        }
    }, [workOrder, checkList])

    useEffect(()=>{
        onChange(selectedItems.length)
    }, [selectedItems])



    const checkBoxCheck = (id) =>{
        let type = "check"
        if(selectedItems.some(item => item.checklist && item.checklist.id === id)){
            type = "uncheck"
        }

        const formData = new FormData()
        formData.set("workOrder", workOrder.id)
        formData.set("checkList", checkList.id)
        formData.set("checkListItem", id)
        doPost(`it-admin/checkbox-item/${type}`, formData).then(res =>{
            console.log(res)
            setSelectedItems(res)
        });
    }

    const isChecked = (id) =>{
        return selectedItems.some(item => item.checklist.id === id)
    }

    const getResponse = (id) => {
        return selectedItems.find(item => item.checklist && item.checklist.id === id);
    }

    const formatDate = (date) =>{
        return moment(date).format('MMMM D, YYYY at hh:mm a')
    }

    return (
        <div>
            <SlidePane
                isOpen={isOpen}
                title={`${checkList.title} Check List`}
                from="right"
                onRequestClose={() => setIsOpen(false)}
            >
                {checkList.checkListItems.map((item, index) => (
                    <div key={index} className={'survey-button-container'}>
                        <input id={item.id} className={'survey-radio'} type={'checkbox'} value={item.id} onChange={()=>checkBoxCheck(item.id)} checked={isChecked(item.id)}/>
                        <label htmlFor={item.id} className={'checkbox-label'}>{item.item}</label>
                        {getResponse(item.id) && <div>Checked By {getResponse(item.id).checkedBy.displayName} on {formatDate(getResponse(item.id).checkedTime)}</div>}
                    </div>
                ))}
            </SlidePane>
        </div>
    );
}

export default CheckList
