import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../../firebase/firebase";
import {useEffect, useState} from "react";
import {hasAdmin} from "../../services/menu";
import {useNavigate} from "react-router-dom";
import MySpinner from "../MySpinner";
import {doGet} from "../../services/rest";
import {formatPrittyDate} from "../../services/dates";
import Heading from "../Heading";

function Surveys() {

    const [user, loading] = useAuthState(auth);
    const [loadingDisplay, setLoadingDisplay] = useState(true)
    const navigate = useNavigate();
    const [isAdmin, setIsAdmin] = useState(false)
    const [mySurveys, setMySurveys] = useState({})
    const adminRolId = "ROLE_SURVEY_ADMIN"

    useEffect(() => {
        if(loading) return;
        if(!user) navigate("/login")
            setIsAdmin(hasAdmin(adminRolId))
            doGet("surveys/mine").then((surveys) =>{
                setMySurveys(surveys)
                setLoadingDisplay(false)
            })

    }, [user, loading, navigate]);

    if(loadingDisplay){
        return (<>
            <Heading title={"Questionnaire"} />
            <MySpinner />
        </>)
    }

    const takeSurvey = (survey) =>{
        for(const i in mySurveys.responses){
            if(mySurveys.responses[i].survey.id === survey.id){
                navigate("/surveys/take-survey", {state: {survey: survey, answers: mySurveys.responses[i]}})
                return
            }
        }

        navigate("/surveys/take-survey", {state: {survey: survey}})


    }

    const getCompleted = (surveyId) =>{
        const filteredItems = mySurveys.responses.findIndex(e => e.survey.id === surveyId)
        if(filteredItems > -1){
            const surveyResponse = mySurveys.responses[filteredItems]
            if(surveyResponse?.completed ?? false){
                return 'completed on '+ formatPrittyDate(surveyResponse.completedDatTime)
            }
        }
        return ''
    }



    return(<>
        <Heading title={"Questionnaire"} />
        <div className={'main-container'}>
            {isAdmin ? <div className={'flex-space-between'} style={{paddingBottom: '10px'}}>
                <button className={'fancy-button-top'} onClick={() => navigate("/surveys/view")}>View Questionnaire</button>
                <button className={'fancy-button-top'} onClick={() => navigate("/surveys/create")}>Create Questionnaire</button>
            </div> : <></>}

            <h3>My Assigned Surveys</h3>
            <ul className="fancyList">
                {mySurveys.surveys.map((survey) => (
                    <li onClick={() => takeSurvey(survey)}>
                        <h2>{survey.surveyName}</h2>
                        <p>{survey.surveyDescription}</p>
                        <div className={'flex-space-between'}>{getCompleted(survey.id)}</div>
                    </li>
                ))}
            </ul>

        </div>

    </>)

}

export default Surveys
