import { slide as Menu } from 'react-burger-menu';
import "./../css/Sitebar.css";
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {auth, logOut} from "../firebase/firebase";
import {doGet, mainUrl} from "../services/rest";
import {useNavigate} from "react-router-dom";
import {faStream} from "@fortawesome/free-solid-svg-icons";

const availableIcons = {
    "fa-address-book": icon({name: "calendar", style: 'regular'}),
    "fa-face-smile":   icon({name: "smile", style: 'regular'}),
    "fa-memory":     icon({name: "apple", style: 'brands'}),
    "fa-kit-medical":     icon({name: "stethoscope", style: 'solid'}),
    "fa-poll":     icon({name: "poll", style: 'solid'}),
    "fa-user": icon({name: "user-circle", style:"regular"}),
    "fa-documents": icon({name: "folder-blank", style:"regular"}),
    "wpforms": icon({name: "wpforms", style:"brands"}),
    "arrow-circle-o-up": icon({name: "arrow-circle-up", style:"solid"}),
    "star-o": icon({name: "star", style:"solid"}),
    "warning": icon({name: "warning", style:"solid"}),
    "qrcode": icon({name: "qrcode", style:"solid"}),
    "calendar": icon({name: "calendar", style:"solid"}),
    "vehicle": icon({name: "car", style:"solid"}),
    "wechat": icon({name: "message", style:"regular"}),
    "birthday-cake": icon({name: "birthday-cake", style:"solid"}),
    "wrench": icon({name: "wrench", style:"solid"}),
    "film": icon({name: "film", style:"solid"}),
    "admin": icon({name: "user-astronaut", style:"solid"}),
    "business": icon({name: "building", style:"regular"}),
}

function Sidebar({items}){

    const navigate = useNavigate();

    const myNavigation = (item) =>{
        if(item.menuItemTypes.jsRoute === '/web-links'){
            if(item.singleLink){
                    window.open(item.singleUrl, '_blank')
            }else{
                navigate(`/web-links/${item.id}/${item.title}`)
            }
        }else if(item.menuItemTypes.jsRoute === '/documents'){
            navigate(`/documents/${item.id}`)
        }else if(item.menuItemTypes.jsRoute === '/media'){
            navigate(`/media/${item.id}`)
        }else{
            navigate(item.menuItemTypes.jsRoute)
        }
    }



    return(<Menu>
        <div style={{textAlign: 'center'}}>
            <div className={"user-image"}
                 style={{backgroundImage: `url(${mainUrl + "menu/image/" + auth.currentUser?.uid})`}}>
            </div>
            <div>{auth.currentUser?.displayName}</div>
        </div>
        <hr></hr>
        <div onClick={() => navigate("/")}><span
            style={{width: '25px', paddingRight: "5px", textAlign: "center", display: "inline-block"}}><FontAwesomeIcon
            icon={faStream}/></span>Stream
        </div>
        {items.map(e => (
            <div onClick={() => myNavigation(e)}><span style={{
                width: '25px',
                paddingRight: "5px",
                textAlign: "center",
                display: "inline-block"
            }}><FontAwesomeIcon icon={availableIcons[e.icon]}/></span>{e.title}</div>
        ))}
        <div onClick={() => logOut()}><span
            style={{width: '25px', paddingRight: "5px", textAlign: "center", display: "inline-block"}}><FontAwesomeIcon
            icon={icon({name: "hourglass", style: 'regular'})}/></span>Log Off
        </div>
    </Menu>)
}

export default Sidebar

export {availableIcons}
