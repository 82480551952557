import {useEffect, useState} from "react";
import {viewImage} from "../../services/rest";
import Lightbox from "yet-another-react-lightbox";


function SingleImageViewer({image}){
    const [imageSrc, setImageSrc] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        viewImage("full", image).then(resp => {
            const imageObjectURL = URL.createObjectURL(resp);
            setImageSrc(imageObjectURL)
        })
    }, []);
    if (!imageSrc) {
        return <div  style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100px',
            width: '100px',
            border: '1px solid black',
            paddingRight: 10
        }}>
            <div style={{
                border: '16px solid #f3f3f3',
                borderRadius: '50%',
                borderTop: '16px solid #e97200',
                width: '50px',
                height: '50px',
                animation: 'spin 2s linear infinite'
            }}/>
        </div>
    }

    return (
        <>
                <img src={imageSrc} onClick={() => setIsOpen(true)} alt='Secure content' style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    cursor: 'pointer'
                }}/>

            <Lightbox
                close={() => setIsOpen(false)}
                open={isOpen}
                slides={[{src: imageSrc}]}
            />

        </>)
}
export default SingleImageViewer;
