import {useEffect, useState} from "react";
import Heading from "../Heading";
import MySpinner from "../MySpinner";
import {doGet} from "../../services/rest";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faClose, faWarning} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";

function ExpiringDocuments() {
    const [vendors, setVendors] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    useEffect(() => {
        doGet(`vendor-admin/expiring-documents`).then(res => {
            setVendors(res);
            setLoading(false);
        })
    },[])

    if(loading){
        return <>
            <Heading title={'Expiring Documents'} />
            <MySpinner />
        </>
    }

    return <>
        <Heading title={'Expiring Documents'} />
        <div className={'inner-container'} style={{paddingTop: '40px'}}>
            <p>The following vendors have a document that is either expired, or will expire in the next month.</p>
            <table className={'workorder'} style={{width:'100%', fontSize: 12, marginTop: 20}}>
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Address</th>
                    <th>Status</th>
                </tr>
                {vendors.map(vendor => (
                    <tr key={vendor.id} onClick={()=>navigate(`/vendors/${vendor.id}`)}>
                        <td>{vendor.id}</td>
                        <td>{vendor.vendorName}</td>
                        <td>{vendor.address}</td>
                        <td style={{textAlign: "center"}}><FontAwesomeIcon size={"lg"} color={vendor.vendorStatus === null ? 'orange' : vendor.vendorStatus === 'APPROVED' ? 'green' : 'red'} icon={vendor.vendorStatus === null ? faWarning : vendor.vendorStatus === 'APPROVED' ? faCheck : faClose } /></td>
                    </tr>
                ))}
            </table>
        </div>
    </>
}

export default ExpiringDocuments;
