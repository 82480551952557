import {mainUrl} from "../../services/rest";
import MaleThumbImage from "../../images/photo-placeholder-male-thumb.jpg";
import {formatPrittyDate} from "../../services/dates";
import SingleImageViewer from "./SingleImageViewer";
import MultipleImagesViewer from "./MultipleImagesViewer";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../../firebase/firebase";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import React, { useState} from "react";
import PopUp from "../PopUp";
import NewPost from "./NewPost";
import MultiLightBox from "./MultiLightBox";
import VideoViewer from "./VideoViewer";

function NewStreamMessageType({stream, groups, setHideEditor}) {
    const [user] = useAuthState(auth);
    const [showEdit, setShowEdit] = useState(false)
    const [loadMultiplePopup, setLoadMultiplePopup] = useState(false)


    const closeMe = () =>{
        setShowEdit(false)
        setHideEditor(false)
    }

    const showMe = () =>{
        setShowEdit(true)
        setHideEditor(true);
    }

    return (<><div className="post">
        <div className={'flex-space-between'}>
        <div className="post-header">
            <img src={stream.user ? mainUrl + 'menu/image/' + stream.user.id : MaleThumbImage}
                 className={'profile-pic'}/>
            <p className={'post-title'}><i>Posted
                By {stream.user ? stream.user.displayName : stream.sentBy} on {formatPrittyDate(stream.dateSent)}</i>
            </p>
        </div>
            {stream?.user?.id === user.uid && <FontAwesomeIcon icon={faEdit} onClick={showMe} style={{cursor: "pointer"}}/>}
        </div>
        {(stream?.messageImages && stream?.messageImages.length === 1) && <SingleImageViewer image={stream.messageImages[0]} />}
        {(stream?.messageImages && stream?.messageImages.length > 1) && (<div style={{display: "flex"}}>
            {stream.messageImages.map(image => (
                <MultipleImagesViewer key={image} image={image} setShowPopUp={e => setLoadMultiplePopup(e)}/>
            ))}
            {loadMultiplePopup && <MultiLightBox images={stream.messageImages} close={() => setLoadMultiplePopup(false)} />}
        </div>) }
        {stream.videoLink && <VideoViewer video={stream.videoLink} admin={false}/> }
        <div style={{textAlign: 'left'}}>
        <p><pre style={{fontFamily: 'inherit'}}>{stream.message}</pre></p>
        </div>
    </div>
        {showEdit && <PopUp
            content={<>
                <div style={{width: 800}}>
                <NewPost messageEdit={stream} groups={groups} closeMe={closeMe}/>
                </div>
            </>}
            handleClose={closeMe}
        />}
    </>)

}

export default NewStreamMessageType
