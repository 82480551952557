import {useEffect, useState} from "react";
import {viewITImage} from "../../services/rest";

function ITImageViewer({id}) {
    const [imageSrc, setImageSrc] = useState(null);

    useEffect(() => {
        viewITImage(id).then(resp => {
            const imageObjectURL = URL.createObjectURL(resp);
            setImageSrc(imageObjectURL)
        })
    }, [id]);

    if (!imageSrc) {
        return <div  style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100px',
            width: '100px',
            border: '1px solid black',
            paddingRight: 10
        }}>
            <div style={{
                border: '16px solid #f3f3f3',
                borderRadius: '50%',
                borderTop: '16px solid #e97200',
                width: '50px',
                height: '50px',
                animation: 'spin 2s linear infinite'
            }}/>
        </div>
    }

    return (
        <>
            <div style={{
                display: 'flex',
                cursor:'pointer',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100px',
                width: '150px',
                border: '1px solid black',
                overflow: "hidden",
                marginRight: 10
            }}>
                <img src={imageSrc} alt='Secure content' style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover'
                }}/>
            </div>
        </>
    );
}
export default ITImageViewer
