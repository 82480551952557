import {Draggable} from "react-drag-reorder";
import {useEffect, useState} from "react";
import {doPostBody} from "../../services/rest";

function Reorder({items, subpath, onComplete}){
    const [reorder, setReorder] = useState([]);

    useEffect(() => {
        setReorder(items)
    }, [items]);

    const getChangedPos = (currentPos, newPos) => {
        const newSurveyData = [...reorder]
        newSurveyData.splice(newPos, 0, newSurveyData.splice(currentPos, 1)[0])
        setReorder(newSurveyData)
    }

    const saveNewOrder = () => {
        if(subpath){
            doPostBody('benefits-admin/reorder/benefits', reorder).then(res=>{
                if(res.toString().trim() === 'true'){
                    onComplete()
                }else{
                    window.alert("something has gone wrong, we could not reorder the benefits.")
                }
            })
        }else{
            doPostBody('benefits-admin/reorder/categories', reorder).then(res=>{
                if(res.toString().trim() === 'true'){
                    onComplete()
                }else{
                    window.alert("something has gone wrong, we could not reorder the benefits.")
                }
            })
        }
    }

    return(<div className={'inner-container'}>
        <p>Drag to re-order items</p>
        {reorder.length > 0 && !subpath && <Draggable onPosChange={getChangedPos}>
            {reorder.map(item =>(<div key={item.id}>{item.name}</div>))}
        </Draggable>}
        {reorder.length > 0 && subpath && <Draggable onPosChange={getChangedPos}>
            {reorder.map(item =>(<div key={item.id}>{item.title}</div>))}
        </Draggable>}
        <button onClick={saveNewOrder} style={{marginTop: 15}} className={'submitPost'}>Save</button>
    </div> )

}
export default Reorder
