import Heading from "../Heading";
import {useEffect, useState} from "react";
import {doGet} from "../../services/rest";
import {hasAdmin} from "../../services/menu";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faClose, faPlus, faWarning} from "@fortawesome/free-solid-svg-icons";

function Vendors() {
    const [vendors, setVendors] = useState([]);

    const navigate = useNavigate();

    const hasVendors = hasAdmin("ROLE_VENDORS")
    const vendorAdmin = hasAdmin("ROLE_VENDORS_ADMIN")

    useEffect(() => {
        if(!hasVendors && !vendorAdmin) {
            navigate("/");
        }
    }, [hasVendors, vendorAdmin, navigate]);

    const search = async event =>{
        if(event.target.value.length > 2){
            const search = await doGet(`vendor/search?search=${event.target.value}`);
            setVendors(search);
        }
        if(event.target.value.length === 0){
            setVendors([]);
        }
    }

    const heading = () => {
        const heading = [<FontAwesomeIcon cursor={'pointer'} onClick={() => navigate('/vendors/new')} icon={faPlus} />];
        if(vendorAdmin){
            heading.push(<FontAwesomeIcon style={{paddingLeft: 5}} cursor={'pointer'} onClick={() => navigate('/vendors/expire')} icon={faWarning} />)
        }
        return heading;
    }



    return(<>
        <Heading title={"Vendors"} icons={heading()} />
        <div className={'inner-container'} style={{paddingTop:50}}>
            <div>Approved: <FontAwesomeIcon icon={faCheck} color={'green'} /> Rejected: <FontAwesomeIcon icon={faClose} color={'red'} /> Pending: <FontAwesomeIcon icon={faWarning} color={'orange'} /> </div>
            <input onChange={search} type={'text'} className={'search-input'} placeholder={'Search for a vendor'} />
            {vendors.length > 0 && <table className={'workorder'} style={{width:'100%', fontSize: 12, marginTop: 20}} >
                <tr><th>ID</th><th>Name</th><th>Address</th><th>Status</th></tr>
                {vendors.map(vendor => (
                    <tr key={vendor.id} onClick={()=>navigate(`/vendors/${vendor.id}`)}>
                        <td>{vendor.id}</td>
                        <td>{vendor.vendorName}</td>
                        <td>{vendor.address}</td>
                        <td style={{textAlign: "center"}}><FontAwesomeIcon size={"lg"} color={vendor.vendorStatus === null ? 'orange' : vendor.vendorStatus === 'APPROVED' ? 'green' : 'red'} icon={vendor.vendorStatus === null ? faWarning : vendor.vendorStatus === 'APPROVED' ? faCheck : faClose } /></td>
                    </tr>
                ))}
            </table>}
        </div>
    </>)
}
export default Vendors;
