import {useEffect, useState} from "react";
import {viewImage} from "../../services/rest";
import PopUp from "../PopUp";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

function SecureImageViewer({image, index, onRemove}) {
    const [imageSrc, setImageSrc] = useState(null);
    const [showPopUp, setShowPopup] = useState(false)

    useEffect(() => {
        viewImage("thumbnail", image).then(resp => {
            const imageObjectURL = URL.createObjectURL(resp);
            setImageSrc(imageObjectURL)
        })
    }, []);

    if (!imageSrc) {
        return <div  style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100px',
            width: '100px',
            border: '1px solid black',
            paddingRight: 10
        }}>
            <div style={{
                border: '16px solid #f3f3f3',
                borderRadius: '50%',
                borderTop: '16px solid #e97200',
                width: '50px',
                height: '50px',
                animation: 'spin 2s linear infinite'
            }}/>
        </div>
    }

    return (
        <>
        <div onClick={() => setShowPopup(true)} style={{
            display: 'flex',
            cursor:'pointer',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100px',
            width: '100px',
            border: '1px solid black',
            overflow: "hidden",
            marginRight: 10
        }}>
            <img src={imageSrc} alt='Secure content' style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover'
            }}/>
        </div>
            { showPopUp &&<PopUp
                content={<>
                    <div style={{display: 'flex', paddingBottom: 10, cursor: 'pointer'}}>
                    <FontAwesomeIcon icon={faTrash} onClick={() => onRemove(index)} />
                    </div>
                    <img src={imageSrc} alt='Secure content' style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover'
                }}/>
                </>}
                handleClose={() => setShowPopup(false)}
            />}
        </>
    );
}

export default SecureImageViewer;
