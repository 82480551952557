import {useAuthState} from "react-firebase-hooks/auth";
import { signInWithCustomToken } from 'firebase/auth';
import {auth} from "./firebase/firebase";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import "./css/main-app.css";
import Logo from './images/GSC.png'
import MySpinner from "./componants/MySpinner";
import {login} from "./services/rest";
function Login() {

    const [user, loading] = useAuthState(auth);

    const navigate = useNavigate();

    const [loginData, setLoginData] = useState()

    const [isLoading, setIsLoading] = useState(false)

    const [errorMessage, setErrorMessage] = useState(null)

    useEffect(()=>{
        if(loading) return;
        if(user) navigate("/")
    }, [user, loading, navigate])

    const onChange = event => {
        setLoginData(prevState => ({
            ...prevState,
            [event.target.name] : event.target.value
        }))
    }

    const onSubmit = async event =>{
        event.preventDefault()
        if(loginData?.username === undefined){
            alert("Username is required.")
            return
        }
        if(loginData?.password === undefined){
            alert("Password is required.")
            return
        }
        setIsLoading(true)

        const response = await login("login", loginData)
        if (response.success) {
            signInWithCustomToken(auth, response.authToken)
                .then(() => {
                    setIsLoading(false);
                })
                .catch(error => {
                    setErrorMessage(error.code)
                    setIsLoading(false)
                });
        } else {
            setErrorMessage(response.message ?? "Error Logging your on, please try again.")
            setIsLoading(false)
        }
    }

    const handleKeyPress = event =>{
        if(event.key === 'Enter'){
            onSubmit(event)
        }
    }

    return(<>
        <div className={"main-heading-login"}><h3>Garden Spot Communities</h3></div>
        {isLoading ? MySpinner() :
        <div className="container">
            <div className="header">
                <img src={Logo} className={"logo-image"} alt={"GSC Logo"}/>
            </div>
            <div className={"label-align-left"}>
                <label htmlFor="uname"><b>Username</b></label>
                <input className={"login"} onChange={onChange} type="text" placeholder="Enter Username" name="username" required/>
            </div>
            <div className={"label-align-left"}>
                <label htmlFor="psw"><b>Password</b></label>
                <input className={'login'} onChange={onChange} type="password" onKeyDown={handleKeyPress} placeholder="Enter Password" name="password" required/>
            </div>
            {errorMessage != null ? <div style={{color : 'red'}}>{errorMessage}</div> : ''}
            <button onClick={onSubmit} type="submit" class="btn">Login</button>
            <div style={{cursor:'pointer'}}>By Logging in you agree to this apps terms and conditions.</div>
            <div style={{paddingTop: "5px", cursor: "pointer"}}>Forgot username or password.</div>
        </div>}
        <div className={"bottom-footer"}>&copy; {new Date().getFullYear()} Garden Spot Communities</div>
    </>)
}

export default Login
