import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../../firebase/firebase";
import {hasAdmin} from "../../services/menu";
import {doGet, doPostBody, mainUrl} from "../../services/rest";
import Heading from "../Heading";
import MySpinner from "../MySpinner";
import TextInput from "../inputs/TextInput";
import PopUp from "../PopUp";
import ShowDeleteOptions from "./ShowDeleteOptions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

function UserForm() {
    let { id } = useParams();
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState({})
    const [adminItems, setAdminItems] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [showDeleteOptions, setShowDeleteOptions] = useState(false)

    useEffect(() =>{
        if(loading) return;
        if(!user) navigate("/login")
        if(!hasAdmin("ROLE_USERS")) navigate("/")

    },[loading, user, navigate])

    useEffect(() => {
        if(user){
            if(id){
                doGet(`user-admin/user/${id}`).then(res => setUserInfo(res))
            }
            doGet('user-admin/my-login-items').then(res => {
                setAdminItems(res)
                setIsLoading(false)
            })

        }
    }, [user, id]);

    useEffect(() => {
        console.log(userInfo)
    }, [userInfo]);

    if(isLoading){
        return (<>
            <Heading title={`Edit User`} />
            <MySpinner />
        </>)
    }
    const updateData = event =>{
        setUserInfo(prevUsers => ({
            ...prevUsers,
            [event.target.name]: event.target.value
        }) )
    }

    const assignedChecked = (loc) => {
        return userInfo?.locationsSet?.some(item => item.id === loc)
    }

    const roleChecked = (role) => {
        return userInfo?.userSecurities?.some(item => item.securityKey === role)
    }

    const onAssignedChecked = (loc) =>{
        if(userInfo?.locationsSet?.some(item => item.id === loc.id)){
            setUserInfo(prevUsers => ({
                ...prevUsers,
                locationsSet: prevUsers.locationsSet.filter(item => item.id !== loc.id)
            }))
            if(loc.id === userInfo.defaultAppLocation?.id){
                setUserInfo(prevUsers => ({
                    ...prevUsers,
                    defaultAppLocation: undefined
                }))
            }
        }else{
            setUserInfo(prevUsers => ({
                ...prevUsers,
                locationsSet: [...prevUsers.locationsSet, loc]
            }))
        }
    }

    const onCheckBoxRoleCheck = (role) =>{
        if(userInfo?.userSecurities?.some(item => item.securityKey === role)){
            setUserInfo(prevUsers => ({
                ...prevUsers,
                userSecurities: prevUsers.userSecurities.filter(item => item.securityKey !== role)
            }))
        }else{
            setUserInfo(prevUsers => ({
                ...prevUsers,
                userSecurities: [...prevUsers.userSecurities, {id:0, securityKey: role}]
            }))
        }
    }

    const azureDomain = () =>{
        if(userInfo?.userName?.includes('@')) {
            const [, domain] = userInfo.userName.split("@");
            return adminItems.azureDomains.includes(domain);
        }
        return false;
    }

    const saveUser = () => {
        setIsLoading(true)
        doPostBody("user-admin/save", userInfo).then(resp =>{
            setUserInfo(resp)
            setIsLoading(false)
        })
    }

    const adminLocationsChecked = (admin) =>{
        return userInfo?.appLocationsAdmin?.some(item => item.id === admin.id)
    }

    const onAdminLocationsChanges = admin =>{
        if(userInfo?.appLocationsAdmin?.some(item => item.id === admin.id)){
            setUserInfo(prevUsers => ({
                ...prevUsers,
                appLocationsAdmin:  prevUsers.appLocationsAdmin.filter(item => item.id !== admin.id)
            }))
        }else{
            setUserInfo(prevUsers => ({
                ...prevUsers,
                appLocationsAdmin: [...prevUsers.appLocationsAdmin, admin]
            }))
        }

    }

    const defaultLocationChecked = (loc) =>{
        return userInfo?.defaultAppLocation?.id === loc.id
    }

    const setDefaultAppLocation = (loc) => {
        setUserInfo(prevUsers => ({
            ...prevUsers,
            defaultAppLocation: loc
        }))
    }

    return(<>
        <Heading title={`Edit User ${userInfo.displayName}`} icons={[<FontAwesomeIcon cursor={'pointer'} onClick={() => setShowDeleteOptions(true)} icon={faTrash} />]}/>
        <div className={'main-container'}>
            <div className={'flex'}>
                <div className={'user-container'} style={{paddingTop: 30}}>
                    <div style={{textAlign: 'center'}}>
                        <div className={"user-image"}
                             style={{backgroundImage: `url(${mainUrl + "menu/image/" + userInfo.id})`}}>
                        </div>
                        <div style={{paddingTop: 10}}>{userInfo.displayName}</div>
                    </div>
                    <div style={{paddingTop: 10}}>
                        {!azureDomain() && <button className={'action-button'}>Reset Password</button>}
                    </div>
                </div>
                <div className={'security-container'} style={{width: '80%'}}>
                    <div style={{
                        margin: '0 auto',
                        marginTop: 30,
                        display: 'flex',
                        justifyContent: 'space-around',
                        flexWrap: 'wrap'
                    }}>
                        <TextInput label={'Display Name'} value={userInfo.displayName} name={'displayName'}
                                   onChange={updateData}/>
                        <TextInput label={'GSC ID'} value={userInfo.gscId} name={'gscId'} onChange={updateData}
                                   readOnly={userInfo.gscId}/>
                        {!azureDomain() &&
                            <TextInput label={'Email'} value={userInfo.userName} name={'userName'} onChange={updateData}
                                       readOnly={userInfo.userName}/>}
                        <div style={{
                            margin: '10px 0',
                            alignItems: 'center'
                        }}>
                            <label style={{
                                marginRight: '10px'
                            }}>
                                User Type
                            </label>
                            <select name={'directory'} onChange={updateData} style={{
                                padding: '5px'
                            }}>
                                <option value={'RESIDENT'} selected={userInfo.directoryType === 'RESIDENT'}>Resident
                                </option>
                                <option value={'EMPLOYEE'} selected={userInfo.directoryType === 'EMPLOYEE'}>Team
                                    Member
                                </option>
                            </select>
                        </div>
                    </div>
                    <div className={'flex'}>
                        <div className={'Roles'}
                             style={{width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <h4>Roles</h4>
                            <div>
                                {adminItems.roles.map(role => (
                                    <div className={'survey-button-container'}>
                                        <input id={role.id} className={'survey-radio'} type={'checkbox'} name={role.id}
                                               value={role.id} onChange={() => onCheckBoxRoleCheck(role.role)}
                                               checked={roleChecked(role.role)}/>
                                        <label className={'checkbox-label'} htmlFor={role.id}>{role.name}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={'Location Assignments'}
                             style={{width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <h4>Assigned Locations</h4>
                            <div>
                                {adminItems.adminLocations.map(loc => (
                                    <div className={'survey-button-container'}>
                                        <input id={'s' + loc.id} className={'survey-radio'}
                                               onChange={() => onAssignedChecked(loc)} type={'checkbox'} name={loc.id}
                                               checked={assignedChecked(loc.id)} value={loc.id}/>
                                        <label className={'checkbox-label'}
                                               htmlFor={'s' + loc.id}>{loc.locationName}</label>
                                    </div>
                                ))}
                            </div>
                            <h4>Default Location</h4>
                            <div>
                                {userInfo?.locationsSet?.map(loc => (
                                    <div className={'survey-button-container'}>
                                        <input className={'survey-radio'} type="radio"
                                               id={"d"+loc.id} name={loc.id}
                                               value={loc.id}
                                               onChange={()=> setDefaultAppLocation(loc)}
                                            checked={defaultLocationChecked(loc)}
                                        />
                                        <label className={'radio-label'}
                                               htmlFor={'d' + loc.id}>{loc.locationName}</label>
                                    </div>
                                ))}
                            </div>
                            {hasAdmin("ROLE_USER_LOCATION") && <>
                                <h4>Assigned Admin Location</h4>
                                <div>
                                    {adminItems.adminLocations.map(loc => (
                                        <div className={'survey-button-container'}>
                                            <input id={'a' + loc.id} onChange={() => onAdminLocationsChanges(loc)}
                                                   checked={adminLocationsChecked(loc)} className={'survey-radio'} type={'checkbox'} name={loc.id}
                                                   value={loc.id}/>
                                            <label className={'checkbox-label'}
                                                   htmlFor={'a' + loc.id}>{loc.locationName}</label>
                                        </div>
                                    ))}
                                </div>
                            </>}
                        </div>
                    </div>
                </div>

            </div>
            <div style={{paddingBottom: 30}}>
                <button onClick={saveUser} className={'action-button'}>Save Changes</button>
            </div>
        </div>
        {showDeleteOptions && <PopUp
            content={<ShowDeleteOptions uid={userInfo.id} close={() => setShowDeleteOptions(false)} />}
            handleClose={() => setShowDeleteOptions(false)}
        />}
    </>)
}

export default UserForm;
