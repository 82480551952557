import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {doGetSurvey, doPostBodySurvey} from "../../services/rest";
import Heading from "../Heading";
import MySpinner from "../MySpinner";
import TextAreaQuestion from "../TextAreaQuestion";

function SurveyLinkTaker() {

    const [validated, setIsValidated] = useState(false);
    const [survey, setSurvey] = useState({})
    const [surveyAnswers, setSurveyAnsers] = useState({})
    const [myGroup, setMyGroup] = useState()
    const [isLastGroup, setIsLastGroup] = useState(false)
    const navigate = useNavigate();

    const {id} = useParams()

    useEffect(() => {
        if(id){
            doGetSurvey("survey-links/validate", id).then(resp => setIsValidated(resp))
        }
    }, [id]);

    useEffect(() =>{
        if(validated){
            const decoded = atob(id).split(":")
            doGetSurvey(`survey-links/load-both/${decoded[1]}`, id).then(resp => {
                if(!resp.answers){
                    startNewSurvey()
                }else{
                    setSurveyAnsers(resp.answers)
                }
                setSurvey(resp.survey)
            })
        }
    },[validated])

    useEffect(()=>{
        if(survey.questionGroups !== undefined) {
            setIsLastGroup(myGroup === survey.questionGroups.length)
        }else{
            setIsLastGroup(false)
        }
    },[myGroup])

    const startNewSurvey = () =>{
        const newSurvey = {
            "startedDateTime": new Date(),
            "survey": survey,
            "surveyAnswers": []
        }
        setSurveyAnsers(newSurvey)
    }

    const answerQuestions = (event, question) =>{
        const questionId = question.id
        const answers = [...surveyAnswers.surveyAnswers]
        const i = answers.findIndex(e => e?.questions?.id === questionId);
        if(i > -1){
            const answer = answers[i]
            answer.textAnswer = event.target.value
            answers[i] = answer
            setSurveyAnsers(surveyAnswers => ({...surveyAnswers, surveyAnswers: answers}))
        }else{
            const answer = {textAnswer: event.target.value, questions: question}
            answers.push(answer)
            setSurveyAnsers(surveyAnswers => ({...surveyAnswers, surveyAnswers: answers}))
        }

    }



    const radioSelected = (event, question) =>{
        const questionId = question.id
        const answers = [...surveyAnswers.surveyAnswers]
        const j = answers.findIndex(e => e.questions.id === questionId)
        if(j > -1){
            const i = question.questionAnswers.findIndex(e => e.id.toString() === event.target.value.toString())
            answers[j].answers = question.questionAnswers[i]
            setSurveyAnsers(surveyAnswers => ({...surveyAnswers, surveyAnswers: answers}))
        }else{
            const i = question.questionAnswers.findIndex(e => e.id.toString() === event.target.value.toString())
            const answer = {questions: question, answers: question.questionAnswers[i]}
            answers.push(answer)
            setSurveyAnsers(surveyAnswers => ({...surveyAnswers, surveyAnswers: answers}))
        }
    }

    const checkBoxChanged = (event, question) =>{
        const questionId = question.id
        const answers = [...surveyAnswers.surveyAnswers]
        const i = answers.findIndex(e => e.questions.id === questionId)
        if(i > -1){
            const j = question.questionAnswers.findIndex(e => e.id.toString() === event.target.value.toString())
            const answer = question.questionAnswers[j]
            const current = answers[i].answersList
            const k = current.findIndex(e => e.id === answer.id)
            if(k > -1){
                current.splice(k, 1)
                answers[i].answersList = current
            }else{
                answers[i].answersList.push(answer)
            }
            setSurveyAnsers(surveyAnswers => ({...surveyAnswers, surveyAnswers: answers}))
        }else{
            const answer = {questions: question}
            answer.answersList = []
            const i = question.questionAnswers.findIndex(e => e.id.toString() === event.target.value.toString())
            answer.answersList.push(question.questionAnswers[i])
            answers.push(answer)
            setSurveyAnsers(surveyAnswers => ({...surveyAnswers, surveyAnswers: answers}))
        }
    }

    const getValue = (question) =>{
        const questionId = question.id
        const answers = [...surveyAnswers.surveyAnswers]
        const i = answers.findIndex(e => e.questions.id === questionId);
        if(i > -1){
            return answers[i].textAnswer
        }
        return ''
    }

    const setSurveyGroup = () =>{
        let newGroupVariable = myGroup
        if(newGroupVariable === undefined){
            newGroupVariable = 0
        }
        setMyGroup(newGroupVariable+1)
    }

    const goBack = () =>{
        let newVarialbe = myGroup - 1;
        setMyGroup(newVarialbe)
    }



    const saveSurvey = async (completed) =>{
        if(completed){
            if(!window.confirm("Are you sure you want to submit your questionnaire?  You will no longer be able to make any changes.")){
                return
            }
        }
        surveyAnswers.survey = survey
        surveyAnswers.completed = completed
        try{
            const resp = await doPostBodySurvey("survey-links/save-survey", surveyAnswers, id)
            setSurveyAnsers(resp)
            if(completed){
                alert("Thank you for completing this survey. Your answers allow us to create a more vibrant community. Your answers will be confidential.")
                navigate("/")
            }else{
                if(window.confirm("Your survey has been saved.  You may come back any time to complete it later.  Press OK to go home, or cancel to continue working on the survey")){
                    navigate("/")
                }
            }
        }catch (e){
            console.log(e)
            alert(e)
        }
    }


    const radioButtonChecked = (id, question) =>{
        const answers = [...surveyAnswers.surveyAnswers]
        const i = answers.findIndex(e => e.questions.id === question.id)
        if(i > -1){
            return answers[i].answers.id === id
        }
        return false;
    }

    const checkBoxShouldBeChecked = (id, question) =>{
        const answers = [...surveyAnswers.surveyAnswers]
        const i = answers.findIndex(e => e.questions.id === question.id)
        if(i > -1){
            return answers[i].answersList.findIndex(e => e.id === id) > -1
        }
        return false;
    }

    if(!validated && !survey && !surveyAnswers){
        return(<>
            <Heading title={"Questionnaire"} />
            <MySpinner />
        </>)
    }



    if (myGroup === undefined) {
        return(<>
            <Heading title={"Questionnaire"} />
            <div className={'main-container'}>
                <h3>{survey.surveyName}</h3>
                <br/>
                <p>{survey.surveyDescription}</p>
                <br/>
                <button className={'fancy-button'} onClick={setSurveyGroup}>Start Questionnaire</button>
            </div>
        </>)
    }

    return (<>
        <Heading title={"Questionnaire"} />
        <div className={'main-container'}>
            <div className={'survey-container'}>
                <h3>{survey.questionGroups[myGroup - 1].groupName}</h3>
                <br/>
                <div dangerouslySetInnerHTML={{__html: survey.questionGroups[myGroup - 1].groupDescription}}/>
                <br/>

                {survey.questionGroups[myGroup - 1].questions.map((question) => {
                    switch (question.questionType) {
                        case "LONGTEXT":
                            return <>
                                <div className={'question'}>{question.question}</div>
                                <textarea
                                    onChange={e => answerQuestions(e, question)}
                                    value={getValue(question)}></textarea>
                                <div className={'survey-question-container'}></div>
                            </>

                        case "MULTIPLECHOICESINGLE":
                            return <>
                                <div className={'question'}>{question.question}</div>
                                <div className={'flex'}>
                                    {question.questionAnswers.map((answers) => (
                                        <div className={'survey-button-container'}>
                                            <input className={'survey-radio'} type="radio"
                                                   id={"id_" + answers.id + "_" + question.id} name={question.id}
                                                   value={answers.id} onClick={e => radioSelected(e, question)}
                                                   checked={radioButtonChecked(answers.id, question)}/>
                                            <label className={'radio-label'}
                                                   htmlFor={"id_" + answers.id + "_" + question.id}>{answers.answer}</label>
                                        </div>
                                    ))}</div>
                                <div className={'survey-question-container'}></div>
                            </>
                        case "MULTIPLECHOICE":
                            return <>
                                <div className={'question'}>{question.question}</div>
                                <div className={'flex'}>
                                    {question.questionAnswers.map((answers) => (
                                        <div className={'survey-button-container'}>
                                            <input className={'survey-radio'} type="checkbox"
                                                   id={"id_" + answers.id + "_" + question.id} name={answers.id}
                                                   value={answers.id} onChange={e => checkBoxChanged(e, question)}
                                                   checked={checkBoxShouldBeChecked(answers.id, question)}/>
                                            <label className={'checkbox-label'}
                                                   htmlFor={"id_" + answers.id + "_" + question.id}>{answers.answer}</label>
                                        </div>
                                    ))}</div>
                                <div className={'survey-question-container'}></div>
                            </>

                        default:
                            return <TextAreaQuestion
                                key={question.id}
                                question={question}
                                initialAnswer={getValue(question)}
                                handleAnswerChange={answerQuestions}
                            />
                    }
                })}
                {surveyAnswers?.completed ?? false ? <div className={"flex-space-between"}>
                    {myGroup > 1 && <button className={'fancy-button'} onClick={goBack}>Back</button>}
                    {!isLastGroup && <button className={'fancy-button'} onClick={setSurveyGroup}>Next</button>}
                </div> : <div className={'flex-space-between'}>
                    {myGroup > 1 && <button className={'fancy-button'} onClick={goBack}>Back</button>}
                    <button className={'fancy-button'} onClick={() => saveSurvey(false)}>Save and finish later</button>
                    {!isLastGroup ? <button className={'fancy-button'} onClick={setSurveyGroup}>Next</button>
                        : <button className={'fancy-button'} onClick={() => saveSurvey(true)}>Submit Questionnaire</button>
                    }

                </div>}
            </div>
        </div>
    </>)


}
export default SurveyLinkTaker
