import Heading from "./Heading";
import React, {useEffect, useState} from "react";
import MySpinner from "./MySpinner";
import {doGet} from "../services/rest";
import {defaultColor} from "../defaults/AppConfig";
import ResidentImages from "./directory/ResidentImages";
import {formatFirstNameLastName} from "./directory/NameFormatters";
import PopUp from "./PopUp";
import ViewResident from "./directory/ViewResident";
import moment from "moment";
import ResidentImageSingle from "./directory/ResidentImageSingle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight} from "@fortawesome/free-solid-svg-icons";


function Celebrations() {

    const [loading, setLoading] = useState(true);
    const [birthday, setBirthday] = useState([]);
    const [newResidents, setNewResidents] = useState([]);
    const [deaths, setDeaths] = useState([]);
    const [startDateBirthday, setStartDateBirthday] = useState();
    const [startDateNew, setStartDateNew] = useState();
    const [startDateDeath, setStartDateDeath] = useState();
    const [selected, setSelected] = useState('new');
    const [newResidentPopup, setNewResidentPopup] = useState();
    const [viewDeathInfo, setViewDeathInfo] = useState();
    useEffect(() => {
        doGet('celebrations').then(resp => {
            setBirthday(resp.birthday)
            setNewResidents(resp.new)
            setDeaths(resp.death)
            const now = new Date();
            now.setDate(1);
            setStartDateBirthday(now)
            setStartDateDeath(now)
            setStartDateNew(now)
            setLoading(false)
        })
    }, []);

    if(loading){
        return(<>
            <Heading title={'Celebrations'} />
            <MySpinner />
        </>)
    }

    const forwardTitle = () =>{
        switch (selected){
            case "new":
                const newDate = new Date(startDateNew)
                newDate.setMonth(newDate.getMonth() + 1)
                return moment(newDate).format('MMMM YYYY');
            case "birthday":
                const newDate1 = new Date(startDateBirthday)
                newDate1.setMonth(newDate1.getMonth() + 1)
                return moment(newDate1).format('MMMM YYYY');
            case "death":
                const newDate2 = new Date(startDateDeath)
                newDate2.setMonth(newDate2.getMonth() + 1)
                return moment(newDate2).format('MMMM YYYY');
        }
    }

    const backTitle = () => {
        switch (selected){
            case "new":
                const newDate = new Date(startDateNew)
                newDate.setMonth(newDate.getMonth() - 1)
                return moment(newDate).format('MMMM YYYY');
            case "birthday":
                const newDate1 = new Date(startDateBirthday)
                newDate1.setMonth(newDate1.getMonth() - 1)
                return moment(newDate1).format('MMMM YYYY');
            case "death":
                const newDate2 = new Date(startDateDeath)
                newDate2.setMonth(newDate2.getMonth() - 1)
                return moment(newDate2).format('MMMM YYYY');
        }
    }

    const loadResidends = (newDate) => {
        setLoading(true)
        let endDate = new Date(newDate)
        endDate.setMonth(endDate.getMonth() + 1)
        endDate.setDate(0)
        doGet(`celebrations/new-residents?startDate=${moment(newDate).format("YYYY-MM-DD")}&endDate=${moment(endDate).format("YYYY-MM-DD")}`).then(resp =>{
            setNewResidents(resp)
            setLoading(false)
        })
    }

    const birthdayByMonth = (newDate) => {
        setLoading(true)
        doGet(`celebrations/birthdays?month=${newDate.getMonth() +1}`).then(resp =>{
            setBirthday(resp)
            setLoading(false)
        })
    }

    const deceasedResidents = (newDate) =>{
        setLoading(true)
        doGet(`celebrations/celebrate-life?date=${moment(newDate).format("YYYY-MM-DD")}`).then(resp =>{
            setDeaths(resp)
            setLoading(false)
        })
    }

    const goBack = () =>{
        switch (selected){
            case "new":
                const newDate = new Date(startDateNew)
                newDate.setMonth(newDate.getMonth() - 1)
                setStartDateNew(newDate)
                loadResidends(newDate)
                break;
            case "birthday":
                const newDate1 = new Date(startDateBirthday)
                newDate1.setMonth(newDate1.getMonth() - 1)
               setStartDateBirthday(newDate1)
                birthdayByMonth(newDate1)
                break;
            case "death":
                const newDate2 = new Date(startDateDeath)
                newDate2.setMonth(newDate2.getMonth() - 1)
                setStartDateDeath(newDate2)
                deceasedResidents(newDate2)
                break;
        }
    }

    const goForward = () =>{
        switch (selected){
            case "new":
                const newDate = new Date(startDateNew)
                newDate.setMonth(newDate.getMonth() + 1)
                setStartDateNew(newDate)
                loadResidends(newDate)
                break;
            case "birthday":
                const newDate1 = new Date(startDateBirthday)
                newDate1.setMonth(newDate1.getMonth() + 1)
                setStartDateBirthday(newDate1)
                birthdayByMonth(newDate1)
                break;
            case "death":
                const newDate2 = new Date(startDateDeath)
                newDate2.setMonth(newDate2.getMonth() + 1)
                setStartDateDeath(newDate2)
                deceasedResidents(newDate2)
                break;
        }
    }

    return(<>
        <Heading title={'Celebrations'}/>
        <div className={'inner-container'} style={{paddingTop: 50}}>
            <div className={'flex-space-between'}>
                <div onClick={() => setSelected('new')}
                     style={selected === 'new' ? style.selected : style.notSelected}>New Residents
                </div>
                <div onClick={() => setSelected('birthday')}
                     style={selected === 'birthday' ? style.selected : style.notSelected}>Resident Birthdays
                </div>
                <div onClick={() => setSelected('death')}
                     style={selected === 'death' ? style.selected : style.notSelected}>Celebrate a life
                </div>
            </div>
            <h4>
                {
                    selected === 'new' && <>{moment(startDateNew).format("MMMM YYYY")}</>
                }
                {
                    selected === 'birthday' && <>{moment(startDateBirthday).format("MMMM YYYY")}</>
                }
                {
                    selected === 'death' && <>{moment(startDateDeath).format("MMMM YYYY")}</>
                }
            </h4>
            <div className={'flex'} style={{paddingTop: 50}}>
                {selected === 'new' && <>
                    {newResidents.map((item, index) => (
                        <div onClick={() => setNewResidentPopup(item)} key={index}
                             style={{width: 300, cursor: 'pointer', paddingBottom: 10}}>
                            <ResidentImages resident={item} thumb={true}/>
                            <h3>{formatFirstNameLastName(item.residentsNew)}</h3>
                            <div>{item.homeNumber} {item.address}</div>
                            <div>{item?.neighborhood?.neighborHoodCommunityName ?? ''}</div>
                        </div>))}
                </>}
                {selected === 'birthday' && <>
                    {birthday.map((item, index) => (
                        <div key={index} style={{width: 300, marginBottom: 10}}>
                            <ResidentImageSingle resident={item} thumb={true}/>
                            <h3>{moment(item.residentBirthdate).format("MM-DD")} {item.firstName} {item.lastName}</h3>
                            <p>{item?.residentLocations?.neighborhood?.neighborHoodCommunityName ?? ''}</p>
                        </div>))}
                </>}
                {selected === 'death' && <>
                    {deaths.map((item, index) => (
                        <div onClick={() => setViewDeathInfo(item)} key={index}
                             style={{width: 300, marginBottom: 10, cursor: 'pointer'}}>
                            <ResidentImageSingle resident={item} thumb={true}/>
                            <h3>{item.fname} {item.lname}</h3>
                            <div>{item.residentLocations.homeNumber} {item.residentLocations.address}</div>
                            <div>{item?.residentLocations?.neighborhood?.neighborHoodCommunityName ?? ''}</div>
                            <div>{moment(item.residentBirth).format("MM/DD/YYYY")} - {moment(item.datePassed).format("MM/DD/YYYY")}</div>
                        </div>))}
                </>}
            </div>
        </div>
        {newResidentPopup && <PopUp
            content={<ViewResident resident={newResidentPopup}/>}
            handleClose={() => setNewResidentPopup(undefined)}
        />}
        {viewDeathInfo && <PopUp
            content={<>
                <ResidentImageSingle resident={viewDeathInfo}/>
                <div style={style.name}>{viewDeathInfo.fname} {viewDeathInfo.lname}</div>
                <div>{viewDeathInfo.residentLocations.homeNumber} {viewDeathInfo.residentLocations.address}</div>
                <div>{viewDeathInfo?.residentLocations?.neighborhood?.neighborHoodCommunityName ?? ''}</div>
                <div>{moment(viewDeathInfo.residentBirth).format("MM/DD/YYYY")} - {moment(viewDeathInfo.datePassed).format("MM/DD/YYYY")}</div>
                {viewDeathInfo.deathInformation && <p>Service will be held
                    at {viewDeathInfo.deathInformation.serviceLocation} on {moment(viewDeathInfo.deathInformation.serviceDate).format('dddd MMMM D, [at] h:mm a')}</p>}
            </>}
            handleClose={() => setViewDeathInfo(undefined)}
        />}
        <div></div>
        <div style={{position: 'fixed', bottom: 0, width: '100%', padding: '10px', backgroundColor: '#EEE', display: "flex", justifyContent: 'space-between'}}>
            <div onClick={() => goBack()} style={{paddingLeft: 20, cursor: 'pointer'}}><FontAwesomeIcon icon={faArrowLeft} />{backTitle()}</div>
            <div onClick={() => goForward()} style={{paddingRight: 20, cursor: 'pointer'}}>{forwardTitle()}<FontAwesomeIcon icon={faArrowRight} /></div>
        </div>
    </>)
}

const style = {
    selected: {
        color: '#fff',
        backgroundColor: defaultColor,
        flex: 1,
        cursor: 'pointer',
        border: '1px solid gray'
    },
    notSelected: {
        flex: 1,
        cursor: 'pointer',
        border: '1px solid gray'
    },
    name: {
        color: defaultColor,
        fontWeight: 'bold',
        fontSize: 26,
    },
    footer:{
        display: 'flex',
    }
}

export default Celebrations
