import FemalePlaceholder from './../../images/photo-placeholder-female-thumb.jpg';
import MalePlaceholder from './../../images/photo-placeholder-male-thumb.jpg';
import CouplePlaceholder from './../../images/photo-placeholder-couple-thumb.jpg';

function ResidentImages({resident, thumb}) {
    let imgSrc = '';

    if (
        resident.homePhoto &&
        resident.homePhoto.length > 0 &&
        resident.residentsNew.length > 1
    ) {
        let newPhoto = resident.homePhoto;

        if (thumb) {
            let photoFiles = resident.homePhoto.split('.');
            newPhoto = `${photoFiles[0]}-thumb.${photoFiles[1]}`;
        }
        imgSrc = `https://photos.gsv.gsrdp.org/${newPhoto}`;

    } else if (resident.residentsNew.length > 1) {
        imgSrc = CouplePlaceholder;

    } else if (resident.residentsNew[0].photo) {
        let newPhoto = resident.residentsNew[0].photo;

        if (thumb) {
            let photoFiles = resident.residentsNew[0].photo.split('.');
            newPhoto = `${photoFiles[0]}-thumb.${photoFiles[1]}`;
        }
        imgSrc = `https://photos.gsv.gsrdp.org/${newPhoto}`;

    } else {
        imgSrc = resident.residentsNew[0].residentGender === 'female'
            ? FemalePlaceholder
            : MalePlaceholder;
    }

    return (
        <img
            style={thumb ? styles.image : styles.largeImage}
            src={imgSrc}
            alt="Resident"
        />
    );
}

const styles = {
    image: {
        width: 165,
    },
    largeImage: {
        objectFit: 'contain',
        width: '500px',
    },
};
export default ResidentImages
