import {useState} from "react";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {doGet, doPost} from "../../services/rest";

function WorkOrderSearch({appLocations}) {
    const [workOrders, setWorkOrders] = useState();
    const [inputValue, setInputValue] = useState('');
    const navigate = useNavigate();

    const formatDate = (dateTime) => {
        return  moment(dateTime).format("MM/DD/yyyy h:mm a");
    }
    const formatDateOnly = (dateTime) => {
        if(!dateTime){
            return ""
        }
        return  moment(dateTime).format("MM/DD/yyyy");
    }

    const handleKeyPress = event => {
        if(event.key === 'Enter'){
            if(inputValue.length >= 3){
                const formData = new FormData();
                formData.set('search', inputValue);
                doPost('it-admin/search', formData).then(r => setWorkOrders(r));
            }else{
                alert("Please enter more to search by")
            }
        }
    }
    const handleDateChange = event => {
        const dateValue = event.target.value;
        // Extract the year part from the date
        const year = Number(dateValue.substring(0, 4));

        // Only proceed if the year is after 2000
        if (year > 2000) {
            const formData = new FormData();
            formData.set('date', event.target.value);
            doPost('it-admin/closed/date', formData).then(r => setWorkOrders(r));
        }
    }

    return (<div className={'inner-container'}>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingBottom: '10px'}} >
            <div>
                Search By Keyword: <input type="text" placeholder="Search" value={inputValue}  onChange={e => setInputValue(e.target.value)} onKeyDown={handleKeyPress}/>
            </div>
            <div>
                Search By Date: <input type="date" placeholder="Search" onChange={handleDateChange} />
            </div>
        </div>
        {workOrders && <table className={'workorder'} style={{width: '100%'}}>
            <tr>
                <th>Submitted Date</th>
                <th>Name</th>
                <th>Description</th>
                <th>Assigned To</th>
                <th>Due Date</th>
                <th>Closed By</th>
            </tr>
            {workOrders.map(order => (
                <tbody key={order.id} onClick={() => navigate(`/workorders/edit/${order.id}`, {state: {locations: appLocations}})}>
                <tr>
                    <td>{formatDate(order.closedDate)}</td>
                    <td>{order?.name ?? ''}</td>
                    <td>{order.subject}</td>
                    <td>{order?.assignedTo?.displayName ?? ""}</td>
                    <td>{formatDateOnly(order?.dueDate)}</td>
                    <td>{order?.closedBy?.displayName ?? ""}</td>
                </tr>
                    </tbody>
                ))}
        </table>}
    </div>)
}

export default WorkOrderSearch;
