import {useEffect, useState} from "react";
import Heading from "../Heading";
import MySpinner from "../MySpinner";
import {doGet} from "../../services/rest";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";

function CalendarAdmin() {
    const [loading, setLoading] = useState(true);
    const [calendars, setCalendars] = useState([]);
    const nativagor = useNavigate()

    useEffect(() => {
        doGet('calendar-admin').then(resp =>{
            setCalendars(resp)
            setLoading(false)
        })
    }, [])


    if(loading){
        return(<>
            <Heading title="Calendar Admin" />
            <MySpinner />
        </>)
    }

    return(<>
        <Heading title="Calendar Admin" icons={[<FontAwesomeIcon onClick={() => nativagor("/calendar-admin/new")} cursor={'pointer'} icon={faAdd} />]} />
        <div className="inner-container" style={{paddingTop: 50}}>
            <h3>Select Calendar</h3>
            {calendars.map((item, index) =>(
                <div onClick={() => nativagor(`/calendar-admin/edit/${item.id}`)} style={{padding: 10, cursor:'pointer'}} key={index}>{item.calendarName}</div>
            ))}
        </div>
    </>)
}
export default CalendarAdmin
