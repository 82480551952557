import {useState} from "react";
import {doPostBody} from "../../services/rest";
import MyCheckBox from "../MyCheckBox";

function MediaForm({repository, folder, onComplete}) {

    const [newFolderName, setNewFolderName] = useState('');
    const [video, setVideo] = useState({});

    const saveFolder = async () => {
        if(newFolderName.length < 3){
            window.alert("Please enter a folder name")
            return;
        }
        const newMediaFolder = {}
        if(folder){
            newMediaFolder.folderPath = folder
            newMediaFolder.url = `${folder}/${newFolderName.replace(" ", '-').toLowerCase()}`;
        }else{
            newMediaFolder.url = `${folder}/${newFolderName.replace(" ", '-').toLowerCase()}`;
        }
        newMediaFolder.title = newFolderName
        newMediaFolder.folder = true;
        const resp = await doPostBody(`media/admin/folder/new/${repository}`, newMediaFolder)
        if(resp[0].id){
            onComplete(resp);
        }else{
            window.alert("Something has gone wrong, folder has not been saved.")
        }
    }

    const updateNewUrl = event => {
        setVideo(prevVideo => ({
            ...prevVideo,
            [event.target.name]: event.target.value
        }))
    }

    const classifyVideoUrl = (url) => {
        const youtubePattern = /(https?:\/\/)?(www\.)?(youtube|youtu|youtube-nocookie)\.(com|be)\/(watch\?v=|embed\/|v\/|.+\?v=)?([^&=%\?]{11})/;
        const vimeoPattern = /(https?:\/\/)?(www\.)?(vimeo)\.com\/(\d+)(\/)?(#.*)?/;

        if (youtubePattern.test(url)) {
            return 'YOUTUBE';
        } else if (vimeoPattern.test(url)) {
            return 'VIMEO';
        } else {
            return 'OTHER';
        }
    };

    const saveUrl = async () => {
        if(!video.url || !video.title){
            window.alert("Please enter a title and video url")
            return;
        }
        if(folder){
            video.folderPath = folder
        }
        if(!video.mediaType){
            video.mediaType = classifyVideoUrl(video.url)
        }
        video.folder = false
        const resp = await doPostBody(`media/admin/folder/new/${repository}`, video)
        if(resp[0].id){
            onComplete(resp);
        }else{
            window.alert("Something has gone wrong, folder has not been saved.")
        }
    }

    const onCheckBoxChange = () => {
        setVideo(prevVideo => ({
            ...prevVideo,
            mediaType: video.mediaType ? undefined : 'LIVE'
        }));
    }

    return(<div className={'inner-container'}>
        <h3>Add Folder</h3>
        <input type="text" placeholder="New Folder Name" className={'search-input'} value={newFolderName} onChange={(e) => setNewFolderName(e.target.value)}/>
        <button onClick={saveFolder} style={{marginTop: 10}} className={'submitPost'}>Save</button>
        <h3>Add Media</h3>
        <input type="text" style={{marginBottom: 10}} name={'title'} placeholder="Title" className={'search-input'} onChange={updateNewUrl} value={video.title}/>
        <input type="text" placeholder="Url" name={'url'} onChange={updateNewUrl} className={'search-input'} value={video.url}/>
        <MyCheckBox name={'live'} id={'live'} value={true} label={'This is a live stream'} onChange={onCheckBoxChange} checked={video.mediaType === 'LIVE'}/>
        <button onClick={saveUrl} style={{marginTop: 10}} className={'submitPost'}>Save</button>
    </div>)
}

export default MediaForm
