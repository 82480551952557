import {useStompClient} from "react-stomp-hooks";
import {useEffect, useRef} from "react";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../../firebase/firebase";

function PersonalSubscriber({onMessage}){
    const [user] = useAuthState(auth);
    const stompClient = useStompClient();  // Provide your Stomp Configuration if required
    const subscription = useRef(null);

    useEffect(() => {
        if (stompClient?.connected) {
            subscription.current = stompClient.subscribe(
                `/user/${user.uid}/messages`,
                (message) => onMessage(message.body)
            );
        }
        return () => {
            if(subscription.current) {
                subscription.current.unsubscribe();
            }
        };
    }, [stompClient, user]);

    return null;
}
export default PersonalSubscriber
