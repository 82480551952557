import {useEffect, useState} from "react";
import MyCheckBox from "../MyCheckBox";
import {doPostBody} from "../../services/rest";

function ResidentAdditionalInformation({information, refreshId, name, onRefresh}) {
    const [additionalInformation, setAdditionalInformation] = useState({})
    useEffect(() => {
        setAdditionalInformation(information)
    }, [information]);

    const chekcBoxChange = () => {
        setAdditionalInformation(prevState => ({
            ...prevState,
            noVehicle: !prevState.noVehicle
        }))
    }

    const onChange = event => {
        setAdditionalInformation(prevState => ({
            ...prevState,
            notes: event.target.value
        }))
    }

    const save = async () => {
        const resp = await doPostBody(`vehicles-admin/save/resident/other/${refreshId}`, additionalInformation)
        if(resp.id){
            onRefresh(resp)
        }else{
            window.alert("Something has gone wrong, information has not been saved.")
        }
    }

    return(<div className={'inner-container'}>
        <h3>Additional information for {name}</h3>
        <MyCheckBox id={'noDrive'} checked={additionalInformation.noVehicle} onChange={chekcBoxChange} label={"Resident has indicated they do not drive."} />
        <textarea onChange={onChange} className={'search-input'} value={additionalInformation.notes}></textarea>
        <button onClick={save} className={'submitPost'}>Save</button>
    </div> )
}
export default ResidentAdditionalInformation
