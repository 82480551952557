import styled from "styled-components";
const Label = styled.label`
        display: flex;
        align-items: center;
        font-size: 16px;
        cursor: pointer;
        &::before{
            content: '';
            margin-right: 10px;
            width: 20px;
            height: 20px;
            border:  1px solid ${({ color }) => color || '#FFA500'};
            display: inline-block;
        }
    `;
const SurveyRadio = styled.input`
  display: none;

  &:checked + ${Label}::before {
    background-color: ${({ color }) => color || '#FFA500'};;
  }
`;
function MyCheckBox({id, name, value, onChange, label, checked, color}) {



    return (
        <div className={'survey-button-container'}>
            <SurveyRadio id={id} type={'checkbox'} name={name} value={value} color={color} onChange={onChange} checked={checked} />
            <Label htmlFor={id} color={color}>{label}</Label>
        </div>
    )
}
export default MyCheckBox
