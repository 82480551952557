import {useParams} from "react-router-dom";
import Heading from "../Heading";
import MySpinner from "../MySpinner";
import {useEffect, useRef, useState} from "react";
import {doGetSurvey} from "../../services/rest";

function Unsubscribe() {
    const {id} = useParams();

    const [status, setStatus] = useState(false)
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(true)
    const current = useRef()

    useEffect(() =>{
        if(id){
            const decoded = atob(id).split(":")
            doGetSurvey(`unsubscribe?type=${decoded[1]}`, id).then(res => {
                current.current = decoded[1]
                setMessage(res.message)
                setStatus(res.subscribed)
                setLoading(false)
            })
        }
    },[id])
    const toggle = async () =>{
        setLoading(true)
        const type = status ? "unsubscribe" : "subscribe"
        const resp = await doGetSurvey(`unsubscribe/manage/${type}?type=${current.current}`, id)
        console.log(resp)
        setMessage(resp.message)
        setStatus(resp.subscribed)
        setLoading(false)
    }
    if(loading){
        return (<>
            <Heading title={"Notification Preferences"} />
            <MySpinner />
        </>)
    }

    return (<>
        <Heading title={'Notification Preferences'} />
        <div className={'inner-container'} style={{paddingTop: 50}}>
            <p>{message}</p>
            <button style={{marginTop: 50}} onClick={toggle} className={'submitPost'}>{status ? 'Unsubscribe' : 'Subscribe'}</button>
        </div>
    </>)

}
export default Unsubscribe
