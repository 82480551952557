import {doGet} from "../../services/rest";
import {useState} from "react";

function UserChatSearch({createNewChat}) {

    const [userResults, setUsers] = useState([]);

    const search = event => {
        if(event.key === 'Enter') {
            if(event.target.value.length > 3){
                doGet(`chat/search-users?search=${event.target.value}`).then(result => {
                    setUsers(result)
                })
            }
        }
    }

    return(<div className={'inner-container'}>
        <input className={'search-input'} type='text' placeholder={'Search For Users'} onKeyDown={e => search(e)} />
        {userResults.map(user =>(<div onClick={() => createNewChat(user)} style={{padding: 10, cursor: "pointer"}} key={user.id}>{user.displayName}</div>))}
    </div> )
}
export default UserChatSearch
