import {useEffect, useState} from "react";
import Heading from "../Heading";
import MySpinner from "../MySpinner";
import {hasAdmin} from "../../services/menu";
import {useNavigate} from "react-router-dom";
import {doGet} from "../../services/rest";
import parse from 'html-react-parser';
import moment from 'moment';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd} from "@fortawesome/free-solid-svg-icons";

function ManageMessages() {
    const navigate = useNavigate();
    const [scheduledMessages, setScheduledMessages] = useState()
    const [sentMessages, setSentMessages] = useState()
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(0)

    useEffect(() => {
        if(!hasAdmin("ROLE_MESSAGING")) navigate("/")
        doGet('message-admin').then(resp =>{
            setScheduledMessages(resp.schedules)
            setSentMessages(resp.messages)
            setLoading(false)
            console.log(resp)
        })
    }, []);

    useEffect(()=>{
        if(!loading){
            doGet(`message-admin/page?page=${page}`).then(resp =>{
                setSentMessages(resp)
                setLoading(false)
            })
        }
    }, [page])

    if(loading){
        return (<>
            <Heading title={'Manage Messages'} />
            <MySpinner />
        </>)
    }
    const getPreview = (html, n) => {
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(html, "text/html");

        let textContent = '';
        let node = htmlDoc.body.firstChild;
        for (let i = 0; i < n; i++) {
            if (node) {
                textContent += node.textContent + '\n';
                node = node.nextSibling;
            } else {
                break;
            }
        }
        return textContent;
    };

    const formatDate = (dateTime) => {
        return  moment(dateTime).format("MM/DD/yyyy h:mm a");
    }


    return(<>
        <Heading title={'Manage Messages'} icons={[<div style={{cursor: 'pointer'}} onClick={() => navigate('/send-message/form')}><FontAwesomeIcon icon={faAdd} /></div>]}/>
        <div className={'main-container'} style={{paddingTop: '20px'}}>
            <h3>Scheduled Messages</h3>
            {scheduledMessages.length !== 0 ? <table className="table" style={{width: '100%', borderCollapse: 'collapse'}}>
                <tr>
                    <th>Sent By</th>
                    <th>Title</th>
                    <th>Preview</th>
                    <th>Date Scheduled</th>
                </tr>
                {scheduledMessages.map(message => (
                    <tr style={{borderBottom: '1px solid gray', cursor: 'pointer'}} key={message.id}
                        onClick={() => navigate(`/send-message/form/${message.id}`, {state: {scheduled: message}})}>
                        <td style={{
                            borderBottom: '1px solid gray',
                            marginBottom: 10
                        }}>{message.user?.displayName ?? message.sentBy}</td>
                        <td style={{borderBottom: '1px solid gray', marginBottom: 10}}> {message.title}</td>
                        <td style={{
                            borderBottom: '1px solid gray',
                            marginBottom: 10
                        }}>{parse(getPreview(message.message, 5))}</td>
                        <td style={{
                            borderBottom: '1px solid gray',
                            marginBottom: 10
                        }}>{formatDate(message.scheduleMessage)}</td>
                    </tr>
                ))}
            </table> : <p>You currently have no scheduled messages.</p>}

            <h3 style={{paddingTop: 30}}>Sent Messages</h3>
            <table className="table" style={{width: '100%', borderCollapse: 'collapse'}}>
                <tr>
                    <th>Sent By</th>
                    <th>Title</th>
                    <th>Preview</th>
                    <th>Date Sent</th>
                </tr>
                {sentMessages.content.map(message => (
                    <tr style={{borderBottom: '1px solid gray', cursor: 'pointer'}} key={message.id}
                        onClick={() => navigate(`/send-message/form/${message.id}`, {state: {message: message}})}>
                        <td style={{
                            borderBottom: '1px solid gray',
                            marginBottom: 10
                        }}>{message.user?.displayName ?? message.sentBy}</td>
                        <td style={{borderBottom: '1px solid gray', marginBottom: 10}}> {message.title}</td>
                        <td style={{borderBottom: '1px solid gray', marginBottom: 10}}>{parse(getPreview(message.message, 5))}</td>
                        <td style={{borderBottom: '1px solid gray', marginBottom: 10}}>{formatDate(message.dateSent)}</td>
                    </tr>
                ))}
            </table>
            <div style={{width: '100%', display: 'flex', alignItems: 'flex-end', marginTop: '20px', justifyContent: 'space-between', marginBottom: '20px'}}>
                {!sentMessages.first ? <button onClick={() => setPage(page-1)} className={'submitPost'}>Previous</button> : <div></div>}
                {!sentMessages.last && <button onClick={() => setPage(page+1)} className={'submitPost'}>Next</button>}
            </div>
        </div>
    </>)

}

export default ManageMessages
