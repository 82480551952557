import {hasAdmin} from "../../services/menu";
import {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import Heading from "../Heading";
import MySpinner from "../MySpinner";
import {doGet, doPost} from "../../services/rest";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd, faArrowLeft, faArrowRight} from "@fortawesome/free-solid-svg-icons";
import PopUp from "../PopUp";
import CheckOutUser from "./CheckOutUser";

function FacilityServices() {
    const faciltyAdmin = hasAdmin("ROLE_MAINTENANCE")
    const natigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [checkIns, setCheckIns] = useState({})
    const [forms, setForms] = useState({})
    const [contractorDate, setContractorDate] = useState()
    const page = useRef(0)
    const [checkOutUser, setCheckOutUser] = useState()

    useEffect(() =>{
        doGet('facility-services').then(resp =>{
            setCheckIns(resp.contractor)
            setForms(resp.forms)
            setLoading(false)
        })
    },[])

    useEffect(() => {
        if(contractorDate){
            setLoading(true)
            doGet(`facility-services/contractor/date?date=${contractorDate}`).then(resp =>{
                setCheckIns(resp)
                setLoading(false)
            })
        }
    }, [contractorDate]);

    const searchByAny = event => {
        if(event.key === 'Enter' && event.target.value.length > 3){
            setLoading(true)
            doGet(`facility-services/contractor/string?search=${event.target.value}`).then(resp =>{
                setCheckIns(resp)
                setLoading(false)
            })
        }
    }

    useEffect(() => {
        if(!faciltyAdmin) natigate("/")
    }, [faciltyAdmin, natigate]);

    if(loading){
        return (<>
            <Heading title={'Facility Services'} />
            <MySpinner />
        </>)
    }

    const changePage = (newPage) => {
        page.current = newPage;
        setLoading(true)
        doGet(`facility-services/forms/${newPage}`).then(resp =>{
            setForms(resp)
            setLoading(false)
        })
    }

    return(<>
        <Heading title={'Facility Services'} />
        <div className={'inner-container'} style={{paddingTop: 50}}>
            <div style={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}}>
                <input type={'date'} className={'search-input'} style={{width: 200}}
                       onChange={e => setContractorDate(e.target.value)}/>
                <h3>Contractor Check Ins</h3>
                <input placeholder={'Search'} type={'text'} className={'search-input'} style={{width: 200}}
                       onKeyDown={searchByAny}/>
            </div>
            <table className={'workorder'} style={{width: '100%', fontSize: 12}}>
                <tr>
                    <th>Name</th>
                    <th>Business</th>
                    <th>Work Location</th>
                    <th>Time In</th>
                    <th>Time Out</th>
                    <th>Check Out</th>
                </tr>
                {checkIns.map((order) => (<tr key={order.id}>
                    <td>{order.lastName} {order.firstName}</td>
                    <td>{order.company}</td>
                    <td>{order.location}</td>
                    <td>{order.timeIn}</td>
                    <td>{order?.timeOut}</td>
                    <td>{!order.timeOut && <div onClick={() => setCheckOutUser(order)}>Check Out</div>}</td>
                </tr>))}
            </table>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <h3>Mechanical Forms</h3><FontAwesomeIcon onClick={() => natigate("/facility-services/form")} style={{paddingLeft: 15}} cursor={'pointer'} icon={faAdd}/>
            </div>
            <table className={'workorder'} style={{width: '100%', fontSize: 12}}>
                <tr>
                    <th>Date Completed</th>
                    <th>Completed By</th>
                </tr>
                {forms.content.map(form =>(
                    <tr onClick={() => natigate(`/facility-services/form/${form.id}`)} key={form.id} style={{cursor: 'pointer'}}>
                        <td>{moment(form.completedDate).format("MM/DD/YYYY [at] hh:mma")}</td>
                        <td>{form.completedBy}</td>
                    </tr>
                ))}
            </table>
            <div style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center', borderTop: '1px solid gray'}}>
                <div>{!forms.first && <FontAwesomeIcon cursor={'pointer'} icon={faArrowLeft} onClick={() => changePage(page.current-1)} />}</div>
                <div>{!forms.last && <FontAwesomeIcon cursor={'pointer'} icon={faArrowRight} onClick={() => changePage(page.current+1)} />}</div>
            </div>
        </div>
        {checkOutUser && <PopUp
                content={<CheckOutUser user={checkOutUser} onComplete={(e) =>{
                    setCheckIns(e)
                    setCheckOutUser(undefined)
                }}/> }
                handleClose={() => setCheckOutUser(undefined)}
            />}
        </>
    )
}

export default FacilityServices
