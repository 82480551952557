
import Heading from "../Heading";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import rrulePlugin from '@fullcalendar/rrule'
import adaptivePlugin from '@fullcalendar/adaptive'
import {useEffect, useRef, useState} from "react";
import {doGet} from "../../services/rest";
import moment from "moment";
import SlidePane from "react-sliding-pane";
import MyCheckBox from "../MyCheckBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose, faGear} from "@fortawesome/free-solid-svg-icons";
import PopUp from "../PopUp";
import NewEventForm from "./NewEventForm";
import {hasAdmin} from "../../services/menu";
import {useNavigate, useParams} from "react-router-dom";
function Calendar() {
    const {calendar} = useParams()
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [sidepainOpen, setSidepainOpen] = useState(false);
    const [myCalendars, setMyCalendars] = useState([]);
    const [selectedCalendars, setSelectedCalendars] = useState();
    const allCalendrEvents = useRef([])
    const [adminCalendars, setAdminCalendars] = useState([]);
    const [showNewEventForm, setShowNewEventForm] = useState(false);
    const [currentEvent, setCurrentEvent] = useState()
    const payLoad = useRef()
    const calendarAdmin = hasAdmin("ROLE_CALENDARS")
    const natigation = useNavigate()
    const [hoveredEvent, setHoveredEvent] = useState(null);
    const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
    useEffect(() => {
        if(!calendar){
            doGet('calendar/security').then(resp => setAdminCalendars(resp))
        }
    },[calendar])
    const delay = ms => new Promise(res => setTimeout(res, ms));

    useEffect(() => {
        if(hoveredEvent){
            delay(5000).then(_ => setHoveredEvent(null));
        }
    },[hoveredEvent])

    const handleMonthChange = async (payload) =>{
        payLoad.current = payload;
        const startDate = moment(payload.start).format("YYYY-MM-DD")
        const endDate = moment(payload.end).format("YYYY-MM-DD")
        let url = `calendar?startDate=${startDate}&endDate=${endDate}`;
        if(calendar){
            url = `public-calendar/${calendar}?startDate=${startDate}&endDate=${endDate}`;
        }
        const calendars = await doGet(`${url}`)
        let combinedCalendarEvents = [];
        let ids = new Set();
        let calendarIds = [];
        for (const calendar of calendars) {
            calendarIds.push(calendar.id);
            if(!selectedCalendars || selectedCalendars?.includes(calendar.id)) {
                for (const event of calendar.calendarEvents) {
                    if (!ids.has(event.id)) {
                        event.backgroundColor = calendar.defaultColor
                        event.duration = moment.duration(moment(event.end).diff(event.start)).asMilliseconds()
                        combinedCalendarEvents.push(event);
                        ids.add(event.id);
                    }
                }
            }
        }
        if(!selectedCalendars){
            setSelectedCalendars(calendarIds);
        }
        setCalendarEvents(combinedCalendarEvents);
        allCalendrEvents.current = calendars;
        setMyCalendars(calendars)
    }

    useEffect(() => {
        const combinedCalendarEvents = [];
        for (const calendar of allCalendrEvents.current) {
            if(!selectedCalendars || selectedCalendars?.includes(calendar.id)) {
                for (const event of calendar.calendarEvents) {
                    event.backgroundColor = calendar.defaultColor
                    combinedCalendarEvents.push(event);
                }
            }
        }
        setCalendarEvents(combinedCalendarEvents);
    }, [selectedCalendars]);

    const onCheckBoxChange = (id) =>{
        if (selectedCalendars?.includes(id)) {
            setSelectedCalendars(prevState => prevState.filter(itemId => itemId !== id));
        } else {
            setSelectedCalendars(prevState => prevState.concat(id));
        }
    }

    const renderEventContent = (eventInfo) => {
        if (eventInfo.view.type === 'listWeek') {
            return (
                <a href={'null'}>
                    <b>{eventInfo.event.title}</b>{eventInfo.event?.extendedProps?.eventLocation && <> - {eventInfo.event?.extendedProps?.eventLocation}</>}
                </a>
            )
        }
        return true;
    };

    const showEvent = async event => {
        event.jsEvent.preventDefault()
        const events = await doGet(`calendar/load-event/${event.event.id}`);
        events.event.calendar = events.calendars;
        setCurrentEvent(events.event);
        setShowNewEventForm(true)
    }

    const reset = () => {
        setCurrentEvent(undefined)
        setShowNewEventForm(false)
        handleMonthChange(payLoad.current)
    }

    const onMouseHover = async (info) => {
        // Set event data and tooltip position
        const events = await doGet(`calendar/load-event/${info.event.id}`);
        setHoveredEvent(events.event);
        setTooltipPosition({
            top: info.jsEvent.clientY + 10, // Adjust position as needed
            left: info.jsEvent.clientX + 10
        });
    };

    const onMouseLeave = () => {
        setHoveredEvent(null); // Hide tooltip
    };
    return(<>
        <Heading title={'Events Calender'} icons={[<FontAwesomeIcon onClick={() => setSidepainOpen(true)} cursor={'pointer'} icon={faGear} />]} />
        <div style={{padding:50, backgroundColor: '#fff', zIndex: -1}}>
            <FullCalendar
                schedulerLicenseKey={'0435152620-fcs-1708716914'}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin, adaptivePlugin, rrulePlugin]}
                initialView={'dayGridMonth'}
                events={calendarEvents}
                eventTimeFormat={{minute: '2-digit', omitZeroMinute: false, hour: 'numeric'}}
                eventMouseEnter={onMouseHover}
                eventContent={renderEventContent}
                eventMouseLeave={onMouseLeave}
                eventClick={showEvent}
                headerToolbar={{
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek"
                }}
                datesSet={handleMonthChange}
            />
        </div>
        <SlidePane
            className={'higher'}
            isOpen={sidepainOpen}
            width={"400px"}
            closeIcon={<FontAwesomeIcon icon={faClose} /> }
            title={`Calendars`}
            from="right"
            onRequestClose={() => setSidepainOpen(false)}
            children={<>
                {adminCalendars.length > 0 && <div style={{display: 'flex', justifyContent: 'center', paddingBottom: 10}}><button onClick={() =>{
                    setShowNewEventForm(true)
                    setSidepainOpen(false)
                }} className={'submitPost'}>Add Event</button></div> }

                    {myCalendars.map((item, index) => (
                        <div key={index}><MyCheckBox color={item.defaultColor} id={item.id} label={item.calendarName} onChange={() => onCheckBoxChange(item.id)} checked={selectedCalendars?.includes(item.id)} /> </div>
                ))}
                {calendarAdmin && <div style={{display: 'flex', justifyContent: 'center', paddingBottom: 10}}>
                    <button onClick={() => natigation("/calendar-admin")} className={'submitPost'}>Calendar Admin</button></div>
                }
            </>}
        />
        {showNewEventForm && <PopUp
            content={<NewEventForm myAdminCalendars={adminCalendars} currentEvent={currentEvent} reset={reset}/>}
            handleClose={() => {
                setCurrentEvent(undefined)
                setShowNewEventForm(false)}}
        />}
        {hoveredEvent && (
            <div style={{
                position: 'absolute',
                top: tooltipPosition.top,
                left: tooltipPosition.left,
                backgroundColor: 'white',
                padding: '5px',
                border: '1px solid black',
                borderRadius: '3px',
                zIndex: 1000
            }}>
                <strong>{hoveredEvent.title}</strong>
                {!hoveredEvent.allDay && <p>{moment(hoveredEvent.start).format('hh:mm a')} - {moment(hoveredEvent.end).format('hh:mm a')}</p>}
                {hoveredEvent.eventLocation && <p>Location: {hoveredEvent.eventLocation}</p>}
            </div>
        )}
    </>)

}
export default Calendar
