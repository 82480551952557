const formatFirstNameLastName = (residents) => {
    if(!residents){
        return "";
    }
    if (residents.length === 1) {
        return residents[0].preferredName + ' ' + residents[0].lastName;
    }
    const lastNameList = new Map();

    residents.forEach(resident => {
        if (!lastNameList.has(resident.lastName)) {
            lastNameList.set(resident.lastName, [resident]);
        } else {
            let existingArray = lastNameList.get(resident.lastName);
            existingArray.push(resident);
            existingArray.sort((a, b) => (a.residentGender === 'male' ? -1 : 1));
            lastNameList.set(resident.lastName, existingArray);
        }
    });

    let nameStringList = [];

    lastNameList.forEach((residents, lastName) => {
        let nameList = residents.map(resident => resident.preferredName);
        nameStringList.push(`${nameList.join(' & ')} ${lastName}`);
    });

    return nameStringList.join(' & ');
};

export {formatFirstNameLastName};
