import {useEffect, useState} from "react";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {doPost} from "../../services/rest";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown, faArrowUp, faSearch} from "@fortawesome/free-solid-svg-icons";
import PopUp from "../PopUp";
import WorkOrderSearch from "./WorkOrderSearch";

function ClosedWorkOrders({locations, closedWorkOrders, removeIfOpened}) {
    const [workOrders, setWorkOrders] = useState([]);
    const [myLocations, setMyLocations] = useState([]);
    const [currentLocation, setCurrentLocation] = useState("all");
    const [showSearch, setShowSearch] = useState(false);
    const navigate = useNavigate();
    const [showOpenWorkOrder, setShowOpenWorkOrder] = useState(true)
    useEffect(() =>{
        setMyLocations(locations);
    }, [locations])

    useEffect(() =>{
        if(closedWorkOrders){
            setWorkOrders((workOrders) => {
                const exists = workOrders.some(order => order.id === closedWorkOrders.id);

                if (exists) {
                    // If it exists, replace it
                    return workOrders.map(order =>
                        order.id === closedWorkOrders.id ? closedWorkOrders : order
                    );
                } else {
                    // If it doesn't exist, add it
                    return [...workOrders, closedWorkOrders];
                }
            });
        }
    }, [closedWorkOrders]);

    useEffect(() => {
        if(removeIfOpened){
            setWorkOrders(workOrders.filter(order => order.id !== removeIfOpened.id));
        }
    },[removeIfOpened])

    useEffect(() => {
        const formData = new FormData();
        formData.set("date", moment(new Date()).format("YYYY-MM-DD"));
        doPost("it-admin/closed/date", formData).then(res =>setWorkOrders(res))
    }, []);

    const formatDate = (dateTime) => {
        return  moment(dateTime).format("MM/DD/yyyy h:mm a");
    }
    const formatDateOnly = (dateTime) => {
        if(!dateTime){
            return ""
        }
        return  moment(dateTime).format("MM/DD/yyyy");
    }


    return(<>

        <div style={{
            display: 'flex',
            flexDirection: 'row',
            paddingTop: 20,
            alignItems: 'center',
            justifyContent: 'space-between'
        }}>
            <div style={{display: 'flex', alignItems: 'center'}}>
            <h3>Todays Closed Work Orders</h3>
                <FontAwesomeIcon icon={faSearch} style={{paddingLeft: 10, cursor: 'pointer'}} onClick={() => setShowSearch(true)} />
            </div>
            <div>
                <FontAwesomeIcon onClick={() => setShowOpenWorkOrder(!showOpenWorkOrder)} style={{paddingRight: 10, cursor: 'pointer'}} icon={showOpenWorkOrder ? faArrowDown : faArrowUp} />
                <select className={'submitPost'} value={currentLocation}
                        onChange={(e) => setCurrentLocation(e.target.value)}>
                    <option value="all">All</option>
                    {myLocations.map((location, index) => (
                        <option key={index} value={location.locationName}>{location.locationName}</option>
                    ))}
                </select>
            </div>
        </div>
        <div style={{
            display: showOpenWorkOrder ? "block" : "none", //example max height
        }}>
        <table className={'workorder'} style={{width:'100%', fontSize: 12}}>
            <tr>
                <th>Submitted Date</th>
                <th>Name</th>
                <th>Description</th>
                <th>Assigned To</th>
                <th>Due Date</th>
                <th>Closed By</th>
            </tr>
            {workOrders
                .filter(order => currentLocation === "all" || currentLocation === order.appLocations.locationName)
                .map(order => (
                    <tbody key={order.id} onClick={() => navigate(`/workorders/edit/${order.id}`, {state: {locations: myLocations}})}>
                    <tr>
                        <td>{formatDate(order.closedDate)}</td>
                        <td>{order?.name ?? ''}</td>
                        <td>{order.subject}</td>
                        <td>{order?.assignedTo?.displayName ?? ""}</td>
                        <td>{formatDateOnly(order?.dueDate)}</td>
                        <td>{order?.closedBy?.displayName}</td>
                    </tr>
                    </tbody>
                ))}
        </table>
        </div>
        {showSearch && <PopUp
            content={<WorkOrderSearch appLocations={locations} />}
            handleClose={() => setShowSearch(false)}
        />}
    </>)


}

export default ClosedWorkOrders
