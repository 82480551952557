import {useEffect, useRef} from "react";
import Hls from "hls.js";
function HLSStream({url}) {
    const videoRef = useRef()

    useEffect(() => {
        const hls = new Hls({'debug': true})
        if (Hls.isSupported()) {
            hls.log = true;
            hls.loadSource(url);
            hls.attachMedia(videoRef.current)
            hls.on(Hls.Events.ERROR, (err) => {
                console.log(err)
            });

        } else {
            console.log('load')
        }
    }, [url])
    return (
        <video
            ref={videoRef}
            controls
            src={url}
        />
    )
}
export default HLSStream;
