import Heading from "../Heading";
import {useCallback, useEffect, useMemo, useState} from "react";
import {hasAdmin} from "../../services/menu";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd, faFile, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useDropzone} from "react-dropzone";
import {doGet, doPost, doPostBody, downloadFileFromUrlPost, uploadDocuments} from "../../services/rest";
import {faFolder} from "@fortawesome/free-solid-svg-icons/faFolder";
import {defaultColor} from "../../defaults/AppConfig";
import FileSaver from 'file-saver';
import {useNavigate, useParams} from "react-router-dom";
import MySpinner from "../MySpinner";
function DocumentsRepo({clearDocuments}){
    const {id,'*': folder} = useParams();
    const [folderAdmin, setFolderAdmin] = useState(false)
    const [dragAndDropActive, setDrageAndDropActive] = useState(false)
    const [documents, setDocuments] = useState({});
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    useEffect(() => {
        console.log(folder)
        let url = `documents/${id}?folder=${folder}`
        if(!folder){
            url = `documents/${id}`
        }
        doGet(url).then(res => {
            setDocuments(res)
            setLoading(false)
        })
    },[id, folder])
    const onFolderChange = async (newFolder) => {
        console.log(newFolder)
        if(folder === newFolder){
            navigate(0)
        }else{
            navigate(`/documents/${id}/${newFolder}`)
        }

    }
    const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    };

    const focusedStyle = {
        borderColor: '#2196f3'
    };

    const acceptStyle = {
        borderColor: '#00e676'
    };

    const rejectStyle = {
        borderColor: '#ff1744'
    };
    const onDrop = useCallback(acceptedFiles => {
        console.log("DROP")
        console.log(folder)
        const formData = new FormData();
        acceptedFiles.forEach((file) =>{
            formData.append(`file`, file)
        });

        if(folder){
            formData.append("location", folder)
        }
        formData.append("repositoryId", id)
        uploadDocuments(formData).then(resp => onFolderChange(folder))
    }, [folder, id])

    const {getRootProps, getInputProps, isDragActive,isFocused,
        isDragAccept,
        isDragReject} = useDropzone({onDrop})
    useEffect(() => {
        setFolderAdmin(hasAdmin("ROLE_DOCUMENTS"))
    }, []);

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    const generateIcons = () => {
        if(folderAdmin){
            return [<div onClick={() => setDrageAndDropActive(!dragAndDropActive)} style={{cursor: "pointer"}}><FontAwesomeIcon icon={faAdd} /></div>]
        }
        return []
    }

    const downloadFile = (file) => {
        downloadFileFromUrlPost({url: 'documents/download', data:file}).then(blob => FileSaver.saveAs(new Blob([blob]), file.title))
    }

    const deleteDocument = (document) =>{
        if(window.confirm("Are you sure you want to delete this document?")){
            doPostBody("documents/admin/delete", document).then(res => {
                if(res){
                    onFolderChange(folder)
                }
            })
        }
    }

    const deleteFolder = (folder1) =>{
        if(window.confirm("Are you sure you want to delete this folder?  It will delete the folder and all of the contents.")){
            const formdata = new FormData();
            formdata.append("folder", folder1)
            formdata.append("repo", id)
            doPost("documents/admin/delete/folder",formdata).then(res => {
                if(res){
                    onFolderChange(folder)
                }
            })
        }
    }
    const goBack = () =>{
        if(folder){
            const pathSections = folder.split("/")

            if(pathSections.length > 2){
                pathSections.pop();
                onFolderChange(""+pathSections.join("/"))
            }else {
                onFolderChange(null)
            }
        }else{
            clearDocuments()
            navigate("/documents")
        }
    }

    const addFolder = () => {
        const newFolder = window.prompt("Enter a new folder name.");
        if(newFolder){
            if(folder){
                addFolderDatabaseReference(`${folder}/${newFolder}`)
                onFolderChange(`${folder}/${newFolder}`)
            }else{
                addFolderDatabaseReference(`/${newFolder}`)
                onFolderChange(`/${newFolder}`)
            }
        }

    }
    const addFolderDatabaseReference = (folderReference) => {
        doGet(`documents/admin/folder-reference/${id}?folderName=${folderReference}`)
    }
    if(loading){
        return(<>
            <Heading title={'Documents'} />
            <MySpinner />
        </>)
    }

    return(<>
        <Heading title={'Documents Repository'} icons={generateIcons()}/>
        {dragAndDropActive && <div className="container">
            <div onClick={addFolder} style={{padding: 30, cursor: 'pointer'}}>Add Folder</div>
            <div {...getRootProps()}>
                <input {...getInputProps({style})} />
                {isDragActive ? <p>Drop files or folder here...</p> :
                    <p>Drag 'n' drop some files or folders here, or click to select files.</p>
                }
            </div></div>
            }
          <div className={'inner-container'} style={{paddingTop: 50}}>
            {documents.folders && <>
                {documents.folders.map(folder =>(<div style={{width: '100%', display: 'flex', flexDirection:'row', paddingBottom: 30, cursor: 'pointer'}}><div style={{display: 'flex', flexDirection:'row', cursor: 'pointer', flexGrow: 1, width: '100%'}}  onClick={() => onFolderChange(folder)}><FontAwesomeIcon style={{color: defaultColor, paddingRight:20, fontSize:20}} icon={faFolder} /> {folder.split("/").pop()}</div>{folderAdmin && <div onClick={() => deleteFolder(folder)} style={{display: 'flex', flexDirection: 'row', justifyContent: "flex-end", flexShrink: 1}}><FontAwesomeIcon icon={faTrash} /></div>}</div>))}
            </>}
        {documents.documents && <>
            {documents.documents.map(document =>(<div style={{width: '100%', display: 'flex', flexDirection:'row', paddingBottom: 30, cursor: 'pointer'}}><div style={{width: '100%', display: 'flex', flexDirection:'row', cursor: 'pointer'}}  onClick={() => downloadFile(document)}><FontAwesomeIcon icon={faFile} style={{color: defaultColor, paddingRight:20, fontSize:20}} /> {document.title}</div>{folderAdmin && <div onClick={() => deleteDocument(document)} style={{display: 'flex', flexDirection: 'row', justifyContent: "flex-end"}}><FontAwesomeIcon icon={faTrash} /></div>}</div>))}
        </>}
          </div>
        </>)
        }export default DocumentsRepo;
