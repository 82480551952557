import {doPost, doPostBody, imageUploadHandler} from "../../services/rest";
import {Editor} from "@tinymce/tinymce-react";
import React, {useEffect, useState} from "react";

function NewComment({woId, currentComment, closeComments}) {

    const [coments, setComents] = useState({});

    useEffect(() =>{
        if(currentComment){
            setComents(currentComment)
        }
    },[currentComment])
    const onEditChange = event => {
        setComents(prevWorkOrder => ({
            ...prevWorkOrder,  // copy all the properties from the old state
            'comment': event      // overwrite the value of the field we are interested in
        }));
    }

    const saveComment = () => {
        if(coments.comment){
            doPostBody(`it/comment/save/${woId}`, coments).then(res => closeComments())
        }
    }

    return(<>
        <Editor
            onEditorChange={(e) => onEditChange(e)}
            licenseKey={'gpl'}
            value={coments?.comment}
            init={{
                height: 500,
                menubar: false,
                plugins: [
                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount', 'table'
                ],
                toolbar: 'undo redo | blocks | link table|' +
                    'bold italic forecolor | alignleft aligncenter ' +
                    'alignright alignjustify | image | bullist numlist outdent indent | ' +
                    'removeformat | toggle',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                automatic_uploads: true,
                images_upload_handler: imageUploadHandler,
            }}
            tinymceScriptSrc={'/tinymce/tinymce.min.js'}
        />
        <button className={'submitPost'} onClick={saveComment}>Save</button>
    </>)
}

export default NewComment
