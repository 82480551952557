import {useState} from "react";
import {doPostBody} from "../../services/rest";

function NewLinkForm({id, subPath, update}) {
    const [newFolderName, setNewFolderName] = useState('');
    const [newLink, setNewLink] = useState({});

    console.log(subPath)

    const onUpdate = e =>{
        setNewLink({...newLink, [e.target.name]: e.target.value});
    }

    const saveFolder = async () => {
        const newLink1 = {}
        if(subPath){
            newLink1.folderPath = subPath
            newLink1.url = `${subPath}/${newFolderName.replace(' ', '-').toLowerCase()}`;
        }else{
            newLink1.url = newFolderName.replace(' ', '-').toLowerCase()
        }
        newLink1.title = newFolderName;
        newLink1.folder = true;
        const data = {links: newLink1, folderLocation: id}
        const resp = await doPostBody("links/admin/folder/new", data)
        if(resp.success){
            update(resp.items)
            alert("Your folder has been added");
            setNewLink({})
            setNewFolderName('')
        }else{
            alert(resp.error)
        }
    }

    const saveUrl = async () =>{
        if(subPath){
            newLink.folderPath = subPath
        }
        const data = {links: newLink, folderLocation: id}
        const resp = await doPostBody("links/admin/link/new", data)
        if(resp.success){
            update(resp.items)
            alert("Your link has been added");
            setNewLink({})
        }else{
            alert(resp.error)
        }

    }

    return(<div className={'inner-container'}>
        <h4>Add A Folder</h4>
        <div className={'search-container'}>
            <input className={'search-input'} name={'folder'} placeholder={'enter new folder name'}
                   onChange={(e) => setNewFolderName(e.target.value)}/>
        </div>
        <button onClick={saveFolder} className={'submitPost'}>Add Folder</button>
        <h4>Add A Link</h4>
        <div className={'search-container'}>
            <input className={'search-input'} value={newLink.title} name={'title'} placeholder={'Enter title'}
                   onChange={onUpdate}/>
        </div>
        <div className={'search-container'}>
            <input className={'search-input'} value={newLink.url} name={'url'} placeholder={'Enter url'}
                   onChange={onUpdate}/>
        </div>
        <button onClick={saveUrl} className={'submitPost'}>Add Link</button>
    </div>)
}

export default NewLinkForm
