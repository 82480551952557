import React, {useEffect, useRef, useState} from "react";
import {doGet, doPost, doPostBody, imageUploadHandler} from "../../services/rest";
import {Editor} from "@tinymce/tinymce-react";

function SurveyEmail({surveyId, close, defaultUsers}) {

    const [appLocations, setAppLocations] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [userResults, setUserResults] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [updateTemplate, setUpdateTemplate] = useState(false);
    const [currentTemplate, setCurrentTemplate] = useState(null);
    const [title, setTitle] = useState('GSC Questionnaire');
    const editorRef = useRef(null);
    const [updateHTMLTemplate, setUpdateHTMLTemplate] = useState(false)
    const [isPosting, setIsPosting] = useState(false)

    useEffect(() =>{
        if(defaultUsers){
            setSelectedUsers([defaultUsers])
            setUpdateTemplate(true)
        }
    },[defaultUsers])

    useEffect(() =>{
        if(surveyId){
            doGet(`surveys/create/survey/locations/${surveyId}`).then(res => setAppLocations(res));
        }
    }, [surveyId])

    useEffect(() =>{
        doGet('surveys/create/current-template').then(template => setCurrentTemplate(template));
    }, [])

    const onGroupSelect = (id) => {
        if(selectedGroups.includes(id)){
            setSelectedGroups(selectedGroups.filter(item => item !== id));
        }else{
            setSelectedGroups([...selectedGroups, id]);
        }
    }

    const search = async event =>{
        if(event.key === "Enter"){
            if(event.target.value.length < 3){
                alert("Please enter more to search by.")
                return
            }
            const formData = new FormData();
            formData.set('search', event.target.value);
            const results = await doPost(`surveys/create/survey/search/users/${surveyId}`, formData);
            setUserResults(results)
        }
    }

    const toggleUser = (user) => {
        const selectedUserIds = selectedUsers.map(item => item.id);

        if(selectedUserIds.includes(user.id)){
            setSelectedUsers(selectedUsers.filter(item => item.id !== user.id));
        }else{
            setSelectedUsers([...selectedUsers, user]);
        }
    }

    const sendEmails = async () => {
        if(updateTemplate){
            if(selectedGroups.length === 0 && selectedUsers.length === 0){
                alert("Please select at least one user or group to send the email to.")
                return;
            }
            setIsPosting(true)
            const data = {'surveyId': surveyId, "groupIds": selectedGroups, title: title, message: currentTemplate, updateMessage: updateHTMLTemplate};
            const users = []
            for(const user of selectedUsers){
                users.push(user.id)
            }
            data.userIds = users;
            const resp = await doPostBody('surveys/create/send-email-individuals', data);
            alert(resp.resp)
            close()
        }else{
            setUpdateTemplate(true);
        }

    }

    if(updateTemplate){
        return <div className={'inner-container'}>
            <div className="search-container"><input className="search-input" name="subject" placeholder="Enter Subject"
                                                     value={title} onChange={e => setTitle(e.target.value)}/></div>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <button onClick={() => {
                editorRef.current.insertContent("%recipient.name%")
            }} className={'submitPost'}>Add Recipient Name
            </button>
            <button onClick={() => {
                editorRef.current.insertContent("%recipient.title%")
            }} className={'submitPost'}>Add Survey Title
            </button>
            <button onClick={() => {
                editorRef.current.insertContent("<a href='%recipient.link%'>Take The Survey</a>")
            }} className={'submitPost'}>Add Survey Link
            </button>
            </div>
            <br />
            <Editor
                onInit={(evt, editor) => editorRef.current = editor}
                onEditorChange={(e) => setCurrentTemplate(e)}
                licenseKey={'gpl'}
                value={currentTemplate}
                init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount', 'table'
                    ],
                    toolbar: 'undo redo | blocks | link table|' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | image | bullist numlist outdent indent | ' +
                        'removeformat | toggle',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    automatic_uploads: true,
                    images_upload_handler: imageUploadHandler,
                }}
                tinymceScriptSrc={'/tinymce/tinymce.min.js'}
            />
            <br/>
            <div className={'survey-button-container'}>
                <input id={'update'} className={'survey-radio'} name={'update'} type={'checkbox'} checked={updateHTMLTemplate} onChange={e => setUpdateHTMLTemplate(!updateHTMLTemplate)} />
                <label className={'checkbox-label'} for={'update'}>Update Default Email Template</label>
            </div>
            {isPosting ? <div style={{display: 'flex', alignItems:'center', justifyContent: 'center'}}> <div style={{
                    border: '16px solid #f3f3f3',
                    borderRadius: '50%',
                    borderTop: '16px solid #e97200',
                    width: '10px',
                    height: '10px',
                    marginLeft: 10,
                    animation: 'spin 2s linear infinite'
                }}/></div> :
                <button className={'submitPost'} onClick={sendEmails}>Send Email Notifications</button> }
        </div>
    }


    return (<div className={'inner-container'}>
        <div style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{flex: 1}}>
                <h5>Select Groups</h5>
                {appLocations.map((data, index) => (
                    <div key={index} className={'survey-button-container'}>
                        <input id={`group_${data.id}`} className={'survey-radio'} type={"checkbox"} value={data.id} name={data.id} onChange={() => onGroupSelect(data.id)} checked={selectedGroups.includes(data.id)}/>
                        <label htmlFor={`group_${data.id}`} className={'checkbox-label'}>{data.locationName}</label>
                    </div>
                ))}
            </div>
            <div style={{flex: 1}}>
                <h5>Search for users</h5>
                <input style={{width: '80%', paddingTop: 12, paddingBottom: 12, paddingRight: 20, paddingLeft: 20, borderRadius: 4, border: '2px solid #ccc', marginBottom: 10}} type={'text'} placeholder={'Search for users'} onKeyDown={search} />
                {selectedUsers.map((data, index) => (
                    <div key={index} className={'survey-button-container'}>
                        <input id={data.id} className={'survey-radio'} type={'checkbox'} value={data.id} checked={true} onClick={() => toggleUser(data) }/>
                        <label htmlFor={data.id} className={'checkbox-label'}>{data.displayName}</label>
                    </div>
                ))}

                {userResults.filter(user => !selectedUsers.find(selectedUser => selectedUser.id === user.id)).map((data, index) => (
                    <div key={index} className={'survey-button-container'}>
                        <input id={data.id} className={'survey-radio'} type={'checkbox'} value={data.id} checked={false} onClick={() => toggleUser(data)} />
                        <label htmlFor={data.id} className={'checkbox-label'}>{data.displayName}</label>
                    </div>
                ))}
            </div>
        </div>
        <button className={'submitPost'} onClick={sendEmails}>Next</button>
    </div>)
}

export default SurveyEmail
