import React, {useEffect, useRef, useState} from "react";
import Heading from "../Heading";
import {Editor} from "@tinymce/tinymce-react";
import {doGet, doPost, doPostBody, imageUploadHandler} from "../../services/rest";
import {getDefaultLocation, hasAdmin} from "../../services/menu";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faClose, faEdit, faPerson} from "@fortawesome/free-solid-svg-icons";
import {useLocation, useParams} from "react-router-dom";
import MySpinner from "../MySpinner";
import PopUp from "../PopUp";
import PersonSearch from "./PersonSearch";
import {formatFirstNameLastName} from "../directory/NameFormatters";
import ITImageViewer from "./ITImageViewer";
import Comments from "./Comments";
import WorkOrderListener from "./WorkOrderListenr";
import CheckListSelection from "./CheckListSelection";
import CheckList from "./CheckList";

function WorkOrderForm() {

    let { id } = useParams();


    const location = useLocation();
    const [workOrder, setWorkOrder] = useState({})
    const [locations, setLocations] = useState([])
    const isItAdmin = hasAdmin("ROLE_IT")
    const [loading, setLoading] = useState(true)
    const [users, setUsers] = useState([])
    const [selectedLocationIndex, setSelectedLocationsIndex] = useState(-1)
    const [showPersonSearch, setShowPersonSearch] = useState(false)
    const [selectedAssignedUser, setSelectedAssignedUser] = useState()
    const [displayEditor, setDisplayEditor] = useState(false)
    const woJustUpdated = useRef(false)
    const [showCheckLists, setShowCheckLists] = useState(false)
    const [showCheckBox, setShowCheckBox] = useState(false)
    const completedLength = useRef(0)
    const [enter, setEnter] = useState();


    useEffect(() => {
        if(location.state.locations){
            setLocations(location.state.locations)
        }
    }, [location]);

    useEffect(() =>{
            if(workOrder.GSCId1){
                doGet('directory/maintenance/ok-enter/' + workOrder.GSCId1).then(res =>
                    setEnter(res)
                );
            }
        },
        [workOrder.GSCId1])

    useEffect(() => {
        if(id){
            setShowPersonSearch(false)
            setLoading(true)
            doGet(`it/view/${id}`).then(res=> {
                setWorkOrder(res)
                setSelectedAssignedUser(res?.assignedTo?.id)
                setLoading(false)
            })
        }else{
            setDisplayEditor(true)
        }
    },[id])

    useEffect(() => {
        if(isItAdmin){
            doGet('it-admin/it-users').then(data => {
                setUsers(data)
                setLoading(false)
            })
        }else{
            setLoading(false)
        }
    }, [isItAdmin])

    useEffect(() =>{
        if(getDefaultLocation()){
            setWorkOrder(prevWorkOrder => ({
                ...prevWorkOrder,
                'appLocations': getDefaultLocation()
            }));
          //  setSelectedLocationsIndex(getDefaultLocation())
        }
    },[])

    useEffect(() => {
        console.log(locations?.findIndex(loc => loc.id === workOrder?.appLocations?.id))
        setSelectedLocationsIndex(locations?.findIndex(loc => loc.id === workOrder?.appLocations?.id))
    }, [workOrder.appLocations, locations]);




    const updateWorkOrder = event => {
        setWorkOrder(prevWorkOrder => ({
            ...prevWorkOrder,  // copy all the properties from the old state
            [event.target.name]: event.target.value      // overwrite the value of the field we are interested in
        }));
    }
    const onEditChange = event => {
        setWorkOrder(prevWorkOrder => ({
            ...prevWorkOrder,  // copy all the properties from the old state
            'notes': event      // overwrite the value of the field we are interested in
        }));
    }

    const onBillableChange = event => {

            setWorkOrder(prevWorkOrder => ({
                ...prevWorkOrder,
                'billable': event.target.checked
            }));
    }

    const handleChangeDecimal = event => {
        const inputValue = event.target.value

        if (/^\d+\.?\d{0,2}$/.test(inputValue) || inputValue === '') {
            setWorkOrder(prevWorkOrder => ({
                ...prevWorkOrder,
                'amount': inputValue
            }))
        }
    }

    const onBilingTimeChange = event => {
        if (/^\d*$/.test(event.target.value)) {
            setWorkOrder(prevWorkOrder => ({
                ...prevWorkOrder,
                'minutes': event.target.value
            }));
        }
    }

    const calcualteBillingTime = event => {
        if (/^\d*$/.test(event.target.value)) {
            const formData = new FormData();
            formData.append('time', event.target.value);
            doPost('it-admin/calculate-billing', formData).then(data => {
                setWorkOrder(prevWorkOrder => ({
                    ...prevWorkOrder,
                    'amount': data
                }))
            })
        }
    }
    const updateLocation = (index) => {
        setWorkOrder(prevWorkOrder => ({
            ...prevWorkOrder,
            'appLocations': locations[index]
        }))
    }

    const updateUser = event => {
        const user = users.find(e => e.id === event.target.value)
        setSelectedAssignedUser(event.target.value)
        setWorkOrder(prevWorkOrder => ({
            ...prevWorkOrder,
            'assignedTo': user
        }))
    }

    const onUserSelect = (person, type) =>{
        if(type === 'resident'){
            const numbers = []
            const email = []
            if(person.homePhone){
                numbers.push({label: 'Home Phone', number: person.homePhone})
            }
            if(person.homeEmail){
                email.push({label: "Email", email: person.homeEmail})
            }
            for(const i in person.residentsNew){
                if(person.residentsNew[i].cellNumber){
                    numbers.push({label: person.residentsNew[i].firstName +" Cell", number: person.residentsNew[i].cellNumber})
                }
                if(person.residentsNew[i].emailAddress){
                    email.push({label: person.residentsNew[i].firstName +" Email", email: person.residentsNew[i].emailAddress})
                }
            }
            setWorkOrder(prevWorkOrder => ({
                ...prevWorkOrder,
                name: formatFirstNameLastName(person.residentsNew),
                address: person.homeNumber+' '+person.address,
                GSCId1: person.residentsNew[0].residentNumber,
                GSCId2: person.residentsNew[1]?.residentNumber,
                contactNumbers: numbers,
                contactEmails: email
            }))
            setShowPersonSearch(false)
        }else{
            const numbers = []
            const email = []
            if(person.phone){
                numbers.push({label: 'Phone', number: person.phone})
            }
            if(person.mobile){
                numbers.push({label: 'Cell', number: person.mobile})
            }
            if(person.email){
                email.push({label: 'Email', email: person.email})
            }
            setWorkOrder(prevWorkOrder => ({
                ...prevWorkOrder,
                name: person.fname+" "+person.lname,
                address: person.position,
                gscid1: person.employeeID,
                contactNumbers: numbers,
                contactEmails: email
            }))
            setShowPersonSearch(false)
        }
    }

    const saveWorKOrder = async () => {
        if(!workOrder.subject){
            alert("Subject is required")
            return
        }
        if(!workOrder.appLocations){
            alert("A default work order location is required.")
            return
        }
        if(workOrder.billable && !workOrder.GSCId1){
            alert("You have marked this work order as billable, but have not selected a user to bill to.")
            return
        }
        if(workOrder.checkLists && (workOrder.closed === true || workOrder.closed === 'true')){
            //verify checklist is complete
            if(workOrder.checkLists.checkListItems.length !== completedLength.current){
                alert("There is a checklist connected to this work order that has not been completed.  You cannot close this work order")
                return
            }
        }
        setLoading(true)
        const resp = await doPostBody("it/save", workOrder)
        setWorkOrder(resp)
        woJustUpdated.current = true
        setLoading(false)
    }

    const onUpdate = (workOrder) =>{
        if(!woJustUpdated.current){
            if(window.confirm("this work order has been updated.  Do you want to refresh it now?  Any unsaved work will be lost")){
                setWorkOrder(JSON.parse(workOrder))
            }
        }else{
            woJustUpdated.current = false
        }

    }

    const setCheckList = (checkList) =>{
        setWorkOrder(prevWorkOrder => ({
            ...prevWorkOrder,
            'checkLists': checkList
        }))
        setShowCheckLists(false)
    }

    if(loading){
        return (<>
            <Heading title={workOrder.id ? `Work Order id ${workOrder.id}` : 'New Work Order'} />
            <MySpinner />
        </>)
    }


    return(<>
        {workOrder.checkLists && <><div onClick={() => setShowCheckBox(true)} style={{position: 'fixed', right: 0, top: 100, writingMode: 'vertical-lr', borderLeft: '1px solid black', borderTop: '1px solid black', borderBottom: '1px solid black', cursor: 'pointer', borderTopLeftRadius: 5, padding: 5, borderBottomLeftRadius: 5}}>CheckList</div><CheckList workOrder={workOrder} checkList={workOrder.checkLists} display={showCheckBox} onChange={(val) => completedLength.current = val}/></>}
        {workOrder.id && <WorkOrderListener id={workOrder.id} onUpdate={(e) => onUpdate(e)} />}
        <Heading title={workOrder.id ? `Work Order ${workOrder.id}` : 'New Work Order'} />

        <div className={'inner-container'}>
            <div className={'search-container'}>
                <input className={'search-input'} onChange={updateWorkOrder} name={'subject'} value={workOrder.subject}
                       placeholder={'Enter Subject'}/>
            </div>
            <div style={{display: "flex", justifyContent: 'space-between'}}>
                <div style={{display: "flex", justifyContent: 'space-between', alignItems: 'center', flex: 1}}>
                <span style={{paddingRight: 10}}>Location:</span>
                <select className={'submitPost'} value={selectedLocationIndex} name={'appLocations'}
                        onChange={e => updateLocation(e.target.value)}>
                    <option></option>
                    {locations?.map((loc, index) => (<option value={index}>{loc.locationName}</option>))}
                </select>
                </div>
                <div style={{display: "flex", justifyContent: 'space-between', alignItems: 'center', flex: 1, marginLeft: 15}}>
                    <div></div>
                <button  onClick={() => setShowPersonSearch(true)} className={'submitPost'}><FontAwesomeIcon icon={faPerson}/> Attach Person</button>
                </div>
            </div>
            <div style={{display: "flex", justifyContent:'space-between', paddingTop: 15}}>
                <div style={{display: "flex", justifyContent: 'space-between', alignItems: 'center', flex: 1}}>
                    <span style={{paddingRight: 10}}>Assigned to:</span>
                    <select className={'submitPost'} name={'assignedTo'} onChange={updateUser} value={selectedAssignedUser}>
                        <option></option>
                        {users.filter(user => user.locationsSet.some(loc => loc.id === workOrder?.appLocations?.id)).map(user =>(
                            <option value={user.id}>{user.displayName}</option>
                        ))}
                    </select>
                </div>
                <div style={{display: "flex", justifyContent: 'space-between', alignItems: 'center', flex: 1, marginLeft: 15}}>
                    <span style={{paddingRight: 10}}>Status:</span>
                    <select name={'closed'} className={'submitPost'} onChange={updateWorkOrder} value={workOrder.closed}>
                        <option value={'false'}>Open</option>
                        <option value={'true'}>Closed</option>
                    </select>
                </div>
            </div>
            <div style={{display: "flex", justifyContent: 'space-between', paddingTop: 15}}>
                <div>{workOrder.checkLists && `Current checklist: ${workOrder.checkLists.title}`}</div>

                <button onClick={() => setShowCheckLists(true)} className={'submitPost'}><FontAwesomeIcon
                    icon={faCheck}/> Attach CheckList
                </button>
            </div>
            {workOrder.name &&
                <div style={{display: "flex", justifyContent: 'space-between', alignItems: 'center', flex: 1, paddingTop: 20}}>
                <div style={{display: "flex", flexDirection:'column', justifyContent: 'space-evenly', alignItems: 'start', flex: 1, marginLeft: 15}}>
                    <div>
                    Name: {workOrder.name}
                    </div>
                    <div>
                    Location: {workOrder.address}
                    </div>
                    {workOrder.contactNumbers.map(contactNumber => (
                        <div>
                            {contactNumber.label}: {contactNumber.number}
                        </div>
                    ))}
                    {workOrder.contactEmails.map(contactEmail => (
                        <div>
                            {contactEmail.label}: {contactEmail.email}
                        </div>
                    ))}
                </div>
                <div>
                    <ITImageViewer id={workOrder.GSCId1} />
                </div>
            </div>}
            {enter && <div style={{display: 'flex', marginLeft: 15}}>Ok to enter: {enter.okenter}</div>}
            <div style={{paddingTop: 50}}>
                <div className={'flex'} style={{alignItems: 'center'}}><h4>Notes</h4><FontAwesomeIcon icon={displayEditor ? faClose : faEdit} style={{paddingLeft: 10}} onClick={() => setDisplayEditor(!displayEditor)}/></div>
                {displayEditor ? <Editor
                    onEditorChange={(e) => onEditChange(e)}
                    licenseKey={'gpl'}
                    value={workOrder.notes}
                    init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount', 'table'
                        ],
                        toolbar: 'undo redo | blocks | link table|' +
                            'bold italic forecolor | alignleft aligncenter ' +
                            'alignright alignjustify | image | bullist numlist outdent indent | ' +
                            'removeformat | toggle',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                        automatic_uploads: true,
                        images_upload_handler: imageUploadHandler,
                    }}
                    tinymceScriptSrc={'/tinymce/tinymce.min.js'}
                /> : <div className={'reset'} dangerouslySetInnerHTML={{__html: workOrder.notes}}></div>}
            </div>
            <div className={'billing'}>
                <h4>Billing</h4>
                <div className={'survey-button-container'}>
                    <input id={'is-billable'} type={'checkbox'} className={'survey-radio'} name={'billable'} value={'true'} onChange={onBillableChange} checked={workOrder?.billable} />
                    <label className={'checkbox-label'} htmlFor={'is-billable'}>This work order is billable.</label>
                </div>
                { workOrder?.billable && <div className={'flex'}>
                    <div style={{display: "flex", flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>
                        <div style={{paddingTop: 10}}>
                        <label>Enter Your Time</label>
                        <div className={'search-container'}>
                            <input className={'search-input'} pattern="[0-9]" type={'text'} onChange={onBilingTimeChange} name={'minutes'} onBlur={calcualteBillingTime}
                                   value={workOrder.minutes}
                                   placeholder={'Time in minutes'}/>
                        </div>
                        </div>
                    </div>
                    <div style={{display: "flex", flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>
                        <div style={{paddingTop: 10}}>
                            <label>Amount To Bill</label>
                        <div className={'search-container'}>
                            <input className={'search-input'} onChange={handleChangeDecimal} type={'text'} name={'amount'}
                                   value={workOrder.amount}
                                   placeholder={'Amount'}/>
                        </div>
                        </div>
                    </div>
                </div>}
                <hr  style={{border: 'none', height: '1px', color: 'green', backgroundColor: 'gray'}}/>
                <h4>Due Date</h4>
                <div style={{display: "flex", flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div style={{paddingTop: 10}}>
                        <select name={'dueDateType'} className={'submitPost'} value={workOrder.dueDateType} onChange={updateWorkOrder}>
                            <option></option>
                            <option value={'ON'}>Completed on date</option>
                            <option value={'AFTER'}>Completed after date</option>
                            <option value={'PRIOR'}>Completed before date</option>
                        </select>
                    </div>
                    <div style={{paddingTop: 10}}>
                        <div className={'search-container'}>
                            <input className={'search-input'} onChange={updateWorkOrder} type={'date'}
                                   name={'dueDate'}
                                   value={workOrder.dueDate}
                                   placeholder={'Amount'}/>
                        </div>
                    </div>
                </div>
            </div>
            {workOrder.id && <Comments id={workOrder.id} currentComments={workOrder.workOrderComments}/>}
        </div>

        <button onClick={saveWorKOrder} className={'submitPost'} style={{marginBottom: 50}}>Save</button>
        {showPersonSearch && <PopUp
            content={<PersonSearch onPersonSelect={(resident, type) => onUserSelect(resident, type)} appLocations={locations} />}
            handleClose={() => setShowPersonSearch(false)}
        />}
        {showCheckLists && <PopUp
            content={<CheckListSelection setSelectedCheckList={e => setCheckList(e)} />}
            handleClose={() => setShowCheckLists(false)}
        />}
    </>)
}

export default WorkOrderForm
