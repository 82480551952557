import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../firebase/firebase";
import {useEffect, useState} from "react";
import {loadMenu} from "../services/menu";
import Sidebar from "./Sidebar";

function Heading({title, icons}) {
    const [user, loading] = useAuthState(auth);

    const [menuItems, setMenuItems] = useState([])

    useEffect(() => {
        if(loading) return;
        if(user){
            loadMenu().then(res => {
                setMenuItems(res)
            })
        }

    }, [user, loading]);


    return(<>
        <div className={"main-heading"}>{user && <Sidebar items={menuItems}></Sidebar>} <div className={"heading-display"}> <h3>{title}</h3></div>
            {icons && (
                <div className={"icons-display"}>
                    {icons.map((Icon, index) => (
                        <div key={index}>
                            {Icon}
                        </div>
                    ))}
                </div>
            )}
        </div>
    </>)
}
export default Heading;
