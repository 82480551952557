import {hasAdmin} from "../../services/menu";
import ItAdminDashboard from "./ItAdminDashboard";
import StandardDashboard from "./StandardDashboard";

function WorkOrders({closed}) {

    if (hasAdmin("ROLE_IT")){
        return <ItAdminDashboard closed={closed} />
    }

    return <StandardDashboard />

}
export default WorkOrders;
